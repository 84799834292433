import { type FC, memo, type ReactNode, useRef, useState } from 'react';
import useDebounceFn from 'ahooks/lib/useDebounceFn';

import { useHTTPRequest } from '@/store/internal/hooks';
import { useLazyGetAllByNameQuery, useLazyGetOneByIDQuery } from '@/store/company/service.ch';

import { Loader, TitleWithIcon, Typography } from '@/components/structural';
import { FormItem, Option, type RefSelectProps, Select, SelectProps } from '@/components/structural/form';

import type { ICHCompanyProfile, ICHCompanySearchResultItem } from '@/features/company/shared/models';

import { companyCHNumberPattern } from '@/features/company/shared/validation/companyCHNumberPattern';
import { formatCompanyAddress } from '@/features/company/shared/tools';


export interface ICHCompanyLookupProps
{
    onSelect: ( company: ICHCompanySearchResultItem | ICHCompanyProfile ) => void,
    className?: string,
    disabled?: boolean,
    guidanceText?: ReactNode,
    selectedItemInput?: ICHCompanySearchResultItem | ICHCompanyProfile,
    disableAllGuidance?: boolean,
    removeInputBottomMargin?: boolean,
}

export const CHCompanyLookup: FC<ICHCompanyLookupProps> = memo( ( {
    onSelect,
    className = '',
    disabled = false,
    guidanceText = 'Using the search box below, type in the name or registered number of \n' +
    'the company that you would like to manage on OneRegistry.',
    selectedItemInput,
    disableAllGuidance = false,
    removeInputBottomMargin = false
} ) =>
{
    const [ searchChByName, resultMany ] = useLazyGetAllByNameQuery();
    const [ searchChById, resultOne ] = useLazyGetOneByIDQuery();

    const { httpRequest: { loading: isLoading } } = useHTTPRequest();

    const [ selectedCompany, setSelectedCompany ] =
          useState<ICHCompanySearchResultItem | ICHCompanyProfile>( selectedItemInput );
    const [ searchValue, setSearchValue ] = useState<string>();
    const ref = useRef<RefSelectProps>();

    const resultManyData = resultMany?.data?.data;
    const resultOneData = resultOne?.data?.data;

    const delayedSearch = useDebounceFn<( value: string ) => void>(
          ( value ) =>
          {
              handleInput( value );
          },
          {
              wait: 350,
          },
    );

    const handleInput = ( value: string ) =>
    {
        if ( !value ) return;

        if ( value.match( companyCHNumberPattern ) )
        {
            searchChById( value );
        } else
        {
            searchChByName( value );
        }
    };

    const handleSelect = ( _: string, option: { chCompany: ICHCompanyLookupProps['selectedItemInput'] } ) =>
    {
        setSelectedCompany( option.chCompany );
        onSelect( option.chCompany );
        ref.current.blur();
    };

    return ( <>
        { !disableAllGuidance && guidanceText && <Typography.Text className="block mb-4">
            { guidanceText }
        </Typography.Text> }

        <FormItem
              label="Search Companies House"
              className={ removeInputBottomMargin && !selectedCompany ? '!mb-0' : '' }
        >
            <Select ref={ ref }
                    className={ className }
                    onSearch={ value =>
                    {
                        delayedSearch.run( value );
                        setSearchValue( value );
                    } }
                    searchValue={ searchValue }
                    onChange={ handleSelect as unknown as SelectProps['onChange'] }
                    showSearch
                    value={ selectedCompany?.company_number }
                    filterOption={ false }
                    loading={ resultMany.isLoading || resultOne.isLoading || isLoading }
                    notFoundContent={ resultMany.isLoading || resultOne.isLoading || isLoading ? <Loader/> : undefined }
                    placeholder="Search for a Companies House Company..."
                    showArrow={ false }
                    onBlur={ () => setSearchValue( '' ) }
                    open={ searchValue && searchValue.length > 0 }
                    disabled={ disabled }
            >
                { resultManyData?.map( company => (
                      <Option value={ company.company_number } key={ company.company_number } chCompany={ company }>
                          <TitleWithIcon title={ company.title } highlight={ searchValue }/>
                      </Option>
                ) ) }
                { resultOneData && (
                      <Option value={ resultOneData.company_number } key={ resultOneData.company_number }
                              chCompany={ resultOneData }>
                          <TitleWithIcon title={ resultOneData.company_name }/>
                      </Option>
                ) }
            </Select>
            { !disableAllGuidance && <Typography.Text className="text-xs">
                Please include a &apos;0&apos; at the start if company number only has 7 digits.
            </Typography.Text> }
        </FormItem>

        { !disableAllGuidance && selectedCompany && <div className="-mt-4">
            <Typography.Text className="block text-primary dark:text-primary-dark">
                Chosen company:
            </Typography.Text>
            <Typography.Text className="block mb-4">
                { 'title' in selectedCompany ?
                      selectedCompany.title
                      :
                      selectedCompany.company_name
                }
                &nbsp;({ selectedCompany.company_number })
            </Typography.Text>
            <Typography.Text className="block text-primary dark:text-primary-dark">
                Registered Address:
            </Typography.Text>
            <Typography.Text className="block">
                { 'address_snippet' in selectedCompany ?
                      selectedCompany.address_snippet
                      :
                      formatCompanyAddress( selectedCompany.registered_office_address )
                }
            </Typography.Text>
        </div> }
    </> );
} );

CHCompanyLookup.displayName = 'CHCompanyLookup';