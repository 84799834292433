import { accountApi, accountApiReducer } from './account';
import { companyApi, companyApiReducer } from './company';
import { companyChApi, companyChApiReducer } from './company/service.ch';
import { datastoreApi, datastoreApiReducer } from './datastore';
import { portfolioApi, portfolioApiReducer } from './portfolio';
import { userApi, userApiReducer } from './user';


export const rtkAPIMiddleware = [
    accountApi.middleware,
    companyApi.middleware,
    companyChApi.middleware,
    datastoreApi.middleware,
    portfolioApi.middleware,
    userApi.middleware
];

export const rtkAPIReducers = {
    [ accountApi.reducerPath ]: accountApiReducer,
    [ companyApi.reducerPath ]: companyApiReducer,
    [ companyChApi.reducerPath ]: companyChApiReducer,
    [ datastoreApi.reducerPath ]: datastoreApiReducer,
    [ portfolioApi.reducerPath ]: portfolioApiReducer,
    [ userApi.reducerPath ]: userApiReducer,
};