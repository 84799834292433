import type { ComponentPropsWithoutRef } from 'react';
import ReactFlagsSelectMain from 'react-flags-select';
import styled from 'styled-components';

import twConfig from '@/shared/tailwindConfig';

import COUNTRIES from '@/data/countries.json';
import NATIONALITIES from '@/data/nationalities.json';


const arrayToObject = <T, >( arr: Array<T> ) => arr.reduce<T>( ( prev, curr ) => ( { ...prev, ...curr } ), {} as T );

const countryCodes: Props['countries'] = COUNTRIES.map( c => c.code );
const countryLabels = arrayToObject<Props['customLabels']>( COUNTRIES.map( c => ( {
    [ c.code ]: c.name
} ) ) );
const nationalityLabels = arrayToObject<Props['customLabels']>( NATIONALITIES.map( c => ( {
    [ c.code ]: c.nationality
} ) ) );

type Props = ComponentPropsWithoutRef<typeof ReactFlagsSelectMain>;
export const ReactFlagsSelect = ( props: Props & { useNationalities?: boolean, removeDropdownArrow?: boolean } ) =>
{
    const { useNationalities, ...baseProps } = props;

    return <StyledReactFlagsSelectMain
          optionsSize={ twConfig.theme.fontSize.md }
          selectedSize={ twConfig.theme.fontSize.md }
          selectButtonClassName={ `${ buttonStyles } ${ props.removeDropdownArrow ? `after:!content-[] disabled:!bg-white disabled:cursor-text` : '' }` }
          searchable
          showSecondarySelectedLabel={ false }
          countries={ countryCodes }
          customLabels={ useNationalities ? nationalityLabels : countryLabels }
          searchPlaceholder=" "
          placeholder=" "
          { ...baseProps }
    />;
};

const buttonStyles = `
    ant-input
    ant-input-lg
    !text-lg
    !h-[2.625rem]
    !border-border
        dark:!border-border-dark
    !rounded-[0.575rem]
    after:!border-t-primary
        dark:after:!border-t-primary-dark
    after:!ml-0
    !pl-0
    !text-default
        dark:!text-default-dark
    focus:!border-primary
        dark:focus:!border-primary-dark
    hover:!border-primary
        dark:hover:!border-primary-dark
`;

const StyledReactFlagsSelectMain = styled( ReactFlagsSelectMain )`
  & {
     input {
         border-style: none;
         border-width: 0;
         border-radius: 0;
         border-color: ${ twConfig.theme.colors.border.DEFAULT };
         border-bottom-width: 1px;
         border-bottom-style: solid;
         height: 2.5rem;
         margin: 0;
         width: 100%;
     } 
      ul {
          border-radius: 0.75rem;
          
          div {
              padding-top: 0;
              padding-left: 0;
              padding-right: 0;
          }
      }
      ul::-webkit-scrollbar-track, ul::-webkit-scrollbar-thumb {
          border-radius: 2rem;
      }
  }
`;