import { type FC, memo, useCallback, useMemo } from 'react';

import { doPatchCurrentUser } from '@/store/user/actions';
import { useAppDispatch } from '@/store/hooks';
import { useCompanies, useCompanyWidgetsFilingDeadlines } from '@/store/company/hooks';

import { CardWithSpacedAndColouredRows, DataFormat, Loader, TitleWithIcon } from '@/components/structural';
import { prepareColumnItem } from '@/components/tables/sorting-tools/prepareColumnItem';
import { Table } from '@/components/tables/Table';

import { getPriorityAndAlertPill, parseCompanies } from '@/features/company/my-company/widgets/filing/filing-deadlines-data';

import { compare } from '@/shared/tools';

import { type ISorter } from '@/components/tables';


export const FilingDeadlinesListWidget: FC = memo( () =>
{
    const dispatch = useAppDispatch();
    const { companies, isFetching } = useCompanies();
    const { selectedCompany } = useCompanyWidgetsFilingDeadlines();

    const changeCurrentCompanyTo = useCallback( ( companyUuid: string ) =>
    {
        dispatch( doPatchCurrentUser( {
            settings: {
                currentCompanyUuid: companyUuid
            }
        } ) );
    }, [] );

    const data = useMemo( () => parseCompanies( companies || [] ), [ companies ] );
    type DataType = typeof data[number];

    const getColumns = useCallback( ( sortByColumn: ISorter ) => [
        prepareColumnItem<DataType>( {
            title: 'Company',
            key: 'company',
            render: ( { uuid, name, colour, colourDark, type } ) =>
                  <div className={ `alert-${ type }` }>
                      <a onClick={ () => changeCurrentCompanyTo( uuid ) } className="w-full">
                          <TitleWithIcon
                                title={ name }
                                iconBgColour={ colour }
                                iconBgColourDark={ colourDark }
                          />
                      </a>
                  </div>,
            sorter: ( a: { name: string }, b: { name: string } ) => compare( a.name, b.name, true )
        }, sortByColumn ),
        prepareColumnItem<DataType>( {
            title: 'Filing',
            key: 'filing',
            render: text => <DataFormat raw={ text } type="pill" className="!text-default"/>
        } ),
        prepareColumnItem<DataType>( {
            title: 'Due Date',
            key: 'date',
            render: date => <DataFormat raw={ date } type="date"/>
        }, sortByColumn ),
        prepareColumnItem<DataType>( {
            title: 'Priority',
            key: 'priority',
            render: ( text: string ) => getPriorityAndAlertPill( text ),
        } ),
    ], [] );

    if ( isFetching ) return <Loader skeleton/>;

    return ( <>
        <CardWithSpacedAndColouredRows title="Filing Deadlines" alternateTableRowBg={ false }>
            <Table
                  parsedData={ data }
                  getColumns={ getColumns }
                  sortByKeys={ [ 'company', 'date' ] }
                  size="small"
                  selectedRowKey={ selectedCompany }
                  disablePagination
                  disableColumnsSelector
                  disableSearch
                  disableHorizontalScroll
                  scroll={ { y: '38rem' } }
            />
        </CardWithSpacedAndColouredRows>
    </> );
} );

FilingDeadlinesListWidget.displayName = 'FilingDeadlinesListWidget';