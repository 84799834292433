import { call, put, takeEvery } from 'redux-saga/effects';

import type { ISignUpResult } from 'amazon-cognito-identity-js';

import { authService } from '@/store/auth';
import { createNotification } from '@/store/notifications/actions';
import { doLoginNoPassword, doSignupWithoutConfirmationEmail, doSignupWithoutConfirmationEmailError, doSignupWithoutConfirmationEmailSuccess } from '@/store/auth/actions';
import { HTTPErrorOccurred, setRequestState } from '@/store/internal/actions';

import handleError from './handleError';
import { processError } from '@/shared/processError';


function* signupWithoutConfirmationEmailScript( { type, payload }: ReturnType<typeof doSignupWithoutConfirmationEmail> )
{
    yield put( setRequestState( { id: type, loading: true } ) );
    try
    {
        const result: ISignUpResult = yield call( authService.signUpNoConfirm, payload );
        const resultStr = JSON.stringify( result ).toLowerCase();

        if ( resultStr.includes( 'exception' ) && !resultStr.includes( 'lambda' ) )
        {
            yield put( createNotification( {
                title: 'This account already exists. Please login or reset your password instead.',
                type: 'error'
            } ) );

            return;
        }

        yield put( setRequestState( { id: type, loading: false } ) );
        yield put( doSignupWithoutConfirmationEmailSuccess( { email: result.user.getUsername() } ) );
    } catch ( err )
    {
        const error = handleError( err );

        processError( 'onSignupWithoutConfirmationEmail saga', error );

        yield put( setRequestState( { id: type, error, loading: false } ) );
        yield put( HTTPErrorOccurred( error ) );
        yield put( doSignupWithoutConfirmationEmailError( error ) );
    }
}

function* signupWithoutConfirmationEmailSuccessScript( { payload }: ReturnType<typeof doSignupWithoutConfirmationEmailSuccess> )
{
    yield put( doLoginNoPassword( payload ) );
}

export function* onSignupWithoutConfirmationEmailSaga()
{
    yield takeEvery( doSignupWithoutConfirmationEmail, signupWithoutConfirmationEmailScript );
    yield takeEvery( doSignupWithoutConfirmationEmailSuccess, signupWithoutConfirmationEmailSuccessScript );
}