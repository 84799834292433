import { useMemo } from 'react';

import { getMetaDefaultObject } from '@/store/_rtk-query/getMetaDefaultObject';
import { useCurrentCompany } from '@/store/company/hooks/useCurrentCompany.hook';

import { convertibleLoanNoteAdapter, useGetManyConvertibleLoanNotesQuery } from '@/store/company';
import { convertibleLoanNoteSelectors } from '@/store/company/selectors';

import { compare } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORGetParameters } from '@/store/_models/get-parameters.interface';


export const useConvertibleLoanNotes = ( getParameters: IORGetParameters = { page: 1, per_page: DEFAULT_PER_PAGE }, skip = false ) =>
{
    const { orCompany } = useCurrentCompany();
    const { data, isFetching, isLoading, error } = useGetManyConvertibleLoanNotesQuery( getParameters, {
        skip: skip || !orCompany
    } );

    const convertibleLoanNotes = convertibleLoanNoteSelectors.selectAll(
          data ||
          convertibleLoanNoteAdapter.getInitialState()
    );

    convertibleLoanNotes.sort( ( a, b ) => compare( a.maturity_date, b.maturity_date, false ) );

    const meta = data?.meta || getMetaDefaultObject();

    return useMemo( () => ( {
        convertibleLoanNotes,
        meta,
        isLoading,
        isFetching,
        error
    } ), [ data, isFetching, isLoading, error ] );
};