import { createSelector } from '@reduxjs/toolkit';
import type { TRootState } from '@/store';


export const selectInternalState = ( state: TRootState ) => state.internal;

export const selectHttp = createSelector(
      selectInternalState,
      ( internal ) => internal.http
);

export const selectRequests = createSelector(
      selectHttp,
      ( http ) => http.requests
);

export const selectMostRecentRequest = createSelector(
      selectHttp,
      ( http ) => http.mostRecentRequest
);

export const selectHTTPRequest = createSelector(
      selectHttp,
      ( http ) => http.httpRequest
);

export const selectPageState = createSelector(
      selectInternalState,
      ( internal ) => internal.page
);

export const selectPageViewSelectorVisibility = createSelector(
      selectPageState,
      ( page ) => page.showViewSelector
);

export const selectPageViewSelectorState = createSelector(
      selectPageState,
      ( page ) => page.viewSelectorState
);

export const selectAppState = createSelector(
      selectInternalState,
      ( internal ) => internal.app
);

export const selectAppViewState = createSelector(
      selectAppState,
      ( app ) => app.view
);