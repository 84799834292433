import type { ORColumnType } from '@/components/tables/types';


export interface ICellActions<T>
{
    click?: Record<keyof T, ( record: T, cellIndex: number ) => void>,
    dblClick?: Record<keyof T, ( record: T, cellIndex: number ) => void>
}

export const mapCellActions = <T>( c: ORColumnType<T>, actions: ICellActions<T> ) =>
{
    const { click = {}, dblClick = {} } = actions;

    c.onCell = ( record, cellIndex ) => ( {
        onClick: () => typeof click[ c.key ] === 'function' && click[ c.key ]( record, cellIndex ),
        onDoubleClick: () => typeof dblClick[ c.key ] === 'function' && dblClick[ c.key ]( record, cellIndex ),
        ...( c.$onCell( record, cellIndex ) ),
    } );

    return c;
};