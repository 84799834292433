import { combineReducers } from '@reduxjs/toolkit';

import { actions } from '@/store/_global/actions-store/actions.reducer';

import { authSliceKey, authStoreSlice } from './auth';
import { companySlice, companySliceKey } from './company';
import { datastoreSlice, datastoreSliceKey } from './datastore';
import { internalSliceKey, internalStoreSlice } from './internal';
import { notificationsSlice, notificationsSliceKey } from './notifications';
import { portfolioSlice, portfolioSliceKey } from './portfolio';

import { rtkAPIReducers } from './rtk-queries';


const rootReducer = combineReducers( {
    [ authSliceKey ]: authStoreSlice,
    [ companySliceKey ]: companySlice,
    [ datastoreSliceKey ]: datastoreSlice,
    [ internalSliceKey ]: internalStoreSlice,
    [ notificationsSliceKey ]: notificationsSlice,
    [ portfolioSliceKey ]: portfolioSlice,

    ...rtkAPIReducers,

    actions,
} );

export default rootReducer;

/*// @ts-expect-error
if ( process.env.NODE_ENV !== 'production' && module.hot )
{
    // @ts-expect-error
    module.hot.accept();
}*/
