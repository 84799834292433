import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { providesIds, providesList } from '@/store/_rtk-query';

import { globalAttachmentCreateSuccess, type IORAttachment, type IORAttachmentMainAttributes } from '@/store/_global/attachments';

import { companyApi } from './service.company';

import { compare, getCookie } from '@/shared/tools';
import { processError } from '@/shared/processError';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompanyFilingCompanyRegisteredName, IORCompanyFilingCompanyRegisteredNameMainAttributes } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const filingCompanyRegisteredNameAdapter = createEntityAdapter<IORCompanyFilingCompanyRegisteredName>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at ),
    selectId: ( e ) => e.uuid,
} );

export const filingCompanyRegisteredNameApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneFilingCompanyRegisteredName: builder.query<IORCompanyFilingCompanyRegisteredName, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/companies-house/filings/registered-name/${ uuid }`, method: 'get'
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'FilingCompanyRegisteredName' )
            ),
        } ),
        getManyFilingsCompanyRegisteredName: builder.query<EntityState<IORCompanyFilingCompanyRegisteredName> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/companies-house/filings/registered-name/`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'FilingsCompanyRegisteredName', 'FilingCompanyRegisteredName' )
            ),
            transformResponse: ( data: IORCompanyFilingCompanyRegisteredName[], meta: IORResponseMetaData ) => ( {
                ...filingCompanyRegisteredNameAdapter.upsertMany( filingCompanyRegisteredNameAdapter.getInitialState(), data ),
                ...{ meta }
            } )
        } ),
        patchFilingCompanyRegisteredName: builder.mutation<IORCompanyFilingCompanyRegisteredName, { uuid: string, data: Partial<IORCompanyFilingCompanyRegisteredNameMainAttributes> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/companies-house/filings/registered-name/${ uuid }/`, method: 'patch', data
            } ),
            invalidatesTags: ( _, __, { uuid } ) => (
                  providesIds( [ uuid ], 'FilingCompanyRegisteredName' )
            )
        } ),
        postFilingCompanyRegisteredName: builder.mutation<IORCompanyFilingCompanyRegisteredName, Partial<IORCompanyFilingCompanyRegisteredNameMainAttributes>>( {
            query: ( data ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/companies-house/filings/registered-name/`, method: 'post', data
            } ),
            invalidatesTags: () => (
                  providesList( [], 'FilingsCompanyRegisteredName' )
            )
        } ),
        postFilingCompanyRegisteredNameAttachment: builder.mutation<IORAttachment, IORAttachmentMainAttributes>( {
            query: ( data ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/companies-house/filings/registered-name/${ data.entity_id }/attachments/`, method: 'post', data
            } ),
            invalidatesTags: ( _, __, { entity_id } ) => (
                  providesList( [ entity_id ], 'FilingsCompanyRegisteredName', 'FilingCompanyRegisteredName' )
            ),
            onQueryStarted: async ( args, api ) =>
            {
                try
                {
                    const { data } = await api.queryFulfilled;

                    api.dispatch( globalAttachmentCreateSuccess(
                          data
                    ) );
                } catch ( error )
                {
                    processError( 'filingCompanyRegisteredNameApi Service', error );
                }
            }
        } ),
        deleteFilingCompanyRegisteredNameAttachment: builder.mutation<void, { filing_id: string, uuid: string }>( {
            query: ( { filing_id, uuid } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/companies-house/filings/registered-name/${ filing_id }/attachments/${ uuid }`, method: 'delete'
            } ),
            invalidatesTags: ( _, __, { filing_id } ) => (
                  providesList( [ filing_id ], 'FilingsCompanyRegisteredName', 'FilingCompanyRegisteredName' )
            )
        } ),
        deleteFilingCompanyRegisteredName: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/companies-house/filings/registered-name/${ uuid }`, method: 'delete'
            } ),
            invalidatesTags: ( _, __, uuid ) => (
                  providesList( [ uuid ], 'FilingsCompanyRegisteredName', 'FilingCompanyRegisteredName' )
            )
        } ),
    } ),
} );

export const {
    useGetOneFilingCompanyRegisteredNameQuery,
    useLazyGetOneFilingCompanyRegisteredNameQuery,
    useGetManyFilingsCompanyRegisteredNameQuery,
    useLazyGetManyFilingsCompanyRegisteredNameQuery,
    usePatchFilingCompanyRegisteredNameMutation,
    usePostFilingCompanyRegisteredNameMutation,
    usePostFilingCompanyRegisteredNameAttachmentMutation,
    useDeleteFilingCompanyRegisteredNameAttachmentMutation,
    useDeleteFilingCompanyRegisteredNameMutation,
    endpoints: filingCompanyRegisteredNameApiEndpoints,
} = filingCompanyRegisteredNameApi;