import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { compare, getCookie } from '@/shared/tools';
import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompanyNote, IORCompanyNoteMainAttributes } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const noteAdapter = createEntityAdapter<IORCompanyNote>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at ),
    selectId: ( e ) => e.uuid,
} );

const addCurrentUser = ( data: Partial<IORCompanyNoteMainAttributes> ) => Object.assign( data, {
    added_by: getCookie( { cName: 'orCurrentUserUuid' } )
} );

export const noteApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneNote: builder.query<IORCompanyNote, string>( {
            query: ( uuid ) => ( {
                url: `/temp/company-note/${ uuid }`,
                overrideUrl: true,
                method: 'get',
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'Note' )
            )
        } ),
        getManyNotes: builder.query<EntityState<IORCompanyNote> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/temp/company-note/`,
                overrideUrl: true,
                method: 'get',
                queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                },
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'Notes', 'Note' )
            ),
            transformResponse: ( data: IORCompanyNote[], meta: IORResponseMetaData ) => ( {
                ...noteAdapter.upsertMany( noteAdapter.getInitialState(), data ),
                ...{ meta },
            } ),
        } ),
        patchNote: builder.mutation<IORCompanyNote, { uuid: string, data: Partial<IORCompanyNoteMainAttributes> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/temp/company-note/${ uuid }`,
                overrideUrl: true,
                method: 'patch',
                data: addCurrentUser( data ),
            } ),
            invalidatesTags: ( _, __, { uuid } ) => ( [
                ...providesIds( [ uuid ], 'Note' ),
                ...providesList( [], 'Notes' ),
            ] ),
        } ),
        postNote: builder.mutation<IORCompanyNote, Partial<IORCompanyNoteMainAttributes>>( {
            query: ( data ) => ( {
                url: `/temp/company-note/`,
                overrideUrl: true,
                method: 'post',
                data: addCurrentUser( data ),
            } ),
            invalidatesTags: () => (
                  providesList( [], 'Notes' )
            ),
        } ),
        deleteNote: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/temp/company-note/${ uuid }`,
                overrideUrl: true,
                method: 'delete',
            } ),
            invalidatesTags: ( _, __, uuid ) => (
                  providesList( [ uuid ], 'Notes', 'Note' )
            ),
        } ),
    } ),
} );

export const {
    useGetOneNoteQuery,
    useLazyGetOneNoteQuery,
    useGetManyNotesQuery,
    usePatchNoteMutation,
    usePostNoteMutation,
    useDeleteNoteMutation,
    endpoints: noteApiEndpoints,
} = noteApi;