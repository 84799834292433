import { call, put, takeEvery } from 'redux-saga/effects';

import { authService } from '@/store/auth';
import { doLogin, doLoginError, doLoginSuccess, setAWSUser, } from '@/store/auth/actions';
import { HTTPErrorOccurred, setRequestState } from '@/store/internal/actions';

import handleError from './handleError';
import { processError } from '@/shared/processError';
import { routerNavigate } from '@/components/structural';

import type { IAWSUser } from '@/features/auth/models';


function* loginScript( { type, payload }: ReturnType<typeof doLogin> )
{
    yield put( setRequestState( { id: type, loading: true } ) );
    try
    {
        const user: IAWSUser = yield call( authService.signIn, payload );
        yield commonLoginProcessSuccessScript( type, user );
    } catch ( error )
    {
        yield commonLoginProcessFailureScript( type, error );
    }
}

function* loginSuccessScript( { payload }: ReturnType<typeof doLoginSuccess> )
{
    yield put( setAWSUser( payload ) );
}

export function* onLoginSaga()
{
    yield takeEvery( doLogin, loginScript );
    yield takeEvery( doLoginSuccess, loginSuccessScript );
}


export function* commonLoginProcessSuccessScript( type: string, user: IAWSUser )
{
    yield put( setRequestState( { id: type, loading: false } ) );
    yield put( doLoginSuccess( user ) );
    yield routerNavigate( '/landing' );
}

export function* commonLoginProcessFailureScript( type: string, err: unknown )
{
    const error = handleError( err );

    processError( 'onLogin saga', error );

    yield put( setRequestState( { id: type, error, loading: false } ) );
    yield put( HTTPErrorOccurred( error ) );
    yield put( doLoginError( error ) );
}