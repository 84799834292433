import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { compare, getCookie } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';
import type { IORStakeholder, IORStakeholderAddress, IORStakeholderMainAttributes } from '@/features/stakeholders/models';

import COUNTRIES from '@/data/countries.json';
import { normaliseCompanyNameString } from '@/features/company/shared/tools';

import { processError } from '@/shared/processError';


export const stakeholderAdapter = createEntityAdapter<IORStakeholder>( {
    sortComparer: ( a, b ) => compare( a.last_name, b.last_name ),
    selectId: ( e ) => e.uuid,
} );

export const mapStakeholder = ( stakeholder: IORStakeholder ) =>
{
    const entity_name = normaliseCompanyNameString( stakeholder.entity_name || '' );
    const country_full = COUNTRIES.find( c => c.code === stakeholder.address?.country )?.name || '';
    const country_of_residence_full = COUNTRIES.find( c => c.code === stakeholder.country_of_residence )?.name || '';
    const nationality_full = COUNTRIES.find( c => c.code === stakeholder.nationality )?.name || '';

    return {
        ...stakeholder,
        entity_name,
        country_of_residence_full,
        nationality_full,
        address: {
            ...stakeholder.address,
            country_full
        }
    } as IORStakeholder;
};

export const stakeholderApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneStakeholder: builder.query<IORStakeholder, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/stakeholder/${ uuid }`, method: 'get'
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'Stakeholder' )
            ),
            transformResponse: mapStakeholder,
        } ),
        getManyStakeholders: builder.query<EntityState<IORStakeholder> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/stakeholder/`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'Stakeholders', 'Stakeholder' )
            ),
            transformResponse: ( data: IORStakeholder[], meta: IORResponseMetaData ) => ( {
                ...stakeholderAdapter.upsertMany( stakeholderAdapter.getInitialState(), data.map( mapStakeholder ) ),
                ...{ meta }
            } ),
        } ),
        patchStakeholder: builder.mutation<IORStakeholder, { uuid: string, data: Partial<IORStakeholderMainAttributes> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/stakeholder/${ uuid }`, method: 'patch', data
            } ),
            invalidatesTags: () => (
                  providesList( [], 'Stakeholders' )
            )
        } ),
        postStakeholder: builder.mutation<IORStakeholder, Partial<IORStakeholderMainAttributes>>( {
            query: ( data ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/stakeholder/`, method: 'post', data
            } ),
            invalidatesTags: () => (
                  providesList( [], 'Stakeholders' )
            )
        } ),
        putStakeholderAddress: builder.mutation<IORStakeholderAddress, { uuid: string, data: Partial<IORStakeholderAddress> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/stakeholder/${ uuid }/address`, method: 'put', data
            } ),
            invalidatesTags: ( _, __, { uuid } ) => (
                  providesList( [ uuid ], 'Stakeholders', 'Stakeholder' )
            ),
            onQueryStarted: async ( { uuid }, api ) =>
            {
                try
                {
                    await api.queryFulfilled;
                    api.dispatch( stakeholderApi.endpoints.getOneStakeholder.initiate( uuid ) );
                } catch ( error )
                {
                    processError( 'stakeholderApi Service', error );
                }
            }
        } ),
        deleteStakeholder: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/stakeholder/${ uuid }`, method: 'delete'
            } ),
            invalidatesTags: ( _, __, uuid ) => (
                  providesList( [ uuid ], 'Stakeholders', 'Stakeholder' )
            )
        } ),
    } ),
} );

export const {
    useGetOneStakeholderQuery,
    useLazyGetOneStakeholderQuery,
    useGetManyStakeholdersQuery,
    useLazyGetManyStakeholdersQuery,
    usePatchStakeholderMutation,
    usePostStakeholderMutation,
    usePutStakeholderAddressMutation,
    useDeleteStakeholderMutation,
    endpoints: stakeholderApiEndpoints,
} = stakeholderApi;