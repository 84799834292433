import { useMemo } from 'react';

import { useCurrentCompany } from '@/store/company/hooks/useCurrentCompany.hook';
import { getMetaDefaultObject } from '@/store/_rtk-query/getMetaDefaultObject';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import { useStakeholders } from '@/store/company/hooks/useStakeholders.hook';
import { useShareIssues } from '@/store/company/hooks/useShareIssues.hook';
import { useShareTransfers } from '@/store/company/hooks/useShareTransfers.hook';
import { useShareholdings } from '@/store/company/hooks/useShareholdings.hook';
import { useShareholdingsSummary } from '@/store/company/hooks/useShareholdingsSummary.hook';

import type { IORGetParameters } from '@/store/_models/get-parameters.interface';


export const useShareholders = ( getParameters: IORGetParameters = { page: 1, per_page: DEFAULT_PER_PAGE }, skip = false ) =>
{
    const { orCompany } = useCurrentCompany();
    const {
        stakeholders,
        meta: stakeholdersMeta,
        isFetching: stakeholdersIsFetching,
        isLoading: stakeholdersIsLoading,
        error: stakeholdersError,
        status: stakeholdersStatus
    } = useStakeholders( getParameters, skip || !orCompany );

    const stakeholdersIDs = stakeholders?.map( s => s.uuid ) || [];

    const { shareIssues, isFetching: issuesIsFetching, isLoading: issuesIsLoading, error: issuesError } = useShareIssues( {
        page: 1,
        per_page: DEFAULT_PER_PAGE
    }, skip || !orCompany || !stakeholdersIDs.length );

    const { shareTransfers, isFetching: transfersIsFetching, isLoading: transfersIsLoading, error: transfersError } = useShareTransfers( {
        page: 1,
        per_page: DEFAULT_PER_PAGE
    }, skip || !orCompany || !stakeholdersIDs.length );

    const { shareholdings, isFetching: shareholdingsIsFetching, isLoading: shareholdingsIsLoading, error: shareholdingsError } = useShareholdings( {
        page: 1,
        per_page: DEFAULT_PER_PAGE,
        ordering: {
            field: 'issue_date',
            direction: 'desc'
        }
    }, skip || !orCompany || !stakeholdersIDs.length );

    const { shareholdingsSummary, isFetching: shareholdingsSummaryIsFetching, isLoading: shareholdingsSummaryIsLoading, error: shareholdingsSummaryError } = useShareholdingsSummary(
          skip || !orCompany || !stakeholdersIDs.length
    );

    const shareholders = useMemo( () => (
          stakeholdersIDs.map( stakeholderId =>
          {
              const stakeholder = stakeholders.find( s => s.uuid === stakeholderId );
              const issuesFiltered = shareIssues.filter( issue =>
                    issue.stakeholder === stakeholderId
              );
              const transfersFiltered = shareTransfers.filter( transfer =>
                    transfer.to_stakeholder === stakeholderId || transfer.from_stakeholder === stakeholderId
              );
              const shareholdingsFiltered = shareholdings.filter( shareholding =>
                    shareholding.stakeholder === stakeholderId
              );
              const shareholdingsSummaryFiltered = ( shareholdingsSummary.filter( shareholdingSummary =>
                    shareholdingSummary.stakeholder === stakeholderId
              ) || [] )[ 0 ] || null;

              return {
                  ...stakeholder,
                  issues: issuesFiltered,
                  transfers: transfersFiltered,
                  shareholdings: shareholdingsFiltered,
                  shareholdingsSummary: shareholdingsSummaryFiltered,
              };
          } ).filter( shareholder => shareholder.issues.length || shareholder.transfers.length || shareholder.shareholdings.length )
    ), [
        shareIssues, shareTransfers, shareholdings, shareholdingsSummary, stakeholdersIDs, stakeholders
    ] );

    const meta = stakeholdersMeta || getMetaDefaultObject();

    const isLoading = stakeholdersIsLoading || issuesIsLoading || transfersIsLoading || shareholdingsIsLoading || shareholdingsSummaryIsLoading;
    const isFetching = stakeholdersIsFetching || issuesIsFetching || transfersIsFetching || shareholdingsIsFetching || shareholdingsSummaryIsFetching;
    const error = stakeholdersError || issuesError || transfersError || shareholdingsError || shareholdingsSummaryError;

    return useMemo( () => ( {
        shareholders,
        meta,
        isLoading,
        isFetching,
        error,
        status: stakeholdersStatus
    } ), [ shareholders, isFetching, isLoading, error, stakeholdersStatus ] );
};