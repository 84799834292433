import styled from 'styled-components';
import tw from 'tailwind-styled-components';

import RadioAntD, { type RadioChangeEvent } from 'antd/lib/radio';
import RadioGroupAntD from 'antd/lib/radio/group';

import twConfig from '@/shared/tailwindConfig';


export const RadioGroup = tw( RadioGroupAntD )``;

const RadioBase = tw( RadioAntD )`
    text-primary
    dark:text-primary-dark
`;

export const Radio = styled( RadioBase )`
    & span.success {
        color: ${ twConfig.theme.colors.success.DEFAULT };
    }

    .dark & span.success {
        color: ${ twConfig.theme.colors.success.dark };
    }
`;

export type { RadioChangeEvent };