import { type FC, memo, useEffect, useMemo, useRef } from 'react';

import { Input } from '@/components/structural/form';
import { processError } from '@/shared/processError';


export interface IAddressLookupProps
{
    className?: string,
    onSelect: ( address: IAddressLookupResult ) => void,
    defaultCountry?: string,
}

export const AddressLookup: FC<IAddressLookupProps> = memo( ( props ) =>
{
    const fetchifyRef = useRef( null );
    const domElId = useMemo( () => 'address_search', [] );

    useEffect( () =>
    {
        try
        {
            // @ts-expect-error
            fetchifyRef.current = new clickToAddress( {
                accessToken: process.env.NEXT_PUBLIC_FETCHIFY_KEY, // Replace this with your access token
                dom: {
                    search: domElId,
                },
                domMode: 'id',
                defaultCountry: 'gbr',
                disableAutoSearch: false,
                transliterate: true,
                onResultSelected: ( c2a, elements, address ) =>
                {
                    props.onSelect?.( address );
                    setTimeout( () =>
                    {
                        ( document.getElementById( domElId ) as HTMLInputElement ).value = '';
                    }, 100 );
                },
            } );
        } catch ( e )
        { processError( 'AddressLookup Component', e ); }

        const timeout = setTimeout( () =>
        {
            fetchifyRef.current?.selectCountry( 'GB' );
        }, 1000 );

        return () =>
        {
            clearTimeout( timeout );
            document.getElementById( 'cc_c2a' )?.remove();
        };
    }, [] );

    useEffect( () =>
    {
        if ( props.defaultCountry ) fetchifyRef.current?.selectCountry( props.defaultCountry );
    }, [ props.defaultCountry ] );

    return ( <>
        <div className={ `${ props.className }` }>
            <Input
                  type="text"
                  id={ domElId }
                  className="text-md"
            />
        </div>
    </> );
} );

export interface IAddressLookupResult
{
    administrative_area: string;
    administrative_area_latin: string;
    alternative_administrative_area: string;
    alternative_locality: string;
    alternative_province: string;
    building_name: string;
    building_number: string;
    company_name: string;
    country_name: string;
    country: {
        alternative_names: string[],
        code: string,
        country_name: string,
        intl_long_name: string,
        iso_3166_1_alpha_2: string,
        iso_3166_1_alpha_3: string,
        iso_3166_1_numeric_3: number,
        official_name: string,
        short_code: string,
    };
    department_name: string;
    dependent_locality: string;
    dependent_street_name: string;
    dependent_street_prefix: string;
    dependent_street_suffix: string;
    double_dependent_locality: string;
    double_dependent_street_name: string;
    double_dependent_street_prefix: string;
    double_dependent_street_suffix: string;
    level_name: string;
    line_1: string;
    line_2: string;
    line_3: string;
    line_4: string;
    line_5: string;
    locality: string;
    post_office_box_number: string;
    post_office_reference_1: string;
    post_office_reference_2: string;
    post_office_reference_3: string;
    post_office_reference_4: string;
    post_office_reference_5: string;
    post_office_reference_6: string;
    postal_code: string;
    province: string;
    province_code: string;
    province_latin: string;
    province_name: string;
    street_name: string;
    street_prefix: string;
    street_suffix: string;
    sub_building_name: string;
    type: string;
    unit_name: string;
    extra: IAddressLookupResultExtra;
}

export interface IAddressLookupResultExtra
{
    geolocation: IAddressLookupResultGeolocation;
}

export interface IAddressLookupResultGeolocation
{
    latitude: number;
    longitude: number;
}


AddressLookup.displayName = 'AddressLookup';