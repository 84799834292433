import { useMemo } from 'react';

import { useAppSelector } from '@/store/hooks';
import { selectRequests } from '@/store/internal/selectors';


export const useRequests = () =>
{
    const requests = useAppSelector( selectRequests );

    return useMemo( () => ( {
        requests
    } ), [ requests ] );
};