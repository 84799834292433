import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { compare, getCookie } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompanyFiling } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const filingAdapter = createEntityAdapter<IORCompanyFiling>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at ),
    selectId: ( e ) => e.uuid,
} );

export const filingApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneFiling: builder.query<IORCompanyFiling, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/filing/${ uuid }`, method: 'get'
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'Filing' )
            ),
        } ),
        getManyFilings: builder.query<EntityState<IORCompanyFiling> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/filing/`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'Filings', 'Filing' )
            ),
            transformResponse: ( data: IORCompanyFiling[], meta: IORResponseMetaData ) => ( {
                ...filingAdapter.upsertMany( filingAdapter.getInitialState(), data ),
                ...{ meta }
            } )
        } ),
    } ),
} );

export const {
    useGetOneFilingQuery,
    useLazyGetOneFilingQuery,
    useGetManyFilingsQuery,
    useLazyGetManyFilingsQuery,
    endpoints: filingApiEndpoints,
} = filingApi;