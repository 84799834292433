import { useMemo } from 'react';

import { getMetaDefaultObject } from '@/store/_rtk-query/getMetaDefaultObject';
import { useCurrentCompany } from '@/store/company/hooks/useCurrentCompany.hook';

import { filingAdapter, useGetManyFilingsQuery } from '@/store/company';
import { filingSelectors } from '@/store/company/selectors';

import { compare } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORGetParameters } from '@/store/_models/get-parameters.interface';


export const useFilings = ( getParameters: IORGetParameters = { page: 1, per_page: DEFAULT_PER_PAGE }, skip = false ) =>
{
    const { orCompany } = useCurrentCompany();
    const { data, isFetching, isLoading, error } = useGetManyFilingsQuery( getParameters, {
        skip: skip || !orCompany
    } );

    const filings = filingSelectors.selectAll(
          data ||
          filingAdapter.getInitialState()
    );

    filings.sort( ( a, b ) => compare( a.updated_at, b.updated_at, true ) );

    const meta = data?.meta || getMetaDefaultObject();

    return useMemo( () => ( {
        filings,
        meta,
        isLoading,
        isFetching,
        error
    } ), [ data, isFetching, isLoading, error ] );
};