import { call, put, takeEvery } from 'redux-saga/effects';

import { authService } from '@/store/auth';
import { doLoginNoPassword, doSignupConfirmAccountWithCode, doSignupConfirmAccountWithCodeError, doSignupConfirmAccountWithCodeSuccess } from '@/store/auth/actions';
import { HTTPErrorOccurred, setRequestState } from '@/store/internal/actions';

import handleError from './handleError';
import { processError } from '@/shared/processError';


function* signupConfirmAccountWithCodeScript( { type, payload }: ReturnType<typeof doSignupConfirmAccountWithCode> )
{
    yield put( setRequestState( { id: type, loading: true } ) );
    try
    {
        yield call( authService.confirmSignup, payload );
        yield put( setRequestState( { id: type, loading: false } ) );
        yield put( doSignupConfirmAccountWithCodeSuccess( { email: payload.email } ) );
    } catch ( err )
    {
        const error = handleError( err );

        processError( 'onSignupConfirmAccountWithCode saga', error );

        yield put( setRequestState( { id: type, error, loading: false } ) );
        yield put( HTTPErrorOccurred( error ) );
        yield put( doSignupConfirmAccountWithCodeError( error ) );
    }
}

function* signupConfirmAccountWithCodeSuccessScript( { payload }: ReturnType<typeof doSignupConfirmAccountWithCodeSuccess> )
{
    yield put( doLoginNoPassword( payload ) );
}

export function* onSignupConfirmAccountWithCodeSaga()
{
    yield takeEvery( doSignupConfirmAccountWithCode, signupConfirmAccountWithCodeScript );
    yield takeEvery( doSignupConfirmAccountWithCodeSuccess, signupConfirmAccountWithCodeSuccessScript );
}