import { call, put, takeEvery } from 'redux-saga/effects';

import { authService } from '@/store/auth';
import { doForgotPassword, doForgotPasswordError, doForgotPasswordSuccess } from '@/store/auth/actions';
import { HTTPErrorOccurred, setRequestState } from '@/store/internal/actions';

import handleError from './handleError';
import { processError } from '@/shared/processError';
import { routerNavigate } from '@/components/structural/Link';


function* forgotPasswordScript( { type, payload }: ReturnType<typeof doForgotPassword> )
{
    yield put( setRequestState( { id: type, loading: true } ) );
    try
    {
        yield call( authService.forgotPassword, payload );
        yield put( setRequestState( { id: type, loading: false } ) );
        yield put( doForgotPasswordSuccess( payload ) );
    } catch ( err )
    {
        const error = handleError( err );

        processError( 'onForgotPassword saga', error );

        yield put( setRequestState( { id: type, error, loading: false } ) );
        yield put( HTTPErrorOccurred( error ) );
        yield put( doForgotPasswordError( error ) );
    }
}

function* forgotPasswordSuccessScript( /*{ payload: { email } }: ReturnType<typeof doForgotPasswordSuccess>*/ )
{
    yield routerNavigate( '/auth/forgot-password-request-success' );
}

export function* onForgotPasswordSaga()
{
    yield takeEvery( doForgotPassword, forgotPasswordScript );
    yield takeEvery( doForgotPasswordSuccess, forgotPasswordSuccessScript );
}