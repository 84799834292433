import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { configureReduxPersist } from '@/store/configureReduxPersist';

import { internalSliceKey } from './slice.key';

import type { IRequestPayload } from '@/shared/models';
import type { IInternalState } from './state.interface';


const initialState: IInternalState = {
    http: {
        requests: {},
        httpRequest: {
            loading: false,
            error: null
        },
        mostRecentRequest: {
            loading: false,
            id: null,
            error: null
        },
    },
    page: {
        showViewSelector: false,
        viewSelectorState: 'table'
    },
    app: {
        view: 'entity-management',
    }
};

const _slice = createSlice( {
    name: internalSliceKey,
    initialState,
    /**
     * The reducers property both creates an action creator function and responds to that action in the slice reducer.
     * Actions need to be exported, see ./actions/index.ts and list these reducers there
     */
    reducers: {
        setRequestState: ( state, action: PayloadAction<IRequestPayload> ) =>
        {
            if ( action.payload.id === 'httpRequest' )
            {
                state.http.httpRequest = {
                    error: action.payload.error,
                    loading: action.payload.loading || false
                };
            } else
            {
                state.http.requests[ action.payload.id ] = {
                    error: action.payload.error,
                    loading: action.payload.loading || false
                };
                state.http.mostRecentRequest = action.payload;
            }

        },
        showViewSelector: ( state ) =>
        {
            state.page.showViewSelector = true;
        },
        hideViewSelector: ( state ) =>
        {
            state.page.showViewSelector = false;
        },
        setViewSelectorState: ( state, action: PayloadAction<IInternalState['page']['viewSelectorState']> ) =>
        {
            state.page.viewSelectorState = action.payload;
        },
        setAppViewState: ( state, action: PayloadAction<IInternalState['app']['view']> ) =>
        {
            state.app.view = action.payload;
        },
        clearInternalState: () =>
        {
            return {
                ...initialState
            };
        }
    },
    /**
     * Use extraReducers when you are dealing with an action that you have already defined somewhere else.
     * The most common examples are responding to a createAsyncThunk action and responding to an action from another slice.
     */
    /*extraReducers: ( builder ) =>
    {}*/
} );

// export const internalStoreSlice = _slice.reducer;
export const internalStoreSlice = configureReduxPersist<IInternalState>( internalSliceKey, _slice.reducer, {
    whitelist: [ 'version' ]
} );

export const actions = _slice.actions;
