import Router from 'next/router';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useCurrentCompany } from '@/store/company/hooks';
import { useCurrentUser } from '@/store/user/hooks';

import type { ORDatastoreOwner, ORDatastoreVariant } from '@/features/datastore/models';


export const useDatastoreInfo = () =>
{
    const { orCompany } = useCurrentCompany();
    const { settings } = useCurrentUser( {} );

    const [ path, setPath ] = useState( () => (
          Router.query.path as string
    ) );

    const owner = Router.query.owner as ORDatastoreOwner || 'company';
    const ownerId = owner === 'company' ? orCompany?.uuid : settings?.currentAccountUuid;
    const variant = Router.query.variant as ORDatastoreVariant || 'store';

    const changePath = useCallback( ( newPath: string ) =>
    {
        if ( !newPath || newPath === path ) return;

        const params = new URLSearchParams( window.location.search );
        params.set( 'path', newPath );
        window.history.pushState( null, '', `${ window.location.pathname }?${ params.toString() }` );

        setPath( () => newPath );
    }, [ path ] );

    useEffect( () =>
    {
        const handlePopState = () =>
        {
            setPath( () => Router.query.path as string );
        };

        window.addEventListener( 'popstate', handlePopState );

        return () =>
        {
            window.removeEventListener( 'popstate', handlePopState );
        };
    }, [] );

    return useMemo( () => ( {
        owner, ownerId, variant, path, changePath
    } ), [ owner, ownerId, variant, path, changePath ] );
};