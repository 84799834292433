import { useMemo } from 'react';

import { useAppSelector } from '@/store/hooks';
import { selectMostRecentRequest } from '@/store/internal/selectors';


export const useMostRecentRequest = () =>
{
    const mostRecentRequest = useAppSelector( selectMostRecentRequest );

    return useMemo( () => ( {
        mostRecentRequest
    } ), [ mostRecentRequest ] );
};