export const addNumberThousandsSeparators = ( { num, keepDecimals = true, minDecimals = 2, maxDecimals = 20 }: {
    num: unknown,
    keepDecimals?: boolean,
    minDecimals?: number,
    maxDecimals?: number
} ): string =>
{
    const data = Number( num );

    if ( isNaN( data ) )
    {
        return '';
    }

    const minimumFractionDigits = keepDecimals ? minDecimals : 0;
    const maximumFractionDigits = keepDecimals ? maxDecimals : 0;

    const formattedNumber = new Intl.NumberFormat( undefined, {
        minimumFractionDigits,
        maximumFractionDigits,
    } ).format( data );

    // console.log( formattedNumber, keepDecimals, minimumFractionDigits, maximumFractionDigits );

    return formattedNumber;
};
/*export const addNumberThousandsSeparators = ( { num, keepDecimals = true, maxDecimals = 2, absoluteMaxDecimals = 10 }: {
    num: unknown,
    keepDecimals?: boolean,
    maxDecimals?: number,
    absoluteMaxDecimals?: number
} ): string =>
{
    const data = Number( num );

    if ( isNaN( data ) )
    {
        return;
    }

    const numParts = data.toString().split( '.' );

    const wholePart = numParts[ 0 ].replace( /\B(?=(\d{3})+(?!\d))/g, ',' );

    const defaultDecimals = Array.from( { length: maxDecimals } ).fill( '0' ).join( '' );
    let decimals = numParts[ 1 ] || defaultDecimals;

    if ( decimals.length < maxDecimals )
    {
        decimals += Array.from( Array( maxDecimals - decimals.length ).keys() ).map( () => '0' ).join( '' );
    }

    const checkIfNumberContainsOtherThanZero = ( value: string ) =>
    {
        return !Array.from( value ).every( digit => digit === '0' );
    };
    const containsOtherThanZero = checkIfNumberContainsOtherThanZero( decimals );

    decimals = decimals.substring( 0, containsOtherThanZero ? absoluteMaxDecimals : maxDecimals );

    if ( keepDecimals )
    {
        // based on https://stackoverflow.com/a/56678678
        while (
            decimals.length !== 0 &&
            decimals[ decimals.length - 1 ] === '0' &&
            decimals.length > maxDecimals
            )
        {
            decimals = decimals.slice( 0, -1 );
        }

        if ( decimals.length === 0 )
        {
            return wholePart;
        }

        return `${ wholePart }.${ decimals }`;
    }

    return wholePart;
};*/

export const removeNumberThousandsSeparators = ( value: string ): number =>
{
    return Number( value.replace( /(,*)/g, '' ) );
};