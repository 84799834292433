import { type FC, memo, useCallback, useState } from 'react';

import { usePatchCompanyMutation } from '@/store/company';

import { FormWithSteps, type IFormWithStepsStep, useStepsForm } from '@/components/structural/form';
import { Modal } from '@/components/structural';

import { type IStep1Data, Step1 } from './Step1';
import { type IStep2Data, Step2 } from './Step2';
import { Step3 } from './Step3';
import { StepEnd } from './StepEnd';


export const CompanyChangeRegisteredAddressForm: FC = memo( () =>
{
    const [ , { isLoading } ] = usePatchCompanyMutation();

    const { currentStep, currentStepStatus, goToStep, isStepValid, next, prev, isValid, setIsValid } = useStepsForm();

    const [ step1Data, setStep1Data ] = useState<IStep1Data>( null );
    const [ step2Data, setStep2Data ] = useState<IStep2Data>( null );

    const doSave = useCallback( () =>
    {
        goToStep( 3 );
    }, [] );

    const doSaveDraft = useCallback( () =>
    {
        Modal.destroyAll();
    }, [] );

    const steps: IFormWithStepsStep[] = [
        {
            content: <Step1 isStepValid={ isStepValid } setIsValid={ setIsValid } setData={ setStep1Data } initialData={ step1Data }/>,
        },
        {
            content: <Step2 isStepValid={ isStepValid } setIsValid={ setIsValid } setData={ setStep2Data } initialData={ step2Data }/>,
        },
        {
            content: <Step3 goToStep={ goToStep } initialData={ {
                step1: step1Data,
                step2: step2Data,
            } }/>,
        },
        {
            content: <StepEnd/>
        }
    ];

    return <FormWithSteps
          title="Change Registered Office Address"
          currentStep={ currentStep }
          currentStepStatus={ currentStepStatus }
          steps={ steps }
          next={ next }
          prev={ prev }
          finish={ doSave }
          saveDraft={ doSaveDraft }
          currentStepIsValid={ isValid }
          isLoading={ isLoading }
    />;
} );

CompanyChangeRegisteredAddressForm.displayName = 'CompanyChangeRegisteredAddressForm';