export * from './breakWordByCapitals';
export * from './calculateAverageOf';
export * from './capitaliseFirstLetter';
export * from './compare';
export * from './cookies';
export * from './delayAsync';
export * from './downloadFromAWS';
export * from './downloadFromBackend';
export * from './extractUuidFromUrl';
export * from './formatDateTime';
export * from './formatPercentage';
export * from './formatStringTitleCase';
export * from './formatWordsWithSeparator';
export * from './getCountryNameByCode';
export * from './getFileExtension';
export * from './getFileName';
export * from './getFileTypeIcon';
export * from './getNationalityNameByCode';
export * from './getRandomNumber';
export * from './getTrueOrFalseFromString';
export * from './getWordsFirstLettersAsCapitals';
export * from './handleHTTPResponseError';
export * from './isDate';
export * from './jsonTools';
export * from './kebabToTitleCase';
export * from './normaliseString';
export * from './normaliseDate';
export * from './normaliseDateTime';
export * from './numberThousandsSeparator';
export * from './objectDiff';
export * from './removeAllQueryParameters';
export * from './removeWhiteSpace';
export * from './replaceStringAt';
export * from './stylesheets';
export * from './splitStringMultiSeparators';
export * from './stringContainsUUID';
export * from './stringIsAllUpperCase';
export * from './stringSimilarity';
export * from './stringToColour';
export * from './uploadToAWS';
