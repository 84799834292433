import { type FC, memo, useCallback, useMemo } from 'react';
import moment from 'moment';
import tw from 'tailwind-styled-components';

import { Card, DataFormat, Loader, Space, Typography } from '@/components/structural';

import { useCurrentUser } from '@/store/user/hooks';
import { useCurrentCompany } from '@/store/company/hooks';
import { formatWordsWithSeparator } from '@/shared/tools';


interface IFilingDeadlineWidgetProps
{
    type: 'accounts' | 'confirmation_statement'
    className?: string,
}

export const FilingDeadlineWidget: FC<IFilingDeadlineWidgetProps> = memo( ( { type, className } ) =>
{
    const { settings } = useCurrentUser( {} );
    const { chProfile, isFetching } = useCurrentCompany();

    const title = useMemo( () => formatWordsWithSeparator( type, '_' ), [ type ] );
    const dueDate = useMemo( () => chProfile && chProfile[ type ] && chProfile[ type ].next_due || '', [ chProfile, type ] );
    const madeUpToDate = useMemo( () => chProfile && chProfile[ type ] && chProfile[ type ].next_made_up_to || '', [ chProfile, type ] );

    const getState = useCallback( () =>
    {
        const nextDue = moment( dueDate );

        const now = moment();

        // if before next made up to date
        if ( now < nextDue )
        {
            return 'success';
        }

        // if after 1 month before next due
        if ( now > nextDue.clone().subtract( 1, 'month' ) )
        {
            return 'danger';
        }

        // if between next made up to and 1 month before next due
        return 'warning';
    }, [ dueDate ] );

    if ( isFetching ) return <Loader skeleton/>;

    return ( <>
        <CardWrapper
              title={ title }
              className={ className }
              headStyle={ { color: settings?.dark_mode ? null : '#fff' } }
              $state={ getState() }
        >
            <InnerWrapper direction="vertical">
                <InnerWrapper size={ 0 }>
                    <Typography.Text className="text-white dark:text-default-dark">Next due by</Typography.Text>
                    <Typography.Title level={ 4 } className="!mt-1">
                        <DataFormat raw={ dueDate } type="date" className="!text-white dark:!text-default-dark"/>
                    </Typography.Title>
                </InnerWrapper>
                <InnerWrapper size={ 0 }>
                    <Typography.Text className="text-white dark:text-default-dark">Made up to</Typography.Text>
                    <Typography.Title level={ 4 } className="!mt-1">
                        <DataFormat raw={ madeUpToDate } type="date" className="!text-white dark:!text-default-dark"/>
                    </Typography.Title>
                </InnerWrapper>
            </InnerWrapper>
        </CardWrapper>
    </> );
} );

FilingDeadlineWidget.displayName = 'FilingDeadlineWidget';

const CardWrapper = tw( Card )<{ $state: 'success' | 'warning' | 'danger' }>`
    ${ p => p.$state === 'success' ? 'bg-success dark:bg-success-dark' : '' }
    ${ p => p.$state === 'warning' ? 'bg-warning dark:bg-warning-dark' : '' }
    ${ p => p.$state === 'danger' ? 'bg-danger dark:bg-danger-dark' : '' }
`;

const InnerWrapper = tw( Space )`
    grid
    place-items-center
`;
