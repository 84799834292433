import { useMemo } from 'react';

import { useCurrentCompany } from '@/store/company/hooks/useCurrentCompany.hook';

import { useGetShareholdingsSummaryQuery } from '@/store/company';

import { compare } from '@/shared/tools';


export const useShareholdingsSummary = ( skip = false ) =>
{
    const { orCompany } = useCurrentCompany();
    const { data, isFetching, isLoading, error } = useGetShareholdingsSummaryQuery( undefined, {
        skip: skip || !orCompany
    } );

    const shareholdingsSummary = [ ...( data || [] ) ];
    shareholdingsSummary.sort( ( a, b ) => compare( a.created_at, b.created_at, false ) );

    return useMemo( () => ( {
        shareholdingsSummary,
        isLoading,
        isFetching,
        error
    } ), [ data, isFetching, isLoading, error ] );
};