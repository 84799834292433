import { setInputBoxCodeDigit } from '@/features/auth/tools';

import type { InputRef } from '@/components/structural/form';
import type { ORJSON } from '@/shared/models';


export const handleInputBoxCodePaste = ( code: string, codeState: ORJSON<string>, refs: InputRef[] ) =>
{
    const digits = code.split( '' ).slice( 0, 6 );

    digits.forEach( ( digit: string, index: number ) =>
    {
        setInputBoxCodeDigit( digit, index, codeState, refs );
    } );
};