import { type FC, memo, useEffect } from 'react';

import { clearNotifications } from '@/store/notifications/actions';
import { useAppDispatch } from '@/store/hooks';
import { useNotifications } from '@/store/notifications/hooks';

import { Notification, routerNavigate } from '@/components/structural';


Notification.config( {
    placement: 'topRight',
    duration: 4.5,
    top: 44
} );

export const Notifications: FC = memo( () =>
{
    const dispatchAction = useAppDispatch();
    const { notifications } = useNotifications();

    useEffect( () =>
    {
        if ( !notifications || !notifications.length ) return;

        notifications.forEach( msg =>
        {
            Notification.open( {
                message: msg.title,
                description: msg.content,
                duration: 4.5,
                // @ts-expect-error
                type: msg.type,
                onClose: msg.onClose,
                onClick: () => msg.onClickNavigateTo && routerNavigate( msg.onClickNavigateTo )
            } );
        } );

        dispatchAction( clearNotifications() );
    }, [ notifications, dispatchAction ] );

    return null;
} );

Notifications.displayName = 'Notifications';