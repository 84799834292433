import { type FC, memo } from 'react';
import useReactive from 'ahooks/lib/useReactive';

import { Form, HiddenFormItemFullWidth, Input, InputNumber, Switch, VisibleFormItem, VisibleFormItemFullWidth } from '@/components/structural/form';
import { Typography } from '@/components/structural';

import type { ICompanyOnboardData } from './company-onboard-data.interface';


export interface IStep3Props
{
    className?: string,
    data?: {
        company?: ICompanyOnboardData['company']
        settings?: ICompanyOnboardData['settings']
    }
    onChange: ( key: keyof ICompanyOnboardData['settings'] ) => ( value: ICompanyOnboardData['settings'][typeof key] ) => void
}

export const Step3: FC<IStep3Props> = memo( ( props ) =>
{
    const switches = useReactive( {
        eis_enable: ( props.data?.settings?.eis_allocation || 0 ) > 0,
        seis_enable: ( props.data?.settings?.seis_allocation || 0 ) > 0,
    } );

    return ( <>
        <div className={ `${ props.className }` }>
            { props.data?.company && <Typography.Title level={ 5 }>
                About { 'company_name' in props.data.company ? props.data.company.company_name : props.data.company.title }
            </Typography.Title> }
            <Typography.Paragraph className="font-roboto">
                Answer a few questions about your company so that we can set things up for you. You can also edit these details in Company Settings.
            </Typography.Paragraph>
            <Form colon={ false } initialValues={ {
                eis_allocation: props.data?.settings?.eis_allocation || 0,
                eis_allocation_yearly: props.data?.settings?.eis_allocation_yearly || 0,
                seis_allocation: props.data?.settings?.seis_allocation || 0,
            } }>
                <VisibleFormItem
                      label="Enterprise Investment Scheme (EIS)"
                      tooltip="EIS is a UK government scheme which offers a range of tax relief to investors. Where a business is listed
                          as ‘EIS’, this tax relief will be available to qualifying investors. The Lifetime limit is set to £12m at present, but up to £5m can be raised in a single tax year."
                >
                    <Switch
                          checked={ switches.eis_enable }
                          onChange={ value => switches.eis_enable = value }
                    />
                </VisibleFormItem>

                <HiddenFormItemFullWidth
                      label={ <span className="text-info dark:text-info-dark">EIS Allowance (£)</span> }
                      rules={ [ { message: 'Please use a number greater than 1!', type: 'number', min: 0 } ] }
                      className={ switches.eis_enable ? 'grid' : 'hidden' }
                      name="eis_allocation"
                >
                    <InputNumber
                          onChange={ props.onChange( 'eis_allocation' ) }
                          disabled
                          wholeNumbers
                    />
                </HiddenFormItemFullWidth>

                <HiddenFormItemFullWidth
                      label={ <span className="text-info dark:text-info-dark">EIS Allowance (£)</span> }
                      rules={ [ { message: 'Please use a number greater than 1!', type: 'number', min: 0 } ] }
                      className={ switches.eis_enable ? 'grid' : 'hidden' }
                      name="eis_allocation_yearly"
                >
                    <InputNumber
                          onChange={ props.onChange( 'eis_allocation_yearly' ) }
                          disabled
                          wholeNumbers
                    />
                </HiddenFormItemFullWidth>

                <VisibleFormItem
                      label="Seed Enterprise Investment Scheme (SEIS)"
                      tooltip="The Seed Enterprise Investment Scheme (SEIS) was introduced in April 2012 by HMRC to help small, early-stage
                          companies raise funds through individual investors by providing a series of tax reliefs on investments made
                          into qualifying companies."
                >
                    <Switch
                          checked={ switches.seis_enable }
                          onChange={ value => switches.seis_enable = value }
                    />
                </VisibleFormItem>

                <HiddenFormItemFullWidth
                      label={ <span className="text-info dark:text-info-dark">SEIS Allowance (£)</span> }
                      rules={ [ { message: 'Please use a number greater than 1!', type: 'number', min: 0 } ] }
                      className={ switches.seis_enable ? 'grid' : 'hidden' }
                      name="seis_allocation"
                >
                    <InputNumber
                          onChange={ props.onChange( 'seis_allocation' ) }
                          disabled
                          wholeNumbers
                    />
                </HiddenFormItemFullWidth>

                <VisibleFormItemFullWidth
                      label={ <span>Web Filing Code <span className="ml-2 text-default-alt dark:text-default-dark font-roboto">OPTIONAL</span></span> }
                      className=""
                      name="web_filing_key"
                >
                    <>
                        <Typography.Paragraph className="font-roboto">
                            This is an alphanumeric code provided by Companies House. You’ll need to save it
                            in OneRegistry in order to be able to make statutory filings and changes at
                            Companies House through the platform. You can add in settings later if needed.
                        </Typography.Paragraph>
                        <Input
                              placeholder=""
                              defaultValue={ props.data?.settings?.web_filing_key }
                              onChange={ ( { target: { value } } ) => props.onChange( 'web_filing_key' )( value ) }
                        />
                    </>
                </VisibleFormItemFullWidth>
            </Form>
        </div>
    </> );
} );

Step3.displayName = 'CompanyOnboardStep3';