import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { compare, getCookie } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompanyShareDilution, IORCompanyShareDilutionMainAttributes } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const shareDilutionAdapter = createEntityAdapter<IORCompanyShareDilution>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at ),
    selectId: ( e ) => e.uuid,
} );

export const mapShareDilution = ( e: IORCompanyShareDilution ) => ( {
    ...e,
} );

export const shareDilutionApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneShareDilution: builder.query<IORCompanyShareDilution, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/dilution/${ uuid }`, method: 'get'
            } ),
            transformResponse: mapShareDilution,
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'ShareDilution' )
            ),
        } ),
        getManyShareDilutions: builder.query<EntityState<IORCompanyShareDilution> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/dilution/`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'ShareDilutions', 'ShareDilution' )
            ),
            transformResponse: ( data: IORCompanyShareDilution[], meta: IORResponseMetaData ) => ( {
                ...shareDilutionAdapter.upsertMany( shareDilutionAdapter.getInitialState(), data.map( mapShareDilution ) ),
                ...{ meta }
            } )
        } ),
        patchShareDilution: builder.mutation<IORCompanyShareDilution, { uuid: string, data: Partial<IORCompanyShareDilutionMainAttributes> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/dilution/${ uuid }`, method: 'patch', data
            } ),
            invalidatesTags: ( _, __, { uuid } ) => ( [
                ...providesIds( [ uuid ], 'ShareDilution' ),
                ...providesList( [], 'Shareholdings' ),
                ...providesList( [], 'ShareholdingsSummary' ),
                ...providesIds( [ getCookie( { cName: 'orCurrentCompanyUuid' } ) ], 'Company' )
            ] )
        } ),
        postShareDilution: builder.mutation<IORCompanyShareDilution, Partial<IORCompanyShareDilutionMainAttributes>>( {
            query: ( data ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/dilution/`, method: 'post', data
            } ),
            invalidatesTags: () => ( [
                ...providesList( [], 'ShareDilutions' ),
                ...providesList( [], 'Shareholdings' ),
                ...providesList( [], 'ShareholdingsSummary' ),
                ...providesIds( [ getCookie( { cName: 'orCurrentCompanyUuid' } ) ], 'Company' )
            ] )
        } ),
        deleteShareDilution: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/dilution/${ uuid }`, method: 'delete'
            } ),
            invalidatesTags: ( _, __, uuid ) => ( [
                ...providesList( [ uuid ], 'ShareDilutions', 'ShareDilution' ),
                ...providesList( [], 'Shareholdings' ),
                ...providesList( [], 'ShareholdingsSummary' ),
                ...providesIds( [ getCookie( { cName: 'orCurrentCompanyUuid' } ) ], 'Company' )
            ] )
        } ),
    } ),
} );

export const {
    useGetOneShareDilutionQuery,
    useLazyGetOneShareDilutionQuery,
    useGetManyShareDilutionsQuery,
    useLazyGetManyShareDilutionsQuery,
    usePatchShareDilutionMutation,
    usePostShareDilutionMutation,
    useDeleteShareDilutionMutation,
    endpoints: shareDilutionApiEndpoints,
} = shareDilutionApi;