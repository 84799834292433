import { type FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import { useRouter } from 'next/router';

import { useGetOneShareRoundQuery } from '@/store/company';
import { useCurrentCompany, useShareholders } from '@/store/company/hooks';
import { usePortfolio } from '@/store/portfolio/hooks';

import {
    Breadcrumb,
    type BreadcrumbProps,
    Link,
    Tooltip,
    Typography,
} from '@/components/structural';
import { SVGIcon } from '@/components/structural/images';

import { formatStringTitleCase, splitStringMultiSeparators } from '@/shared/tools';
import { BREADCRUMBS_CUSTOM_HELP_TEXT, BREADCRUMBS_CUSTOM_TITLE, BREADCRUMBS_ICONS_MAP, BREADCRUMBS_PAGES_TO_HIDE_ON } from '@/shared/constants';

import helpIcon from '@/icons/question-circle.svg';


// const defaultLandingPage = require( '../../../shared/defaultLandingPage.js' );

export interface IORBreadcrumb
{
    title: string,
    href: string,
}

export const Breadcrumbs: FC<BreadcrumbProps> = memo( ( props ) =>
{
    const { orCompany } = useCurrentCompany();
    const { pathname, query } = useRouter();
    const [ breadcrumbs, setBreadcrumbs ] = useState<IORBreadcrumb[]>( [] );

    useEffect( () =>
    {
        const linkPath = pathname.split( '/' );
        linkPath.shift();

        const pathArray: IORBreadcrumb[] = linkPath.map( ( path, i ) =>
        {
            return { title: path, href: '/' + linkPath.slice( 0, i + 1 ).join( '/' ) };
        } );

        const lastPathEntry = pathArray[ pathArray.length - 1 ];
        const lastPathEntryTitleParts = splitStringMultiSeparators( lastPathEntry.title, [ '[', ']' ] );

        if ( lastPathEntryTitleParts.length === 3 )
        {
            lastPathEntry.title = query[ lastPathEntryTitleParts[ 1 ] ] as string;
        }

        setBreadcrumbs( pathArray );
    }, [ pathname, query ] );

    const lastBreadcrumb = breadcrumbs.length && breadcrumbs[ breadcrumbs.length - 1 ] || null;
    const shareholderID = pathname.includes( '/shareholders/' ) && lastBreadcrumb && lastBreadcrumb.title || '';
    const { shareholders } = useShareholders( undefined, !shareholderID );
    const shareholder = useMemo( () => shareholders.find( s => s.uuid === shareholderID ), [ shareholders, shareholderID ] );
    const investmentCompanyNumber = pathname.includes( '/investments/' ) && lastBreadcrumb && lastBreadcrumb.title || '';
    const { portfolio: { company_data } = { company_data: [] } } = usePortfolio();
    const investmentCompany = useMemo( () => (
          Object.values( company_data || [] )
                .filter( c => c )
                .find( c => c.company_number === investmentCompanyNumber )
    ), [ company_data, investmentCompanyNumber ] );
    const shareRoundsData = useGetOneShareRoundQuery( pathname.includes( '/share-rounds/' ) && lastBreadcrumb && lastBreadcrumb.title || skipToken );

    const getProperTitle = useCallback( ( path: string ) =>
    {
        if ( path.includes( '/shareholders/' ) )
        {
            return () => shareholder?.entity_name;
        }

        if ( path.includes( '/investments/' ) )
        {
            return () => formatStringTitleCase( investmentCompany?.company_name );
        }

        if ( path.includes( '/share-rounds/' ) )
        {
            return () => shareRoundsData?.data?.name;
        }

        if ( BREADCRUMBS_CUSTOM_TITLE[ path ] )
        {
            return () => BREADCRUMBS_CUSTOM_TITLE[ path ];
        }

        return normaliseBreadcrumbTitle;
    }, [ shareholder, shareRoundsData?.data, investmentCompany ] );

    if ( !breadcrumbs.length || BREADCRUMBS_PAGES_TO_HIDE_ON.includes( pathname ) )
    {
        return null;
    }

    return ( <>
        <Wrapper>
            <ORBreadcrumb
                  { ...props }
                  separator={ <BreadcrumbSeparator><span>&gt;</span></BreadcrumbSeparator> }
            >
                <Breadcrumb.Item key="/home">
                    <BreadcrumbLinkInactive to={ pathname.includes( '/portfolio' ) ? '/portfolio' : '/my-company' }>
                        <BreadcrumbIcon { ...BREADCRUMBS_ICONS_MAP[ 'home' ] } className="!mr-0"/>
                    </BreadcrumbLinkInactive>
                </Breadcrumb.Item>
                {/*{ orCompany?.name && !pathname.includes( '/portfolio' ) && <Breadcrumb.Item key="/current-company">
                    <BreadcrumbLinkInactive to="/my-company">
                        { orCompany.name }
                    </BreadcrumbLinkInactive>
                </Breadcrumb.Item> }*/ }
                { breadcrumbs.length === 1 && <Breadcrumb.Item>
                    <BreadcrumbLinkActive>
                        { BREADCRUMBS_ICONS_MAP[ breadcrumbs[ 0 ].title ] &&
                              <BreadcrumbIcon { ...BREADCRUMBS_ICONS_MAP[ breadcrumbs[ 0 ].title ] }/> }
                        { getProperTitle( breadcrumbs[ 0 ].href )( breadcrumbs[ 0 ].title ) }
                    </BreadcrumbLinkActive>
                </Breadcrumb.Item> }
                { breadcrumbs.length > 1 && breadcrumbs.map( ( bc, i ) =>
                {
                    if ( orCompany?.name && bc.href === '/my-company' ) return;
                    return (
                          <Breadcrumb.Item key={ bc.href + bc.title }>
                              { i === breadcrumbs.length - 1 ?
                                    ( <BreadcrumbLinkActive>
                                        { getProperTitle( bc.href )( bc.title ) }
                                        { BREADCRUMBS_CUSTOM_HELP_TEXT[ bc.href ] &&
                                              <Tooltip title={ BREADCRUMBS_CUSTOM_HELP_TEXT[ bc.href ] } placement="bottom">
                                                  <span><SVGIcon src={ helpIcon.src } className="!w-5 !h-5 -mb-1 ml-1"/></span>
                                              </Tooltip>
                                        }
                                    </BreadcrumbLinkActive> )
                                    :
                                    ( <BreadcrumbLinkInactive to={ bc.href }>
                                        { BREADCRUMBS_ICONS_MAP[ bc.title ] &&
                                              <BreadcrumbIcon { ...BREADCRUMBS_ICONS_MAP[ bc.title ] }/> }
                                        { normaliseBreadcrumbTitle( bc.title ) }
                                    </BreadcrumbLinkInactive> )
                              }
                          </Breadcrumb.Item>
                    );
                } ) }
            </ORBreadcrumb>
        </Wrapper>
    </> );
} );

Breadcrumbs.displayName = 'Breadcrumbs';

const Wrapper = tw.div`
    text-lg
`;

const ORBreadcrumb = styled( Breadcrumb )`
    & li {
        display: flex;
        align-items: center;
        align-content: center;
        align-self: center;
        text-align: center;
    }
`;

const BreadcrumbLinkInactive = tw( Link )`
    !text-default
        dark:!text-default-dark
    hover:!text-success
        dark:hover:!text-success-dark
`;

const BreadcrumbLinkActive = tw( Typography.Text )`
    inline-block
    text-default
    dark:text-default-dark
`;

const BreadcrumbSeparator = tw( Typography.Text )`
    text-success
        dark:text-success-dark
`;

const BreadcrumbIcon = tw( SVGIcon )`
    !h-6
    !w-6
    text-success
        dark:text-success-dark
    !inline-grid
    mr-2
    -mb-1
`;

const normaliseBreadcrumbTitle = ( string: string ) =>
      {
          return formatStringTitleCase( string
                .replace( /-/g, ' ' )
                .replace( /oe/g, 'ö' )
                .replace( /ae/g, 'ä' )
                .replace( /ue/g, 'ü' ) );
      }
;