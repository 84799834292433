import { type FC, memo, type PropsWithChildren } from 'react';

import Typography from 'antd/lib/typography';


export interface ITextWithCutoffProps
{
    className?: string,
    disableEllipsis?: boolean
    maxCharsCutOff?: number
}

export const TextWithCutoff: FC<PropsWithChildren<ITextWithCutoffProps>> = memo( ( props ) =>
{
    return ( <>
        <Typography.Text
              className={ `text-inherit flex-grow inline-block ${ props.className || '' }` }
              style={ props.maxCharsCutOff ? { maxWidth: `${ props.maxCharsCutOff }ch` } : undefined }
              /* eslint-disable-next-line @typescript-eslint/no-empty-function */
              ellipsis={ props.disableEllipsis || { tooltip: props.children, onEllipsis: () => {} } }
        >
            { props.children }
        </Typography.Text>
    </> );
} );

TextWithCutoff.displayName = 'TextWithCutoff';