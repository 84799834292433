import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
// import { AdminConfirmSignUpCommand, CognitoIdentityProviderClient } from '@aws-sdk/client-cognito-identity-provider';
import type { ConfirmSignUpOptions } from '@aws-amplify/auth/src/types/Auth';
import type { ISignUpResult } from 'amazon-cognito-identity-js';

// import { lastValueFrom, Observable } from 'rxjs';
import { storage } from './aws.storage';
import { processError } from '@/shared/processError';

import type { IAWSUser } from '@/features/auth/models';
import type { ORJSON } from '@/shared/models';


const srv = ( storageObj: typeof storage ) =>
{
    ///*** CONFIG ***///

    // let cognitoAdminService: CognitoIdentityProviderClient;
    try
    {
        Amplify.configure( {
            Auth: {
                region: process.env.NEXT_PUBLIC_AWS_AMPLIFY_REGION,
                userPoolId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_ID,
                userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
                authenticationFlowType: process.env.NEXT_PUBLIC_AWS_AMPLIFY_AUTHENTICATION_FLOW_TYPE,
                mandatorySignIn: !!process.env.NEXT_PUBLIC_AWS_AMPLIFY_MANDATORY_SIGN_IN,
                storage: storageObj
            }
        } );

        /*cognitoAdminService = new CognitoIdentityProviderClient( {
            credentials: {
                accessKeyId: process.env.NEXT_PUBLIC_AWS_CLIENT_ID,
                secretAccessKey: process.env.NEXT_PUBLIC_AWS_CLIENT_SECRET
            },
            region: process.env.NEXT_PUBLIC_AWS_AMPLIFY_REGION
        } );*/
    } catch ( error )
    {
        processError( 'auth Service', error );
    }

    ///*** PRIVATE FUNCTIONS ***///

    ///*** PUBLIC FUNCTIONS ***///

    const signIn = ( { email, password }: { email: string, password: string } ): Promise<IAWSUser> =>
    {
        return Auth.signIn( email, password, {
            referrer: process.env.NEXT_PUBLIC_OR_TYPE || ''
        } );
    };

    const signInNoPassword = ( { email }: { email: string } ): Promise<IAWSUser> =>
    {
        return Auth.signIn( email, undefined, {
            referrer: process.env.NEXT_PUBLIC_OR_TYPE || ''
        } );
    };

    const signOut = () =>
    {
        return Auth.signOut();
    };

    const refreshToken = (): Promise<IAWSUser> =>
    {
        return Auth.currentAuthenticatedUser();
    };

    const signUp = ( { email, password, extraAttr = {} }: { email: string, password: string, extraAttr?: ORJSON } ): Promise<ISignUpResult> =>
    {
        return Auth.signUp( {
            username: email, password, attributes: { email, ...extraAttr }, clientMetadata: {
                referrer: process.env.NEXT_PUBLIC_OR_TYPE || ''
            }
        } );
    };

    const signUpNoConfirm = async ( { email, password }: { email: string, password: string } ): Promise<ISignUpResult> =>
    {
        return signUp( { email, password, extraAttr: { 'custom:invited': 'yes' } } ).then( ( user ) =>
        {
            return user;
            /*return lastValueFrom( new Observable<ISignUpResult>( obs =>
                {
                    const adminConfirmSignupCommand = new AdminConfirmSignUpCommand( {
                        Username: email,
                        UserPoolId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_ID || '',
                        ClientMetadata: {
                            referrer: process.env.NEXT_PUBLIC_OR_TYPE || ''
                        }
                    } );
                    cognitoAdminService.send( adminConfirmSignupCommand ).catch( ( error ) =>
                    {
                        if ( error )
                        {
                            obs.error( error );
                        } else
                        {
                            obs.next( user );
                        }
                        obs.complete();
                    } );
                } )
            );*/
        } );
    };

    const resendConfirmationEmail = ( { email }: { email: string } ) =>
    {
        return Auth.resendSignUp( email, {
            referrer: process.env.NEXT_PUBLIC_OR_TYPE || ''
        } );
    };

    const confirmSignup = ( { email, code, options = {} }: { email: string, code: string, options?: ConfirmSignUpOptions } ) =>
    {
        return Auth.confirmSignUp( email, code, Object.assign( {}, options, {
            clientMetadata: {
                referrer: process.env.NEXT_PUBLIC_OR_TYPE || ''
            }
        } ) );
    };

    const userExist = async ( { email }: { email: string } ) =>
    {
        try
        {
            const user = await Auth.signIn( email.toLowerCase().trim() );
            if ( user )
            {
                await Auth.signOut();
                return true;
            }
            return false;
        } catch ( error )
        {
            switch ( error.code )
            {
                case 'PasswordResetRequiredException':
                case 'UserNotConfirmedException':
                    return true;
                case 'NotAuthorizedException':
                case 'UserNotFoundException':
                default:
                    return false;
            }
        }
    };

    const forgotPassword = ( { email }: { email: string } ) =>
    {
        return Auth.forgotPassword( email, {
            referrer: process.env.NEXT_PUBLIC_OR_TYPE || ''
        } );
    };

    const forgotPasswordSubmitNewPassword = ( { email, code, password }: { email: string, code: string, password: string } ) =>
    {
        return Auth.forgotPasswordSubmit( email, code, password, {
            referrer: process.env.NEXT_PUBLIC_OR_TYPE || ''
        } );
    };

    // awsUser is a class instance, non-serializable, so use this function to get a version with
    // the minimum attributes needed by the auth reducer
    const parseIAWSUser = ( user: IAWSUser ) => ( {
        username: user.username,
        attributes: user.attributes,
        preferredMFA: user.preferredMFA,
        signInUserSession: {
            idToken: {
                jwtToken: user.signInUserSession.idToken.jwtToken
            },
            accessToken: {
                jwtToken: user.signInUserSession.accessToken.jwtToken
            }
        }
    } );

    ///*** PUBLIC API ***///

    return {
        signUp, signUpNoConfirm, userExist,
        resendConfirmationEmail, confirmSignup,
        forgotPassword, forgotPasswordSubmitNewPassword,
        signIn, signInNoPassword,
        refreshToken,
        signOut,
        parseIAWSUser
    };
};

export const authService = srv( storage );
// export const authServiceWithStorage = srv;