import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { compare, extractUuidFromUrl, getCookie } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type {
    IORCompanyShareClass,
    IORCompanyShareClassMainAttributes,
    IORCompanyShareClassNominalValueMainAttributes,
    IORCompanyShareClassRedesignation,
    IORCompanyShareClassRedesignationNoRemainingSharesMainAttributes,
    IORCompanyShareClassRedesignationWithRemainingSharesMainAttributes,
    IORCompanyShareClassShareHistoryEntry
} from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const shareClassAdapter = createEntityAdapter<IORCompanyShareClass>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at ),
    selectId: ( e ) => e.uuid,
} );

export const shareClassShareHistoryAdapter = createEntityAdapter<IORCompanyShareClassShareHistoryEntry>( {
    sortComparer: ( a, b ) => compare( a.action_date, b.action_date ),
    selectId: ( e ) => e.uuid,
} );

export const shareClassRedesignationAdapter = createEntityAdapter<IORCompanyShareClassRedesignation>( {
    sortComparer: ( a, b ) => compare( a.action_date, b.action_date ),
    selectId: ( e ) => e.uuid,
} );

export const mapShareClass = ( e: IORCompanyShareClass ) => ( {
    ...e,
    company: extractUuidFromUrl( e.company ),
    share_rounds: e.share_rounds.map( sr => extractUuidFromUrl( sr ) ),
    past_versions: null,
    share_change: null,
} );

export const shareClassApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneShareClass: builder.query<IORCompanyShareClass, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareclass/${ uuid }`, method: 'get'
            } ),
            transformResponse: mapShareClass,
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'ShareClass' )
            ),
        } ),
        getManyShareClasses: builder.query<EntityState<IORCompanyShareClass> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareclass/`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'ShareClasses', 'ShareClass' )
            ),
            transformResponse: ( data: IORCompanyShareClass[], meta: IORResponseMetaData ) => ( {
                ...shareClassAdapter.upsertMany( shareClassAdapter.getInitialState(), data.map( mapShareClass ) ),
                ...{ meta }
            } )
        } ),
        postShareClass: builder.mutation<IORCompanyShareClass, Partial<IORCompanyShareClassMainAttributes>>( {
            query: ( data ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareclass/`, method: 'post', data
            } ),
            invalidatesTags: () => (
                  providesList( [], 'ShareClasses' )
            )
        } ),
        patchShareClass: builder.mutation<IORCompanyShareClass, { uuid: string, data: Partial<IORCompanyShareClassMainAttributes> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareclass/${ uuid }`, method: 'patch', data
            } ),
            invalidatesTags: ( _, __, { uuid } ) => (
                  providesIds( [ uuid ], 'ShareClass' )
            )
        } ),
        getOneShareClassShareHistory: builder.query<IORCompanyShareClassShareHistoryEntry, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareclass/sharehistory/${ uuid }`, method: 'get'
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'ShareClassShareHistory' )
            ),
        } ),
        getManyShareClassesShareHistory: builder.query<EntityState<IORCompanyShareClassShareHistoryEntry> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareclass/sharehistory`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'ShareClassesShareHistory', 'ShareClassShareHistory' )
            ),
            transformResponse: ( data: IORCompanyShareClassShareHistoryEntry[], meta: IORResponseMetaData ) => ( {
                ...shareClassShareHistoryAdapter.upsertMany( shareClassShareHistoryAdapter.getInitialState(), data ),
                ...{ meta }
            } )
        } ),
        postShareClassNominalValue: builder.mutation<IORCompanyShareClass, { uuid: string, data: Partial<IORCompanyShareClassNominalValueMainAttributes> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareclass/${ uuid }/nominalvalue`, method: 'post', data
            } ),
            invalidatesTags: ( _, __, { uuid } ) => ( [
                ...providesList( [ uuid ], 'ShareClasses', 'ShareClass' ),
                ...providesList( [], 'ShareClassesShareHistory' ),
                ...providesList( [], 'Shareholdings' ),
                ...providesList( [], 'ShareholdingsSummary' ),
                ...providesList( [], 'Transactions' ),
                ...providesList( [], 'ShareIssues' ),
                ...providesList( [], 'ShareRounds' ),
                ...providesList( [], 'ShareTransfers' )
            ] )
        } ),
        getOneShareClassRedesignation: builder.query<IORCompanyShareClassRedesignation, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareclass/redesignations/${ uuid }`, method: 'get'
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'ShareClassRedesignation' )
            ),
        } ),
        getManyShareClassRedesignations: builder.query<EntityState<IORCompanyShareClassShareHistoryEntry> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareclass/redesignations`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'ShareClassRedesignations', 'ShareClassRedesignation' )
            ),
            transformResponse: ( data: IORCompanyShareClassShareHistoryEntry[], meta: IORResponseMetaData ) => ( {
                ...shareClassShareHistoryAdapter.upsertMany( shareClassShareHistoryAdapter.getInitialState(), data ),
                ...{ meta }
            } )
        } ),
        postShareClassRedesignation: builder.mutation<IORCompanyShareClassRedesignation, {
            uuid: string,
            data: Partial<IORCompanyShareClassRedesignationWithRemainingSharesMainAttributes | IORCompanyShareClassRedesignationNoRemainingSharesMainAttributes>
        }>( {
            query: ( { uuid, data } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareclass/${ uuid }/redesignate`, method: 'post', data
            } ),
            invalidatesTags: () => ( [
                ...providesList( [], 'ShareClassRedesignations' ),
                ...providesList( [], 'ShareClasses' ),
                ...providesList( [], 'ShareClassesShareHistory' ),
                ...providesList( [], 'Shareholdings' ),
                ...providesList( [], 'ShareholdingsSummary' ),
                ...providesList( [], 'Transactions' ),
                ...providesList( [], 'ShareIssues' ),
                ...providesList( [], 'ShareRounds' ),
                ...providesList( [], 'ShareTransfers' )
            ] )
        } ),
        deleteShareClass: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareclass/${ uuid }`, method: 'delete'
            } ),
            invalidatesTags: ( _, __, uuid ) => ( [
                ...providesList( [ uuid ], 'ShareClasses', 'ShareClass' ),
                ...providesList( [], 'ShareClassesShareHistory' ),
                ...providesList( [], 'Shareholdings' ),
                ...providesList( [], 'ShareholdingsSummary' ),
                ...providesList( [], 'Transactions' ),
                ...providesList( [], 'ShareIssues' ),
                ...providesList( [], 'ShareRounds' ),
                ...providesList( [], 'ShareTransfers' )
            ] ),
        } ),
    } ),
} );

export const {
    useGetOneShareClassQuery,
    useLazyGetOneShareClassQuery,
    useGetManyShareClassesQuery,
    useLazyGetManyShareClassesQuery,
    usePatchShareClassMutation,
    usePostShareClassMutation,
    useGetOneShareClassShareHistoryQuery,
    useLazyGetOneShareClassShareHistoryQuery,
    useGetManyShareClassesShareHistoryQuery,
    useLazyGetManyShareClassesShareHistoryQuery,
    usePostShareClassNominalValueMutation,
    useGetOneShareClassRedesignationQuery,
    useLazyGetOneShareClassRedesignationQuery,
    useGetManyShareClassRedesignationsQuery,
    useLazyGetManyShareClassRedesignationsQuery,
    usePostShareClassRedesignationMutation,
    useDeleteShareClassMutation,
    endpoints: shareClassApiEndpoints,
} = shareClassApi;