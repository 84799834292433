import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';
import { mapStakeholder } from '@/store/company/service.stakeholder';

import { compare, getCookie } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompanyShareIssue, IORCompanyShareIssueMainAttributes } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const shareIssueAdapter = createEntityAdapter<IORCompanyShareIssue>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at ),
    selectId: ( e ) => e.uuid,
} );

const mapShareIssue = ( si: IORCompanyShareIssue ): IORCompanyShareIssue =>
{
    return ( {
        ...si,
        stakeholder_details: mapStakeholder( si.stakeholder_details ),
    } );
};

export const shareIssueApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneShareIssue: builder.query<IORCompanyShareIssue, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareissue/${ uuid }`, method: 'get'
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'ShareIssue' )
            ),
            transformResponse: mapShareIssue
        } ),
        getManyShareIssues: builder.query<EntityState<IORCompanyShareIssue> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareissue/`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'ShareIssues', 'ShareIssue' )
            ),
            transformResponse: ( data: IORCompanyShareIssue[], meta: IORResponseMetaData ) => ( {
                ...shareIssueAdapter.upsertMany( shareIssueAdapter.getInitialState(), data.map( mapShareIssue ) ),
                ...{ meta }
            } )
        } ),
        getManyShareIssuesForRound: builder.query<EntityState<IORCompanyShareIssue> & IORResponseMeta, { shareRound: string, options: IORGetParameters }>( {
            query: ( { shareRound, options: { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareround/${ shareRound }/shareissue/`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'ShareIssues', 'ShareIssue' )
            ),
            transformResponse: ( data: IORCompanyShareIssue[], meta: IORResponseMetaData ) => ( {
                ...shareIssueAdapter.upsertMany( shareIssueAdapter.getInitialState(), data ),
                ...{ meta }
            } )
        } ),
        patchShareIssue: builder.mutation<IORCompanyShareIssue, { uuid: string, data: Partial<IORCompanyShareIssueMainAttributes> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareissue/${ uuid }`, method: 'patch', data
            } ),
            invalidatesTags: ( _, __, { uuid } ) => ( [
                ...providesIds( [ uuid ], 'ShareIssue' ),
                ...providesList( [], 'Shareholdings' ),
                ...providesList( [], 'ShareholdingsSummary' ),
                ...providesIds( [ getCookie( { cName: 'orCurrentCompanyUuid' } ) ], 'Company' )
            ] )
        } ),
        postShareIssue: builder.mutation<IORCompanyShareIssue, Partial<IORCompanyShareIssueMainAttributes>>( {
            query: ( data ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareissue/`, method: 'post', data
            } ),
            invalidatesTags: () => ( [
                ...providesList( [], 'ShareIssues' ),
                ...providesList( [], 'Shareholdings' ),
                ...providesList( [], 'ShareholdingsSummary' ),
                ...providesIds( [ getCookie( { cName: 'orCurrentCompanyUuid' } ) ], 'Company' )
            ] )
        } ),
        deleteShareIssue: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareissue/${ uuid }`, method: 'delete'
            } ),
            invalidatesTags: ( _, __, uuid ) => ( [
                ...providesList( [ uuid ], 'ShareIssues', 'ShareIssue' ),
                ...providesList( [], 'Shareholdings' ),
                ...providesList( [], 'ShareholdingsSummary' ),
                ...providesIds( [ getCookie( { cName: 'orCurrentCompanyUuid' } ) ], 'Company' )
            ] ),
        } ),
    } ),
} );

export const {
    useGetOneShareIssueQuery,
    useLazyGetOneShareIssueQuery,
    useGetManyShareIssuesQuery,
    useGetManyShareIssuesForRoundQuery,
    useLazyGetManyShareIssuesQuery,
    usePatchShareIssueMutation,
    usePostShareIssueMutation,
    useDeleteShareIssueMutation,
    endpoints: shareIssueApiEndpoints,
} = shareIssueApi;