import { type FC, memo, useCallback } from 'react';

import { useCurrentCompany } from '@/store/company/hooks';

import { CompanyChangeRegisteredAddressForm } from '@/features/company/my-company/components';
import { DataFormat, FieldContainerCols, FieldsWrapperGrid, FieldTitleTop, FieldValueDefault, Loader, openModal, Tooltip, WidgetSimpleWithAction } from '@/components/structural';
import { SVGIcon } from '@/components/structural/images';

import { useCurrentUser } from '@/store/user/hooks';

import type { ICHCompanyProfile } from '@/features/company/shared/models';

import infoIcon from '@/icons/info-circle-fill.svg';


export const CompanyRegisteredAddressWidget: FC = memo( () =>
{
    const { chProfile, isFetching } = useCurrentCompany();
    const { settings } = useCurrentUser( {} );

    const openModalForm = useCallback( () =>
    {
        openModal( {
            type: 'confirm',
            children: <CompanyChangeRegisteredAddressForm/>,
        } );
    }, [] );

    if ( isFetching ) return <Loader skeleton/>;

    return ( <>
        <WidgetSimpleWithAction
              title="Registered Office Address"
              actionTitle="CHANGE ADDRESS"
              variant="regular"
              onClick={ openModalForm }
        >
            <RegisteredAddressDetails chProfile={ chProfile }/>
        </WidgetSimpleWithAction>
        { settings?.view_all_widget_states && <WidgetSimpleWithAction
              title="Registered Office Address"
              actionTitle="CHANGE ADDRESS"
              variant="draft"
              onClick={ openModalForm }
        >
            <RegisteredAddressDetails chProfile={ chProfile }/>
        </WidgetSimpleWithAction> }
        { settings?.view_all_widget_states && <WidgetSimpleWithAction
              title="Registered Office Address"
              variant="updated"
              updatedText="CHANGE SUBMITTED: 9 DEC 2021"
        >
            <RegisteredAddressDetails chProfile={ chProfile }/>
        </WidgetSimpleWithAction> }
    </> );
} );

CompanyRegisteredAddressWidget.displayName = 'CompanyRegisteredAddressWidget';

export const RegisteredAddressDetails: FC<{ chProfile: ICHCompanyProfile }> = memo( ( { chProfile } ) => ( <>
    <div className="mb-4">
        The registered office address is the official address of the company (or limited liability partnership) registered with Companies House.
        <Tooltip
              className="inline-flex items-end"
              title={ <>
                  Every company must always have a registered office in the UK, even if it carries on its business from abroad (or just sells
                  products online rather than from a physical location). The registered office is the address to which government bodies (primarily
                  Companies House and HMRC, but possibly also other agencies) may address official communications, notices and reminders relating
                  to the company.It is assumed that any official notice sent to the company’s registered office address has been received by the
                  company. Because of this, the company’s registered office address must be effective for delivering documents to the company and
                  its directors. It’s the company’s responsibility to ensure they have access to documents received at this address.
              </> }
        >
            &nbsp;
            <SVGIcon
                  className={ `!w-5 !h-5 text-info dark:text-info-dark relative bottom-[0.2rem]` }
                  src={ infoIcon.src }
            />
        </Tooltip>
    </div>
    <FieldsWrapperGrid>
        <FieldContainerCols>
            <FieldTitleTop>
                Building Name/Number:
            </FieldTitleTop>
            <FieldValueDefault>
                <DataFormat raw={ chProfile?.registered_office_address.address_line_1 }/>
            </FieldValueDefault>
        </FieldContainerCols>
        <FieldContainerCols>
            <FieldTitleTop>
                Street:
            </FieldTitleTop>
            <FieldValueDefault>
                <DataFormat raw={ chProfile?.registered_office_address.address_line_2 }/>
            </FieldValueDefault>
        </FieldContainerCols>
        <FieldContainerCols>
            <FieldTitleTop>
                Post Town:
            </FieldTitleTop>
            <FieldValueDefault>
                <DataFormat raw={ chProfile?.registered_office_address.locality }/>
            </FieldValueDefault>
        </FieldContainerCols>
        <FieldContainerCols>
            <FieldTitleTop>
                County/Region:
            </FieldTitleTop>
            <FieldValueDefault>
                <DataFormat raw={ chProfile?.registered_office_address.region }/>
            </FieldValueDefault>
        </FieldContainerCols>
        <FieldContainerCols>
            <FieldTitleTop>
                Postcode:
            </FieldTitleTop>
            <FieldValueDefault>
                <DataFormat raw={ chProfile?.registered_office_address.postal_code }/>
            </FieldValueDefault>
        </FieldContainerCols>
        <FieldContainerCols>
            <FieldTitleTop>
                Registered Country:
            </FieldTitleTop>
            <FieldValueDefault>
                <DataFormat raw={ chProfile?.registered_office_address.country }/>
            </FieldValueDefault>
        </FieldContainerCols>
    </FieldsWrapperGrid>
</> ) );

RegisteredAddressDetails.displayName = 'CompanyRegisteredAddressDetails';