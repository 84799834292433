import { createSelector } from '@reduxjs/toolkit';

import type { TRootState } from '@/store';

import { companyAdapter } from './service.company';
import { convertibleLoanNoteAdapter } from './service.convertible-loan-note';
import { filingAdapter } from './service.filing';
import { filingCompanyRegisteredAddressAdapter } from './service.filing.company-registered-address';
import { filingCompanyRegisteredNameAdapter } from './service.filing.company-registered-name';
import { optionRoundAdapter } from './service.option-round';
import { optionIssueAdapter } from './service.option-issue';
import { shareIssueAdapter } from './service.share-issue';
import { shareClassAdapter, shareClassRedesignationAdapter, shareClassShareHistoryAdapter } from './service.share-class';
import { shareDilutionAdapter } from './service.share-dilution';
import { shareRoundAdapter } from './service.share-round';
import { shareTransferAdapter } from './service.share-transfer';
import { shareholdingAdapter } from './service.shareholding';
import { stakeholderAdapter } from './service.stakeholder';
import { transactionAdapter } from './service.transaction';

import { noteAdapter } from './service.note';
import { responsiblePartyAdapter } from './service.responsible-party';
import { tagAdapter, tagItemAdapter } from '@/store/company/service.tag';


export const companySelectors = companyAdapter.getSelectors<ReturnType<typeof companyAdapter.addOne>>(
      ( state ) => state
);

export const shareClassSelectors = shareClassAdapter.getSelectors<ReturnType<typeof shareClassAdapter.addOne>>(
      ( state ) => state
);

export const shareClassShareHistorySelectors = shareClassShareHistoryAdapter.getSelectors<ReturnType<typeof shareClassShareHistoryAdapter.addOne>>(
      ( state ) => state
);

export const shareClassRedesignationSelectors = shareClassRedesignationAdapter.getSelectors<ReturnType<typeof shareClassRedesignationAdapter.addOne>>(
      ( state ) => state
);

export const shareDilutionSelectors = shareDilutionAdapter.getSelectors<ReturnType<typeof shareDilutionAdapter.addOne>>(
      ( state ) => state
);

export const shareIssueSelectors = shareIssueAdapter.getSelectors<ReturnType<typeof shareIssueAdapter.addOne>>(
      ( state ) => state
);

export const shareholdingSelectors = shareholdingAdapter.getSelectors<ReturnType<typeof shareholdingAdapter.addOne>>(
      ( state ) => state
);

export const shareTransferSelectors = shareTransferAdapter.getSelectors<ReturnType<typeof shareTransferAdapter.addOne>>(
      ( state ) => state
);

export const shareRoundSelectors = shareRoundAdapter.getSelectors<ReturnType<typeof shareRoundAdapter.addOne>>(
      ( state ) => state
);

export const optionRoundSelectors = optionRoundAdapter.getSelectors<ReturnType<typeof optionRoundAdapter.addOne>>(
      ( state ) => state
);

export const optionIssueSelectors = optionIssueAdapter.getSelectors<ReturnType<typeof optionIssueAdapter.addOne>>(
      ( state ) => state
);

export const convertibleLoanNoteSelectors = convertibleLoanNoteAdapter.getSelectors<ReturnType<typeof convertibleLoanNoteAdapter.addOne>>(
      ( state ) => state
);

export const filingSelectors = filingAdapter.getSelectors<ReturnType<typeof filingAdapter.addOne>>(
      ( state ) => state
);

export const filingCompanyRegisteredAddressSelectors = filingCompanyRegisteredAddressAdapter.getSelectors<ReturnType<typeof filingCompanyRegisteredAddressAdapter.addOne>>(
      ( state ) => state
);

export const filingCompanyRegisteredNameSelectors = filingCompanyRegisteredNameAdapter.getSelectors<ReturnType<typeof filingCompanyRegisteredNameAdapter.addOne>>(
      ( state ) => state
);

export const stakeholderSelectors = stakeholderAdapter.getSelectors<ReturnType<typeof stakeholderAdapter.addOne>>(
      ( state ) => state
);

export const transactionSelectors = transactionAdapter.getSelectors<ReturnType<typeof transactionAdapter.addOne>>(
      ( state ) => state
);

export const noteSelectors = noteAdapter.getSelectors<ReturnType<typeof noteAdapter.addOne>>(
      ( state ) => state
);

export const responsiblePartySelectors = responsiblePartyAdapter.getSelectors<ReturnType<typeof responsiblePartyAdapter.addOne>>(
      ( state ) => state
);

export const tagSelectors = tagAdapter.getSelectors<ReturnType<typeof tagAdapter.getInitialState>>(
      ( state ) => state
);

export const tagItemSelectors = tagItemAdapter.getSelectors<ReturnType<typeof tagItemAdapter.getInitialState>>(
      ( state ) => state
);

export const selectCompanyState = ( state: TRootState ) => state.company;

export const selectCompanyWidgetsFilingDeadlines = createSelector(
      selectCompanyState,
      ( company ) => company.widgets.filingDeadlines
);