import { type FC, forwardRef, memo, type Ref } from 'react';
import { type BaseSelectRef } from 'rc-select';
import tw from 'tailwind-styled-components';
import styled from 'styled-components';

import SelectAntD, { type RefSelectProps as RefSelectPropsAntd, type SelectProps as SelectPropsAntd } from 'antd/lib/select';


const SelectOR = tw( SelectAntD )`
    w-full
    relative
`;

const SelectStyled = styled( SelectOR )<SelectPropsAntd>`
    &.ant-select-lg .ant-select-selector {
        height: 2.65rem !important;
    }
`;

export const Select: FC<SelectPropsAntd & {
    ref?: Ref<BaseSelectRef> | undefined;
}> = memo( forwardRef( ( props, ref ) =>
{
    const {
        size = 'large',
        ...rest
    } = props;

    return <SelectStyled
          { ...rest }
          size={ size }
          ref={ ref }
    />;
} ) );
Select.displayName = 'Select';

export const Option = SelectAntD.Option;
export const OptGroup = SelectAntD.OptGroup;

export type RefSelectProps = RefSelectPropsAntd;
export type SelectProps = SelectPropsAntd;