import { useMemo } from 'react';

import { useAppSelector } from '@/store/hooks';
import { selectHTTPRequest } from '@/store/internal/selectors';


export const useHTTPRequest = () =>
{
    const httpRequest = useAppSelector( selectHTTPRequest );

    return useMemo( () => ( {
        httpRequest
    } ), [ httpRequest ] );
};