import { useCallback, useRef, useState } from 'react';

import type { FormInstance } from 'antd/lib/form/Form';
import type { StepsProps } from 'antd/lib/steps';


export interface IFormFeedbackMessages
{
    errorFields: {
        name: string[],
        errors: string[],
        warnings: string[]
    }[],
}

export const useStepsForm = () =>
{
    const [ currentStep, setCurrentStep ] = useState( 0 );
    const [ currentStepStatus, setCurrentStepStatus ] = useState<StepsProps['status']>( 'process' );
    const [ isValid, setIsValid ] = useState( false );
    const formFeedbackMessages = useRef<IFormFeedbackMessages>( { errorFields: [] } );

    const next = useCallback( () =>
    {
        setCurrentStep( () => currentStep + 1 );
    }, [ currentStep ] );

    const prev = useCallback( () =>
    {
        setCurrentStep( () => currentStep - 1 );
    }, [ currentStep ] );

    const goToStep = useCallback( ( step: number ) =>
    {
        setCurrentStep( () => step );
    }, [] );

    const isStepValid = useCallback( async ( form: FormInstance ) =>
    {
        try
        {
            await form.validateFields();
            return true;
        } catch ( e )
        {
            const result = e as { errorFields: { errors: string[], name: string[], warnings: string[] }[] };
            const hasErrors = result.errorFields.some( f => f.errors.length );

            formFeedbackMessages.current = result;

            return !hasErrors;
        }
    }, [] );

    return {
        prev, next, goToStep, isStepValid,
        currentStep, currentStepStatus, setCurrentStepStatus,
        isValid, setIsValid,
        formFeedbackMessages: formFeedbackMessages.current
    };
};