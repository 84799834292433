import { useRouter } from 'next/router';
import tw from 'tailwind-styled-components';
import { useMemo } from 'react';
import { useResponsive } from 'ahooks/lib/useResponsive';

import { doLogout } from '@/store/auth/actions';
import { doPatchCurrentUser } from '@/store/user/actions';
import { useAppDispatch } from '@/store/hooks';
import { useAuth } from '@/store/auth/hooks';
import { useCompanyQuickstartStatus } from '@/features/company/my-company/hooks';
import { useCurrentCompany } from '@/store/company/hooks';
import { useDatastoreInfo } from '@/features/datastore/hooks/useDatastoreInfo.hook';
import { usePatchCompanyMutation } from '@/store/company';
import { useCurrentUser } from '@/store/user/hooks';
import { useVersion } from '@/store/internal/hooks';

import { Badge, BadgeWrapper, Link, type MenuItemType, routerNavigate } from '@/components/structural';
import { SVGIcon } from '@/components/structural/images';

import { VIEW_ALL_COMPANIES_ID } from '@/shared/constants';

import accountIcon from '@/icons/person.svg';
import profileIcon from '@/icons/person.svg';
import companyQuickstartIcon from '@/icons/pie-chart.svg';
import documentsIcon from '@/icons/briefcase.svg';
import getInTouchIcon from '@/icons/question-circle.svg';
import logoutIcon from '@/icons/box-arrow-right.svg';
import myCompanyIcon from '@/icons/house.svg';
import portfolioIcon from '@/icons/graph-up-arrow.svg';
import restartTutorialIcon from '@/icons/arrow-counterclockwise.svg';
import stakeholdersIcon from '@/icons/person-plus.svg';
import versionIcon from '@/icons/terminal.svg';


export const useMenu = () =>
{
    const router = useRouter();
    const dispatchAction = useAppDispatch();
    const [ patchCompany ] = usePatchCompanyMutation();
    const breakpoints = useResponsive();

    const { awsUser } = useAuth();
    const { me, settings } = useCurrentUser( {} );
    const { orCompany } = useCurrentCompany();
    const { owner, variant } = useDatastoreInfo();
    const { version } = useVersion();
    const { allStepsDone } = useCompanyQuickstartStatus();

    const portfolioMenu: MenuItemType[] = useMemo( () => [ {
        label: /*( breakpoints.xl || !breakpoints.lg ) && */'My Portfolio',
        icon: <Icon src={ portfolioIcon.src } className={ '!w-[1.6rem] translate-y-[-0.15rem] ' +
              ( !router.pathname.startsWith( '/portfolio' ) && !breakpoints.xl && 'translate-x-[0.3rem]' ) }/>,
        key: 'portfolio',
        children: [
            {
                label: <Link to="/portfolio">Overview</Link>,
                key: '/portfolio',
            },
            {
                label: <Link to="/portfolio/investments">Investments</Link>,
                key: '/portfolio/investments',
            },
            {
                label: <Link to="/portfolio/projections">Projections</Link>,
                key: '/portfolio/projections',
            },
        ]
    } ], [ breakpoints.xl, breakpoints.lg ] );

    const portfolioDocumentsMenu: MenuItemType[] = useMemo( () => [ {
        label: <Link to={ `/portfolio/documents?owner=${ owner }&variant=${ variant }&path=/` }>{ 'Documents' }</Link>,
        icon: <Icon src={ documentsIcon.src }
                    className={ '!w-[1.4rem] translate-y-[-0.1rem]' }/>,
        key: '/portfolio/documents'
    } ], [ breakpoints.xl, breakpoints.md, owner, variant ] );

    const myCompanyMenu: MenuItemType[] = useMemo( () => [ {
        label: ( breakpoints.xl || !breakpoints.md ) && 'My Company',
        icon: <Icon src={ myCompanyIcon.src } className={ '!w-[1.4rem] !h-[1.4rem] translate-y-[-0.1rem] ' + ( !breakpoints.xl && 'translate-x-[0.3rem]' ) }/>,
        key: 'my-company',
        children: [ {
            label: <Link to="/my-company">Overview</Link>,
            key: '/my-company',
        }, {
            label: <Link to="/my-company/company-information">Company Information</Link>,
            key: '/my-company/company-information',
        }, {
            label: <Link to="/my-company/officers">Officers</Link>,
            key: '/my-company/officers',
        }, {
            label: <Link to="/my-company/pscs">PSCs</Link>,
            key: '/my-company/pscs',
        }, {
            label: <Link to="/my-company/mortgages-and-charges">Mortgages and Charges</Link>,
            key: '/my-company/mortgages-and-charges',
        }, {
            label: <Link to="/my-company/exemptions">Exemptions</Link>,
            key: '/my-company/exemptions',
        }, {
            label: <Link to="/my-company/filings">Statutory Filings</Link>,
            key: '/my-company/filings',
        }, {
            label: <Link to="/my-company/filings-log">Filing Log</Link>,
            key: '/my-company/filings-log',
        } ]
    } ], [ breakpoints.xl, breakpoints.md ] );

    const shareCapitalMenu: MenuItemType[] = useMemo( () => [ {
        label: ( breakpoints.xl || !breakpoints.md ) && <span>
            <span className="pointer-events-none">Share Capital</span>
            { settings?.currentCompanyUuid !== VIEW_ALL_COMPANIES_ID && !allStepsDone &&
                  <span onClick={ () => routerNavigate( '/my-company/quickstart' ) }>
                <BadgeWrapper size="small"
                              count={ <Badge
                                    className="!-translate-y-0.5 !translate-x-0.5"
                                    onClick={ ( e ) =>
                                    {
                                        e.stopPropagation();
                                        routerNavigate( '/my-company/quickstart' );
                                    } }>!</Badge> }
                              title="Click to open the Company Quickstart Guide"
                              status="error"
                              offset={ [ 2, -3 ] }
                              className="hover:cursor-pointer"/>
            </span> }
        </span>,
        icon: ( !breakpoints.xl && breakpoints.md && settings?.currentCompanyUuid !== VIEW_ALL_COMPANIES_ID && !allStepsDone ) ?
              <span
                    className={ 'relative translate-y-[0.15rem] ' + ( !breakpoints.xl && 'translate-x-[0.3rem]' ) }
              >
              <BadgeWrapper
                    size="small"
                    count={ <Badge
                          onClick={ ( e ) =>
                          {
                              e.stopPropagation();
                              routerNavigate( '/my-company/quickstart' );
                          } }>!</Badge>
                    }
                    title="Click to open the Company Quickstart Guide"
                    status="error"
                    offset={ [ 2, 3 ] }
                    className="hover:cursor-pointer"
              >
                <Icon src={ companyQuickstartIcon.src }
                />
              </BadgeWrapper>
        </span> : <Icon src={ companyQuickstartIcon.src } className={ 'translate-y-[-0.1rem] ' + ( !breakpoints.xl && 'translate-x-[0.3rem]' ) }/>,
        key: 'share-capital',
        children: [ {
            label: <Link to="/share-capital">Overview</Link>,
            key: '/share-capital',
        }, {
            label: <Link to="/share-capital/share-classes">Share Classes</Link>,
            key: '/share-capital/share-classes',
        }, {
            label: <Link to="/share-capital/shareholders">Shareholders</Link>,
            key: '/share-capital/shareholders',
        }, {
            label: <Link to="/share-capital/share-rounds">Rounds</Link>,
            key: '/share-capital/share-rounds',
        }, {
            label: <Link to="/share-capital/share-transfers">Transfers</Link>,
            key: '/share-capital/share-transfers',
        }, {
            label: <Link to="/share-capital/share-transactions">Transactions</Link>,
            key: '/share-capital/share-transactions',
        }, {
            label: <Link to="/share-capital/dilution-tool">Dilution Tool</Link>,
            key: '/share-capital/dilution-tool',
        }
            /*, orCompany?.option_enable && {
                label: 'Share Options',
                children: [
                    {
                        label: <Link to="/share-capital/share-options">Option Classes</Link>,
                        key: '/share-capital/share-options',
                    },
                    {
                        label: <Link to="/share-capital/share-option-pools">Option Pools</Link>,
                        key: '/share-capital/share-option-pools',
                    },
                    {
                        label: <Link to="/share-capital/share-option-transactions">Options in Issue</Link>,
                        key: '/share-capital/share-option-transactions',
                    },
                    {
                        label: <Link to="/share-capital/share-option-holders">Option Holders</Link>,
                        key: '/share-capital/share-option-holders',
                    },
                ]
            }, orCompany?.cln_enable && {
                label: 'Convertible Loan Notes',
                children: [
                    {
                        label: <Link to="/share-capital/share-cln-transactions">Loan Notes in Issue</Link>,
                        key: '/share-capital/share-cln-transactions',
                    },
                    {
                        label: <Link to="/share-capital/share-cln-holders">Loan Note Holders</Link>,
                        key: '/share-capital/share-cln-holders',
                    },
                ]
            },*/ ]
    } ], [ breakpoints.xl, breakpoints.md, breakpoints.md,/* orCompany, orCompany?.option_enable, orCompany?.cln_enable,*/ allStepsDone, settings ] );

    const documentsMenu: MenuItemType[] = useMemo( () => [ {
        label: <Link to={ `/documents?owner=${ owner }&variant=${ variant }&path=/` }>{ ( breakpoints.xl || !breakpoints.md ) && 'Documents' }</Link>,
        icon: <Icon src={ documentsIcon.src }
                    className={ '!w-[1.4rem] translate-y-[-0.1rem] ' +
                          ( !breakpoints.xl && 'translate-x-[0.3rem]' )
                    }/>,
        key: '/documents'
    } ], [ breakpoints.xl, breakpoints.md, owner, variant ] );

    const stakeholdersMenu: MenuItemType[] = useMemo( () => [ {
        label: <Link to="/stakeholders">{ ( breakpoints.xl || !breakpoints.md ) && 'Stakeholders' }</Link>,
        icon: <Icon src={ stakeholdersIcon.src }
                    className={ '!w-[1.5rem] translate-y-[-0.15rem] ' +
                          ( breakpoints.md && 'translate-x-[0.4rem] ' ) +
                          ( !breakpoints.md && ' translate-x-[0.3rem]' )
                    }/>,
        key: '/stakeholders',
        /*children: [ {
            label: <Link to="/stakeholders/people">People</Link>,
            key: '/stakeholders/people',
        }, {
            label: <Link to="/stakeholders/companies">Companies</Link>,
            key: '/stakeholders/companies',
        } ]*/
    } ], [ breakpoints.xl, breakpoints.md ] );

    const userMenu: MenuItemType[] = useMemo( () => [ {
        label: ( breakpoints.xl || !breakpoints.md ) &&
              <span className={ `${ !breakpoints.xl ? '-translate-x-2' : '' } block` }>
                  { me ? `${ me.first_name || me.email }` : `${ awsUser?.username }` }
              </span>,
        icon: <Icon src={ accountIcon.src }
                    className={ '!w-[2rem] ' +
                          ( breakpoints.xl && ' translate-x-[0] ' ) +
                          ( breakpoints.md && ' translate-x-[0.4rem] ' ) +
                          ( !breakpoints.md && ' ' )
                    }/>,
        link: '/account',
        key: 'account',
        children: [ {
            label: <Link to="/account">Profile</Link>,
            icon: <Icon src={ profileIcon.src }/>,
            key: '/account'
        }, /*{
            label: <Link to="/account/audit">Audit</Link>,
            icon: <Icon src={ auditIcon.src }/>,
            key: '/account/audit'
        }, */!settings?.portfolio_quickstart_completed && [ 'investor', 'investor-company' ].includes( settings?.user_type ) && {
            label: <Link to="/portfolio/quickstart">Portfolio Quickstart Guide</Link>,
            icon: <Icon src={ restartTutorialIcon.src }/>,
            key: 'portfolio-quickstart-main',
        }, settings?.portfolio_quickstart_completed && [ 'investor', 'investor-company' ].includes( settings?.user_type ) && {
            label: 'Show Portfolio Quickstart Guide',
            icon: <Icon src={ restartTutorialIcon.src }/>,
            key: 'portfolio-quickstart',
            onClick: () =>
            {
                dispatchAction( doPatchCurrentUser( {
                    settings: {
                        portfolio_quickstart_completed: false
                    }
                } ) );
                routerNavigate( '/portfolio/quickstart' );
            },
        }, !orCompany?.setup_complete && [ 'company', 'investor-company' ].includes( settings?.user_type ) && {
            label: <Link to="/my-company/quickstart">Company Quickstart Guide</Link>,
            icon: <Icon src={ restartTutorialIcon.src }/>,
            key: 'my-company-quickstart-main',
        }, orCompany?.setup_complete && [ 'company', 'investor-company' ].includes( settings?.user_type ) && {
            label: 'Show Company Quickstart Guide',
            icon: <Icon src={ restartTutorialIcon.src }/>,
            key: 'my-company-quickstart',
            onClick: () =>
            {
                patchCompany( {
                    uuid: orCompany?.uuid,
                    data: {
                        setup_complete: false
                    }
                } );
                routerNavigate( '/my-company/quickstart' );
            },
        }, {
            label: 'Get in Touch',
            icon: <Icon src={ getInTouchIcon.src }/>,
            onClick: () =>
            {
                window.location.assign( 'mailto:getintouch@oneregistry.co.uk?subject=Need%20Help' );
            }
        }, {
            label: 'Logout',
            icon: <Icon src={ logoutIcon.src }/>,
            danger: true,
            onClick: () =>
            {
                dispatchAction( doLogout() );
            },
        }, {
            type: 'divider'
        }, {
            label: version ? `v${ version.major }.${ version.minor }.${ version.patch }` : ``,
            icon: <Icon src={ versionIcon.src }/>
        } ]
    } ], [ breakpoints.xl, breakpoints.md, me, awsUser, orCompany, orCompany?.setup_complete, version ] );

    return useMemo( () => ( {
        portfolioMenu, portfolioDocumentsMenu, myCompanyMenu, shareCapitalMenu, documentsMenu, stakeholdersMenu, userMenu
    } ), [ portfolioMenu, portfolioDocumentsMenu, myCompanyMenu, shareCapitalMenu, documentsMenu, stakeholdersMenu, userMenu ] );
};

const Icon = tw( SVGIcon )`
    text-current
    !w-5
    !h-5
`;