import type { EntityId } from '@reduxjs/toolkit';


export const providesIds = <R extends EntityId[], T extends string>(
      resultsWithIdsArray: R | undefined,
      tagType: T
) =>
{
    return resultsWithIdsArray
          ? [
              ...resultsWithIdsArray.map( ( uuid ) => ( { type: tagType, uuid } ) ),
          ]
          : [];
};