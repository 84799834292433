import { useCallback, useState } from 'react';


export const useRefreshComponent = () =>
{
    const [ , setObject ] = useState( {} );
    const refreshComponent = useCallback( () =>
    {
        setObject( {} );
    }, [] );

    return {
        refreshComponent
    };
};