import type { ICHCompanyExemption, ICHCompanyExemptions } from '@/features/company/shared/models';


export interface ICHCompanyExemptionFormatted
{
    type: string,
    value: ICHCompanyExemption,
}

export const formatCompanyExemptions = ( exemptions: ICHCompanyExemptions ): ICHCompanyExemptionFormatted[] =>
{
    if ( !exemptions )
    {
        return [];
    }

    const keys = Object.keys( exemptions );
    const list: { type: string, value: ICHCompanyExemption }[] = [];

    keys.forEach( key =>
    {
        list.push( {
            type: key,
            value: exemptions[ key ]
        } );
    } );

    return list;
};