import { type FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/query';

import { doPatchCurrentUser } from '@/store/user/actions';
import { useAppDispatch } from '@/store/hooks';
import { useCompanies } from '@/store/company/hooks';
import { usePostCompanyMutation } from '@/store/company';
import { useCurrentUser } from '@/store/user/hooks';

import { routerNavigate } from '@/components/structural';
import { FormWithSteps, type IFormWithStepsStep, useStepsForm, } from '@/components/structural/form';

import { getCookie } from '@/shared/tools';

import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { StepEnd } from './StepEnd';

import { OR_HMRC_COMPANY_EIS_LIMIT_CURRENT_YEAR, OR_HMRC_COMPANY_EIS_LIMIT_LIFETIME, OR_HMRC_COMPANY_SEIS_LIMIT } from '@/shared/constants';

import type { ICHCompanyProfile, ICHCompanySearchResultItem } from '@/features/company/shared/models';
import type { IORUser, IORUserMainAttributes } from '@/features/user/models';
import type { ICompanyOnboardData } from './company-onboard-data.interface';


export interface ICompanyOnboardFormProps
{
    className?: string,
}

export const CompanyOnboardForm: FC<ICompanyOnboardFormProps> = memo( () =>
{
    const dispatch = useAppDispatch();
    const [ postCompany, { status: companyPostStatus, error: companyPostError, isLoading, data: postCompanyResponse } ] = usePostCompanyMutation();

    const { currentStep, currentStepStatus, goToStep, next, prev } = useStepsForm();
    const { me } = useCurrentUser( {} );
    const { companies } = useCompanies();

    const [ data, setData ] = useState<ICompanyOnboardData>( {
        settings: {
            seis_allocation: OR_HMRC_COMPANY_SEIS_LIMIT,
            eis_allocation: OR_HMRC_COMPANY_EIS_LIMIT_LIFETIME,
            eis_allocation_yearly: OR_HMRC_COMPANY_EIS_LIMIT_CURRENT_YEAR,
        }
    } );

    const [ stepsValid, setStepsValid ] = useState( {} );
    const validationSet = useRef( false );

    useEffect( () =>
    {
        if ( !me?.settings || validationSet.current ) return;

        setStepsValid( () => ( me?.settings?.user_type ? {
            0: false,
            1: true,
            2: true,
        } : {
            0: false,
            1: false,
            2: true,
            3: true
        } ) );

        validationSet.current = true;
    }, [ me?.settings?.user_type ] );

    const updateData = useCallback( ( key: keyof typeof data, innerKey = null ) => ( value ) =>
    {
        if ( innerKey )
        {
            setData( ( prevData: object ) => ( {
                ...prevData,
                [ key ]: {
                    ...prevData[ key ],
                    [ innerKey ]: value
                }
            } ) );
        } else
        {
            setData( ( prevData ) => ( {
                ...prevData,
                [ key ]: value
            } ) );
        }
    }, [] );

    const doCancel = useCallback( () =>
    {
        if ( currentStep === 0 ) routerNavigate( '/my-company' );
        else goToStep( 0 );
    }, [ currentStep ] );

    const showingStep1SetRef = useRef<boolean>( null );
    const showingStep1 = useMemo( () =>
    {
        if ( !me?.settings ) return null;
        if ( showingStep1SetRef.current !== null ) return showingStep1SetRef.current;
        return ( showingStep1SetRef.current = !me.settings.user_type );
    }, [ me?.settings?.user_type ] );

    const verifyStep1 = useCallback( ( { user_type }: { user_type: IORUser['settings']['user_type'] } ) =>
    {
        if ( user_type )
        {
            updateData( 'user_type' )( user_type );

            setStepsValid( ( value ) => ( {
                ...value,
                0: true
            } ) );
        }
    }, [] );

    const verifyStep2 = useCallback( ( chosenCompany: ICHCompanyProfile | ICHCompanySearchResultItem ) =>
    {
        if ( chosenCompany )
        {
            updateData( 'company' )( chosenCompany );

            setStepsValid( ( value ) => ( {
                ...value,
                [ !showingStep1 ? 0 : 1 ]: !companies.find( c => c.company_number === chosenCompany.company_number )
            } ) );
        }
    }, [ showingStep1, companies ] );

    const verifyStep3 = useCallback( ( key: keyof typeof data['settings'] ) => ( value: typeof data['settings'][typeof key] ) =>
    {
        if ( value )
        {
            updateData( 'settings', key )( value );
        }

        setStepsValid( ( value ) => ( {
            ...value,
            [ !showingStep1 ? 1 : 2 ]: true
        } ) );
    }, [ showingStep1 ] );

    const verifyStep4 = useCallback( ( key: keyof typeof data['extra'] ) => ( extraValue: typeof data['extra'][typeof key] ) =>
    {
        if ( extraValue )
        {
            updateData( 'extra', key )( extraValue );
        }

        setStepsValid( ( value ) => ( {
            ...value,
            [ !showingStep1 ? 2 : 3 ]: true
        } ) );
    }, [ showingStep1 ] );

    const doLaunch = useCallback( () =>
    {
        routerNavigate( '/my-company/quickstart' );
    }, [] );

    const doSave = useCallback( () =>
    {
        postCompany( {
            company_number: data.company.company_number,
            owner_account: getCookie( { cName: 'orCurrentAccountUuid' } ),
            eis_allocation: data.settings?.eis_allocation,
            seis_allocation: data.settings?.seis_allocation,
            web_filing_key: data.settings?.web_filing_key,
            company_website: data.extra?.company_website,
            company_profile: data.extra?.company_profile,
            company_logo: data.extra?.company_logo,
        } );
    }, [ data ] );

    const steps: IFormWithStepsStep[] = useMemo( () => ( [ showingStep1 ? {
        content: <Step1 onChange={ verifyStep1 }/>,
    } : null, {
        content: <Step2 data={ { company: data.company } } onChange={ verifyStep2 }/>,
    }, {
        content: <Step3 data={ {
            company: data.company, settings: data.settings
        } } onChange={ verifyStep3 }/>,
    }, {
        content: <Step4 data={ {
            company: data.company, extra: data.extra
        } } onChange={ verifyStep4 }/>,
    }, {
        content: <StepEnd onSubmit={ doLaunch } firstTimeLaunch={ showingStep1 }/>,
    } ] ), [ showingStep1, data, verifyStep1, verifyStep2, verifyStep3, verifyStep4, doLaunch ] );

    const lastStepIndex = steps.length - ( showingStep1 ? 1 : 2 );

    useEffect( () =>
    {
        if ( companyPostStatus !== QueryStatus.fulfilled ) return;

        if ( companyPostError ) return goToStep( 0 );

        if ( currentStep !== lastStepIndex ) goToStep( lastStepIndex );

        if ( showingStep1 && data.user_type )
        {
            const patchAttrs: Partial<IORUserMainAttributes> = {
                settings: {
                    ...me.settings,
                    user_type: data.user_type,
                    currentCompanyUuid: postCompanyResponse.uuid
                }
            };

            dispatch( doPatchCurrentUser( patchAttrs ) );
        }
    }, [ postCompanyResponse, companyPostStatus, companyPostError, currentStep, data, showingStep1, lastStepIndex ] );

    return <div className="w-[40rem] mx-auto bg-white py-8 px-12">
        <FormWithSteps
              currentStep={ currentStep }
              currentStepStatus={ currentStepStatus }
              steps={ steps }
              next={ next }
              prev={ prev }
              finish={ doSave }
              cancel={ doCancel }
              currentStepIsValid={ stepsValid[ currentStep ] }
              isLoading={ isLoading }
              hideNavigation={ currentStep === lastStepIndex }
              showORLogo
        />
    </div>;
} );

CompanyOnboardForm.displayName = 'CompanyOnboardForm';