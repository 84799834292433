import { flushSync } from 'react-dom';
import { useEffect, useMemo, useState } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/query';

import { selectActions } from './actions.selector';
import { storeUtils } from '@/store';
import { useAppSelector } from '@/store/hooks';

import { isAction, isActionFn } from './tools';

import type { TStoreAction } from '@/store/_global/actions-store/actions.reducer';


export const useActions = () =>
{
    const actions = useAppSelector( selectActions );

    return useMemo( () => ( {
              actions,
              isAction
          } ),
          [ actions ]
    );
};

export const useLastAction = () =>
{
    const [ lastAction, setLastAction ] = useState<TStoreAction>( null );

    useEffect( () => storeUtils.store?.subscribe( () =>
    {
        const newLastAction = storeUtils.store.getState().actions.lastAction;
        if ( newLastAction.isApi &&
              [ QueryStatus.fulfilled, QueryStatus.rejected ].includes( newLastAction.type ) )
        {
            setTimeout( () => flushSync( () =>
            {
                setLastAction( () => newLastAction );
            } ) );
        } else
        {
            setLastAction( () => newLastAction );
        }
    } ), [] );

    return useMemo( () => ( {
        lastAction,
        isAction: isActionFn( lastAction )
    } ), [ lastAction ] );
};