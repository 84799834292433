import { setupListeners } from '@reduxjs/toolkit/query';
import { applyMiddleware, configureStore, type Store } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, type Persistor, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import createSagaMiddleware, { type Task } from 'redux-saga';
import { createWrapper, HYDRATE } from 'next-redux-wrapper/lib';

import { rtkAPIMiddleware } from '@/store/rtk-queries';
import rootReducer from '@/store/reducers';
import rootSaga from '@/store/sagas';


export interface SagaStore extends AppStore
{
    sagaTask?: Task;
}

export interface PersistorStore extends AppStore
{
    __persistor?: Persistor;
}

export type ORStore = SagaStore & PersistorStore;

export const storeUtils: { dispatch: ReturnType<typeof makeStore>['dispatch'], store: ORStore } = {
    dispatch: null,
    store: null,
};

const makeStore = ( /*context: Context = undefined*/ ) =>
{
    const sagaMiddleware = createSagaMiddleware();

    const middleware = ( getDefaultMiddleware ) => getDefaultMiddleware( {
        serializableCheck: {
            ignoreState: true,
            // allow redux-persist and next-redux-wrapper to operate within the redux toolkit
            ignoredActions: [
                FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, // redux-persist
                HYDRATE, // next-redux-wrapper
                'notifications/createAlert',
                'notifications/clearAlert',
                'notifications/createBellAlert',
                'notifications/makeBellAlertRead',
                'notifications/makeAllBellAlertsRead',
                'notifications/changeBellAlertActionResult',
                'notifications/clearBellAlert',
                'notifications/createMessage',
                'notifications/createNotification'
            ]
        },
        immutableCheck: false,
    } ).concat( ...rtkAPIMiddleware );

    const enhancers = [ applyMiddleware( sagaMiddleware ) ];

    const store = configureStore( {
        reducer: rootReducer,
        middleware,
        enhancers,
        devTools: process.env.NODE_ENV !== 'production'
    } );

    setupListeners( store.dispatch );

    ( store as SagaStore ).sagaTask = sagaMiddleware.run( rootSaga );

    if ( typeof window !== 'undefined' )
    {
        ( store as PersistorStore ).__persistor = persistStore( store );
    }

    storeUtils.dispatch = store.dispatch;
    storeUtils.store = store;

    return store;
};

export const storeWrapper = createWrapper<Store>( makeStore, {
    debug: false,
    serializeState: ( state ) =>
    {
        try
        {
            return JSON.parse( state );
        } catch ( e )
        {
            return state;
        }
    },
    deserializeState: ( state ) =>
    {
        try
        {
            return JSON.parse( state );
        } catch ( e )
        {
            return state;
        }
    },
} );

export type AppStore = ReturnType<typeof makeStore>;
export type TRootState = ReturnType<AppStore['getState']>;
export type TAppDispatch = AppStore['dispatch'];
