import { all, call, spawn } from 'redux-saga/effects';

import { processError } from '@/shared/processError';

import accountSagas from './account/sagas';
import authSagas from './auth/sagas';
import companySagas from './company/sagas';
import datastoreSagas from './datastore/sagas';
import internalSagas from './internal/sagas';
import notificationsSagas from './notifications/sagas';
import portfolioSagas from './portfolio/sagas';
import onAttachmentPostSuccess from './_global/attachments/onAttachmentPostSuccess.saga';
import userSagas from './user/sagas';


export default function* rootSaga()
{
    // regular running sagas
    yield spawn( internalSagas );
    yield spawn( notificationsSagas );

    // sagas that may error out, like rtk or async/promise based sagas
    const keepAliveSagas = [
        accountSagas,
        authSagas,
        companySagas,
        datastoreSagas,
        onAttachmentPostSuccess,
        portfolioSagas,
        userSagas,
    ];

    yield all( keepAliveSagas.map( saga =>
          spawn( function* ()
          {
              while ( true )
              {
                  try
                  {
                      yield call( saga );
                      break;
                  } catch ( error )
                  {
                      processError( 'Central Store Sagas', error );
                  }
              }
          } ) )
    );
}

/*
// @ts-expect-error
if ( process.env.NODE_ENV !== 'production' && module.hot )
{
    // @ts-expect-error
    module.hot.accept();
}*/
