import { call, put, takeEvery } from 'redux-saga/effects';

import { authService } from '@/store/auth';
import { clearAuthState, doLogout, doLogoutClearState, doLogoutError, doLogoutSuccess, } from '../actions';
import { HTTPErrorOccurred, setRequestState } from '@/store/internal/actions';

import handleError from './handleError';
import { processError } from '@/shared/processError';
import { routerNavigate } from '@/components/structural/Link';


function* logoutScript( { type }: ReturnType<typeof doLogout> )
{
    try
    {
        yield put( setRequestState( { id: type, loading: true } ) );
        yield call( authService.signOut );

        yield put( doLogoutClearState() );
        yield put( doLogoutSuccess() );
    } catch ( err )
    {
        const error = handleError( err );

        processError( 'onLogout saga', error );

        yield put( setRequestState( { id: type, error, loading: false } ) );
        yield put( HTTPErrorOccurred( error ) );
        yield put( doLogoutError( error ) );
    }
}

function* logoutSuccessScript()
{
    yield routerNavigate( '/auth/login' );
}

function* logoutClearStateScript()
{
    yield put( clearAuthState() );
}

export function* onLogoutSaga()
{
    yield takeEvery( doLogout, logoutScript );
    yield takeEvery( doLogoutClearState, logoutClearStateScript );
    yield takeEvery( doLogoutSuccess, logoutSuccessScript );
}