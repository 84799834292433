import App, { type AppProps } from 'next/app';
import { type AppInitialProps } from 'next/dist/shared/lib/utils';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { type FC, Suspense, useEffect, useState } from 'react';
import { END } from 'redux-saga';
import NextNProgress from 'nextjs-progressbar';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import { type PersistorStore, type SagaStore, storeWrapper } from '@/store';

import { ConfigProvider, LoaderFullscreen } from '@/components/structural';

import { AppLayoutAuthenticated, AppLayoutGuest } from '@/features/layout';

import { processError } from '@/shared/processError';
import { SentryErrorBoundary } from '@/components/SentryErrorBoundary';

import configureLibs from '@/_libs-config/configureLibs';
import setupPolyfills from '@/_libs-config/setupPolyfills';
import setupAxiosInterceptors from '@/_libs-config/setupAxiosInterceptors';

import twConfig from '@/shared/tailwindConfig';

import '@/styles/globals.css';
import { usePageLoaded, usePageTitle } from '@/features/layout/hooks';


if ( typeof window !== 'undefined' )
{
    setupPolyfills();

    if ( process.env.NODE_ENV === 'development' )
    {
        const reportWebVitals = require( '@/_libs-config/reportWebVitals' );
        reportWebVitals.default( ( message: string, metric: string ) =>
        {
            console.warn( message, metric );
        }, false );
    }
}

const appLayoutPropsRouteMap = {
    '/my-company/onboard': {
        hideBreadcrumbs: true,
        hideHeader: true,
        hideTopViewToggle: true,
    },
    '/portfolio/projections': {
        showPageViewSelectorMap: true,
    }
};

const ORApp: FC<AppProps> = ( { Component, ...rest } ) =>
{
    const router = useRouter();
    const { store, props } = storeWrapper.useWrappedStore( rest );
    const [ isAuthenticated, setIsAuthenticated ] = useState(
          router.pathname !== '/' && !router.pathname.startsWith( '/auth' ) ||
          !!store.getState()?.auth?.awsUser
    );
    const { pageTitle } = usePageTitle();
    const { pageLoaded, setPageLoaded } = usePageLoaded();
    const [ showLoader, setShowLoader ] = useState( true );

    const theme = {
        primaryColor: twConfig.theme.colors.primary.DEFAULT,
        errorColor: twConfig.theme.colors.danger.DEFAULT,
        warningColor: twConfig.theme.colors.warning.DEFAULT,
        successColor: twConfig.theme.colors.success.DEFAULT,
        infoColor: twConfig.theme.colors.info.DEFAULT,
    };

    useEffect( () =>
    {
        configureLibs();
        ConfigProvider.config( { theme } );

        let previousIsAuthenticated = false;

        return store.subscribe( () =>
        {
            const { auth: { awsUser } } = store.getState();
            const newIsAuthenticated = !!awsUser;

            if ( previousIsAuthenticated !== newIsAuthenticated )
            {
                setPageLoaded( () => false );
                previousIsAuthenticated = newIsAuthenticated;

                setTimeout( () =>
                {
                    setIsAuthenticated( () => newIsAuthenticated );
                    setPageLoaded( () => true );
                }, 1000 );
            }
        } );
    }, [ store ] );

    useEffect( () =>
    {
        setupAxiosInterceptors( store.dispatch );
    }, [ store.dispatch ] );

    useEffect( () =>
    {
        if ( pageLoaded )
        {
            setTimeout( () => setShowLoader( () => false ), 2500 );
        } else
        {
            setShowLoader( () => true );
        }
    }, [ pageLoaded ] );

    const currentPagePropsMap = appLayoutPropsRouteMap[ router.pathname ] || {};

    return (
          <>
              <Head>
                  <title>{ `OneRegistry React - ${ pageTitle }` }</title>
              </Head>
              <Provider store={ store }>
                  <PersistGate persistor={ ( store as PersistorStore ).__persistor }>
                      <ConfigProvider>
                          <NextNProgress height={ 3 } color={ theme.primaryColor } options={ { showSpinner: false } }/>
                          <SentryErrorBoundary>
                              <Suspense fallback={ <LoaderFullscreen/> }>
                                  { router.pathname === '/landing' ? (
                                        <Component { ...props.pageProps } />
                                  ) : (
                                        isAuthenticated ? (
                                              <AppLayoutAuthenticated
                                                    hideBreadcrumbs={ currentPagePropsMap.hideBreadcrumbs }
                                                    showPageViewSelector={ currentPagePropsMap.showPageViewSelector }
                                                    hideHeader={ currentPagePropsMap.hideHeader }
                                                    hideTopViewToggle={ currentPagePropsMap.hideTopViewToggle }
                                              >
                                                  <Component { ...props.pageProps } />
                                              </AppLayoutAuthenticated>
                                        ) : (
                                              <AppLayoutGuest>
                                                  <Component { ...props.pageProps } />
                                              </AppLayoutGuest>
                                        )
                                  ) }
                              </Suspense>
                              <LoaderFullscreen isVisible={ showLoader }/>
                          </SentryErrorBoundary>
                      </ConfigProvider>
                  </PersistGate>
              </Provider>
          </>
    );
};

// @ts-expect-error
ORApp.getInitialProps = storeWrapper.getInitialAppProps( ( store: SagaStore ) => async ( context ) =>
{
    let appPageProps: AppInitialProps = { pageProps: {} };

    try
    {
        appPageProps = ( await App.getInitialProps( context ) ).pageProps;
    } catch ( e )
    {
        processError( 'ORApp.getInitialProps appPageProps error: ', e );
    }

    // 1. Wait for all page actions to dispatch
    const pageProps = appPageProps;

    try
    {
        // 2. Stop the saga if on server
        if ( context.ctx.req )
        {
            store.dispatch( END );
            await store.sagaTask.toPromise();
        }
    } catch ( e )
    {
        processError( 'ORApp.getInitialProps stop sagaTask error: ', e );
    }

    // 3. Return props
    return { pageProps };
} );

export default ORApp;
