import { useMemo } from 'react';

import { getMetaDefaultObject } from '@/store/_rtk-query/getMetaDefaultObject';
import { useCurrentCompany } from '@/store/company/hooks/useCurrentCompany.hook';

import { filingCompanyRegisteredAddressAdapter, useGetManyFilingsCompanyRegisteredAddressQuery } from '@/store/company';
import { filingCompanyRegisteredAddressSelectors } from '@/store/company/selectors';

import { compare } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORGetParameters } from '@/store/_models/get-parameters.interface';


export const useFilingCompanyRegisteredAddresses = ( getParameters: IORGetParameters = { page: 1, per_page: DEFAULT_PER_PAGE }, skip = false ) =>
{
    const { orCompany } = useCurrentCompany();
    const { data, isFetching, isLoading, error } = useGetManyFilingsCompanyRegisteredAddressQuery( getParameters, {
        skip: skip || !orCompany
    } );

    const filingCompanyRegisteredAddresses = filingCompanyRegisteredAddressSelectors.selectAll(
          data ||
          filingCompanyRegisteredAddressAdapter.getInitialState()
    );

    filingCompanyRegisteredAddresses.sort( ( a, b ) => compare( a.updated_at, b.updated_at, true ) );

    const meta = data?.meta || getMetaDefaultObject();

    return useMemo( () => ( {
        filingCompanyRegisteredAddresses,
        meta,
        isLoading,
        isFetching,
        error
    } ), [ data, isFetching, isLoading, error ] );
};