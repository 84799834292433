import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { compare, getCookie } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompanyTransaction } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const transactionAdapter = createEntityAdapter<IORCompanyTransaction>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at ),
    selectId: ( e ) => e.uuid,
} );

export const transactionApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneTransaction: builder.query<IORCompanyTransaction, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/transaction/${ uuid }`, method: 'get'
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'Transaction' )
            ),
        } ),
        getManyTransactions: builder.query<EntityState<IORCompanyTransaction> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/transaction/`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'Transactions', 'Transaction' )
            ),
            transformResponse: ( data: IORCompanyTransaction[], meta: IORResponseMetaData ) => ( {
                ...transactionAdapter.upsertMany( transactionAdapter.getInitialState(), data ),
                ...{ meta }
            } )
        } ),
    } ),
} );

export const {
    useGetOneTransactionQuery,
    useLazyGetOneTransactionQuery,
    useGetManyTransactionsQuery,
    useLazyGetManyTransactionsQuery,
    endpoints: transactionApiEndpoints,
} = transactionApi;