import { type FC, memo } from 'react';

import { DataFormat, FieldContainerCols, FieldEditText, FieldsWrapperFlex, FieldsWrapperGrid, FieldTitleCenter, FieldValue, FieldValueDefault, Pill } from '@/components/structural';
import type { IFormStepProps } from '@/components/structural/form';

import type { ORJSON } from '@/shared/models';

import type { IStep1Data } from './Step1';
import type { IStep2Data } from './Step2';


export interface IStep3Data extends ORJSON
{
    step1: IStep1Data,
    step2: IStep2Data,
}

export const Step3: FC<IFormStepProps<IStep3Data>> = memo( ( { initialData: data, goToStep } ) =>
{
    return <>
        <div className="mb-4 font-roboto">
            Please check the details below carefully before submitting request to Companies House.
        </div>
        <FieldsWrapperFlex>
            <FieldContainerCols>
                <FieldValueDefault className="font-roboto">
                    SAIL Address: <FieldEditText onClick={ () => goToStep( 0 ) } className="ml-8 inline-block"/>
                </FieldValueDefault>
            </FieldContainerCols>
        </FieldsWrapperFlex>
        <div className="grid grid-cols-1 place-items-center w-full mb-4">
            <FieldsWrapperGrid>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        Building Name/Number
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={ data.step1.address_line_1 } className="text-inherit font-medium" inline/>
                    </FieldValue>
                </FieldContainerCols>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        Street
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={ data.step1.address_line_2 } className="text-inherit font-medium" inline/>
                    </FieldValue>
                </FieldContainerCols>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        Post Town
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={ data.step1.locality } className="text-inherit font-medium" inline/>
                    </FieldValue>
                </FieldContainerCols>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        County/Region
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={ data.step1.region } className="text-inherit font-medium" inline/>
                    </FieldValue>
                </FieldContainerCols>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        Postcode
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={ data.step1.postal_code } className="text-inherit font-medium" inline/>
                    </FieldValue>
                </FieldContainerCols>
            </FieldsWrapperGrid>
        </div>
        <div>
            <FieldsWrapperFlex>
                <FieldContainerCols>
                    <FieldValueDefault className="font-roboto">
                        Records now kept at SAIL: <FieldEditText onClick={ () => goToStep( 1 ) } className="ml-8 inline-block"/>
                    </FieldValueDefault>
                </FieldContainerCols>
            </FieldsWrapperFlex>
            <div className="flex flex-wrap mb-2">
                { data.step2.records_and_locations.map( ( item ) => (
                      <Pill key={ item }
                            text={ item }
                            variant="success"
                            className={ `mr-2 mb-2 text-xs` }
                      />
                ) ) }
            </div>
        </div>
    </>;
} );

Step3.displayName = 'CompanyAddSAILAddressStep3';