import { capitaliseFirstLetter, normaliseString } from '@/shared/tools';


export const parseOfficerName = ( name: string ) =>
{
    if ( !name )
    {
        return { first: '', last: '' };
    }

    const [ last, first ] = name.split( ',' ).map( item =>
          item.split( ' ' )
                .map( word => word ?
                      capitaliseFirstLetter( normaliseString( word ) ) :
                      ''
                )
                .join( ' ' )
    );

    return { first, last };
};