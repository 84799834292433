import { type FC, memo, useCallback, useMemo, useState } from 'react';

import { authService } from '@/store/auth';
import { doSignup } from '@/store/auth/actions';
import { useAppDispatch } from '@/store/hooks';

import { AuthPageFormButton, PrivacyPolicy, TermsAndConditions } from '@/features/auth/components';
import { Checkbox, Form, FormItem, Input, InputPassword } from '@/components/structural/form';
import { Modal, Typography } from '@/components/structural';
import { SVGIcon } from '@/components/structural/images';

import { EMAIL_VALIDATION_REGEX } from '@/shared/constants';

import checkIcon from '@/icons/check.svg';
import crossIcon from '@/icons/x.svg';
import lockIcon from '@/icons/lock-fill.svg';


export const AuthSignupForm: FC = memo( () =>
{
    const dispatchAction = useAppDispatch();

    const [ isTCModalVisible, setIsTCModalVisible ] = useState( false );
    const [ isPPModalVisible, setIsPPModalVisible ] = useState( false );
    const [ password, setPassword ] = useState( '' );

    const yesIcon = useMemo( () => <SVGIcon src={ checkIcon.src } className="!w-6 !h-6 text-success dark:text-success-dark"/>, [] );
    const noIcon = useMemo( () => <SVGIcon src={ crossIcon.src } className="!w-6 !h-6 text-danger dark:text-danger-dark"/>, [] );

    const process = useCallback( ( values: {
        first_name: string,
        last_name: string,
        email: string,
        password: string,
        agreement: boolean,
        beta_warning: boolean,
    } ) =>
    {
        dispatchAction( doSignup( {
            email: values.email.toLocaleLowerCase().trim(),
            password: values.password,
            first_name: values.first_name.trim(),
            last_name: values.last_name.trim(),
        } ) );
    }, [ dispatchAction, doSignup ] );

    const passwordContainsLowerCase = () => /(.*[a-z].*)/.test( password );
    const passwordContainsUpperCase = () => /(.*[A-Z].*)/.test( password );
    const passwordContainsNumbers = () => /(.*\d.*)/.test( password );
    const passwordContainsSymbols = () => /(.*\W.*)/.test( password );
    const passwordContains8to16Chars = () => /^.{8,16}$/.test( password );

    const closeTCModal = useCallback( () => setIsTCModalVisible( false ), [] );
    const closePPModal = useCallback( () => setIsPPModalVisible( false ), [] );

    return ( <>
        <Form
              name="auth"
              layout="vertical"
              requiredMark={ false }
              onFinish={ process }
              validateTrigger="onSubmit"
        >
            <FormItem
                  label="First Name"
                  name="first_name"
                  className="mb-4"
                  rules={ [ { required: true, message: 'Please provide your first name!', type: 'string' } ] }
            >
                <Input/>
            </FormItem>

            <FormItem
                  label="Last Name"
                  name="last_name"
                  className="mb-4"
                  rules={ [ { required: true, message: 'Please provide your last name!', type: 'string' } ] }
            >
                <Input/>
            </FormItem>

            <FormItem
                  label="Email"
                  name="email"
                  className="mb-4"
                  rules={ [ {
                      required: true, validator: async ( _, value ) =>
                      {
                          if ( !EMAIL_VALIDATION_REGEX.test( value ) )
                              throw new Error( 'Please provide a valid email address!' );

                          if ( await authService.userExist( { email: value } ) )
                              throw new Error( 'This email is already taken, please choose a different one!' );
                      },
                  } ] }
            >
                <Input/>
            </FormItem>

            <FormItem
                  label="Password"
                  name="password"
                  className="mb-2"
                  rules={ [ {
                      required: true, validator: async () =>
                      {
                          if ( !passwordContainsLowerCase() ||
                                !passwordContainsUpperCase() ||
                                !passwordContainsNumbers() ||
                                !passwordContainsSymbols() ||
                                !passwordContains8to16Chars() )
                              throw new Error( 'Please provide a valid password!' );
                      }
                  } ] }
            >
                <InputPassword
                      prefix={ <SVGIcon { ...lockIcon }/> }
                      onChange={ ( { target: { value } } ) => setPassword( () => value ) }
                />
            </FormItem>

            <Typography.Paragraph className="text-xs text-default dark:text-default-dark font-roboto grid grid-rows-2 mb-4">
                <div>
                    Your password must contain: <br/>
                </div>
                <div className="flex content-center">
                    { passwordContainsLowerCase() ? yesIcon : noIcon }&nbsp;Lower case&nbsp;
                    { passwordContainsUpperCase() ? yesIcon : noIcon }&nbsp;Upper case&nbsp;
                    { passwordContainsNumbers() ? yesIcon : noIcon }&nbsp;Numbers&nbsp;
                    { passwordContainsSymbols() ? yesIcon : noIcon }&nbsp;Symbols&nbsp;
                    { passwordContains8to16Chars() ? yesIcon : noIcon }&nbsp;8-16 characters
                </div>
            </Typography.Paragraph>

            <FormItem
                  name="agreement"
                  valuePropName="checked"
                  className="mb-4"
                  rules={ [ {
                      validator: ( _, value ) =>
                            value ? Promise.resolve() : Promise.reject( new Error( 'You must agree in order to use this service!' ) ),
                  } ] }
            >
                <div className="flex items-start">
                    <Checkbox/>
                    <div className="ml-4">
                        By creating an account you agree to the&nbsp;
                        <a className="underline" onClick={ () => setIsTCModalVisible( true ) }>terms and conditions</a> and&nbsp;
                        <a className="underline" onClick={ () => setIsPPModalVisible( true ) }>privacy policy</a>.
                    </div>
                </div>
            </FormItem>

            <FormItem
                  name="beta_warning"
                  valuePropName="checked"
                  className="mb-4"
                  rules={ [ {
                      validator: ( _, value ) =>
                            value ? Promise.resolve() : Promise.reject( new Error( 'You must agree in order to use this service!' ) ),
                  } ] }
            >
                <div className="flex items-start">
                    <Checkbox/>
                    <div className="ml-4">
                        I understand that OneRegistry is currently in beta and by signing up
                        I will be testing an early version of the platform.
                    </div>
                </div>
            </FormItem>

            <FormItem wrapperCol={ { span: 24 } }>
                <AuthPageFormButton>
                    SIGN UP
                </AuthPageFormButton>
            </FormItem>
        </Form>

        <Modal title="Terms and Conditions"
               open={ isTCModalVisible }
               onOk={ closeTCModal }
               onCancel={ closeTCModal }
               okButtonProps={ { style: { display: 'none' } } }
               cancelText="Close"
        >
            <TermsAndConditions/>
        </Modal>

        <Modal title="Privacy Policy"
               open={ isPPModalVisible }
               onOk={ closePPModal }
               onCancel={ closePPModal }
               okButtonProps={ { style: { display: 'none' } } }
               cancelText="Close"
        >
            <PrivacyPolicy/>
        </Modal>
    </> );
} );

AuthSignupForm.displayName = 'AuthSignupForm';