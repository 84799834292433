import type { TRootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';


export const selectActionsState = ( state: TRootState ) => state.actions;

export const selectActions = createSelector(
      [ selectActionsState ],
      ( state ) => state.actions
);
export const selectLastAction = createSelector(
      [ selectActionsState ],
      ( state ) => state.lastAction
);