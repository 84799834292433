import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { compare } from '@/shared/tools';
import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompanyResponsibleParty, IORCompanyResponsiblePartyMainAttributes } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const responsiblePartyAdapter = createEntityAdapter<IORCompanyResponsibleParty>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at ),
    selectId: ( e ) => e.uuid,
} );

export const responsiblePartyApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneResponsibleParty: builder.query<IORCompanyResponsibleParty, string>( {
            query: ( uuid ) => ( {
                url: `/temp/company-responsible-party/${ uuid }`,
                overrideUrl: true,
                method: 'get',
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'ResponsibleParty' )
            )
        } ),
        getManyResponsibleParties: builder.query<EntityState<IORCompanyResponsibleParty> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/temp/company-responsible-party/`,
                overrideUrl: true,
                method: 'get',
                queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                },
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'ResponsibleParties', 'ResponsibleParty' )
            ),
            transformResponse: ( data: IORCompanyResponsibleParty[], meta: IORResponseMetaData ) => ( {
                ...responsiblePartyAdapter.upsertMany( responsiblePartyAdapter.getInitialState(), data ),
                ...{ meta },
            } ),
        } ),
        patchResponsibleParty: builder.mutation<IORCompanyResponsibleParty, { uuid: string, data: Partial<IORCompanyResponsiblePartyMainAttributes> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/temp/company-responsible-party/${ uuid }`,
                overrideUrl: true,
                method: 'patch',
                data,
            } ),
            invalidatesTags: ( _, __, { uuid } ) => ( [
                ...providesIds( [ uuid ], 'ResponsibleParty' ),
                ...providesList( [], 'ResponsibleParties' ),
            ] ),
        } ),
        postResponsibleParty: builder.mutation<IORCompanyResponsibleParty, Partial<IORCompanyResponsiblePartyMainAttributes>>( {
            query: ( data ) => ( {
                url: `/temp/company-responsible-party/`,
                overrideUrl: true,
                method: 'post',
                data,
            } ),
            invalidatesTags: () => (
                  providesList( [], 'ResponsibleParties' )
            ),
        } ),
        deleteResponsibleParty: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/temp/company-responsible-party/${ uuid }`,
                overrideUrl: true,
                method: 'delete',
            } ),
            invalidatesTags: ( _, __, uuid ) => (
                  providesList( [ uuid ], 'ResponsibleParties', 'ResponsibleParty' )
            ),
        } ),
    } ),
} );

export const {
    useGetOneResponsiblePartyQuery,
    useLazyGetOneResponsiblePartyQuery,
    useGetManyResponsiblePartiesQuery,
    usePatchResponsiblePartyMutation,
    usePostResponsiblePartyMutation,
    useDeleteResponsiblePartyMutation,
    endpoints: responsiblePartyApiEndpoints,
} = responsiblePartyApi;