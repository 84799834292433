import { type FC, memo, type ReactNode, useState } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/query';
import useUpdateEffect from 'ahooks/lib/useUpdateEffect';

import { useAccounts } from '@/store/account/hooks/useAccounts.hook';
import { useHTTPRequest } from '@/store/internal/hooks';
import { usePostCompanyMutation } from '@/store/company';

import { Button, Loader, Modal } from '@/components/structural';

import { CHCompanyLookup, type ICHCompanyLookupProps } from '@/features/company/components/CHCompanyLookup';

import type { ICHCompanyProfile, ICHCompanySearchResultItem, IORCompany } from '@/features/company/shared/models';


export interface ICompanyAddFormProps
{
    standalone?: boolean,
    onAdd?: ( newCompany: IORCompany ) => void
    onSelect?: ICHCompanyLookupProps['onSelect'],
    guidanceText?: ReactNode,
    hideMainButton?: boolean,
    selectedCompany?: ICHCompanySearchResultItem | ICHCompanyProfile,
}

export const CompanyAddForm: FC<ICompanyAddFormProps> = memo( ( {
    standalone = false,
    onAdd = () => null,
    onSelect = () => null,
    guidanceText,
    hideMainButton = false,
    selectedCompany,
} ) =>
{
    const [ postCompany, { status: postStatus, data } ] = usePostCompanyMutation();

    const { httpRequest: { loading: isLoading } } = useHTTPRequest();
    const { currentAccount } = useAccounts();

    const [ selectedCompanyInternal, setSelectedCompanyInternal ] =
          useState<ICHCompanySearchResultItem | ICHCompanyProfile>( selectedCompany );

    const onSelectCombined = ( value: ICHCompanySearchResultItem | ICHCompanyProfile ) =>
    {
        setSelectedCompanyInternal( value );
        onSelect( value );
    };

    const handleAddCompany = () =>
    {
        postCompany( {
            company_number: selectedCompanyInternal.company_number,
            owner_account: currentAccount.uuid/*`/account/${ currentAccount.uuid }`*/,
        } );
    };

    useUpdateEffect( () =>
    {
        if ( postStatus === QueryStatus.fulfilled )
        {
            !standalone &&
            Modal.destroyAll();

            onAdd( data );
        }
    }, [ postStatus ] );

    return ( <>
        <CHCompanyLookup onSelect={ onSelectCombined } guidanceText={ guidanceText } selectedItemInput={ selectedCompanyInternal }/>
        <div className="grid place-items-center mt-4">
            { isLoading ?
                  <Loader/>
                  :
                  !hideMainButton && <Button
                        type="success"
                        disabled={ !selectedCompanyInternal }
                        onClick={ handleAddCompany }
                  >
                      ADD AND CONTINUE
                  </Button>
            }
        </div>
    </> );
} );

CompanyAddForm.displayName = 'CompanyAddForm';