import { type FC, memo } from 'react';

import { Typography, UploadZone } from '@/components/structural';
import { Form, Input, InputTextArea, VisibleFormItemFullWidth } from '@/components/structural/form';

import type { ICompanyOnboardData } from './company-onboard-data.interface';


export interface IStep4Props
{
    className?: string,
    data?: {
        company?: ICompanyOnboardData['company'],
        extra?: ICompanyOnboardData['extra']
    },
    onChange: ( key: keyof IStep4Props['data']['extra'] ) => ( value: IStep4Props['data']['extra'][typeof key] ) => void
}

export const Step4: FC<IStep4Props> = memo( ( props ) =>
{
    return ( <>
        <div className={ `${ props.className }` }>
            { props.data?.company && <Typography.Title level={ 5 }>
                { 'company_name' in props.data.company ? props.data.company.company_name : props.data.company.title } Profile
            </Typography.Title> }
            <Typography.Paragraph className="font-roboto">
                You can also add/edit these details in Company Settings.
            </Typography.Paragraph>
            <Form colon={ false }>
                <VisibleFormItemFullWidth
                      label="Website"
                      className=""
                >
                    <Input
                          placeholder="https://"
                          value={ props.data.extra?.company_website }
                          onChange={ ( { target: { value } } ) => props.onChange( 'company_website' )( value ) }
                    />
                </VisibleFormItemFullWidth>

                <VisibleFormItemFullWidth
                      label={ <span>Company Profile <span className="ml-2 text-default-alt dark:text-default-dark font-roboto">OPTIONAL</span></span> }
                      className=""
                >
                    <InputTextArea
                          placeholder=""
                          className="w-full"
                          value={ props.data.extra?.company_profile }
                          onChange={ ( { target: { value } } ) => props.onChange( 'company_profile' )( value ) }
                          rows={ 4 }
                    />
                </VisibleFormItemFullWidth>

                <VisibleFormItemFullWidth
                      label={ <span>Company Logo <span className="ml-2 text-default-alt dark:text-default-dark font-roboto">OPTIONAL</span></span> }
                      className=""
                >
                    <UploadZone
                          onFilesDrop={ ( acceptedFiles ) => props.onChange( 'company_logo' )( acceptedFiles[ 0 ] ) }
                          existingFiles={ props.data.extra?.company_logo ? [ props.data.extra?.company_logo ] : [] }/>
                </VisibleFormItemFullWidth>
            </Form>
        </div>
    </> );
} );

Step4.displayName = 'CompanyOnboardStep4';