import axios, { type AxiosError } from 'axios';
import axiosRetry from 'axios-retry';

import { handleHTTPResponseError, stringContainsUUID } from '@/shared/tools';
import { processError } from '@/shared/processError';
import { setRequestState } from '@/store/internal/actions';
import { type TAppDispatch } from '@/store';


const interceptorIDs = {
    response: -1,
    request: -1
};

export default function setupAxiosInterceptors( dispatch: TAppDispatch )
{
    let numberOfAjaxCAllPending = 0;

    // Add a request interceptor
    axios.interceptors.request.eject( interceptorIDs.request );
    interceptorIDs.request = axios.interceptors.request.use( config =>
    {
        numberOfAjaxCAllPending++;

        dispatch( setRequestState( { id: 'httpRequest', loading: true } ) );

        return config;
    }, error =>
    {
        return Promise.reject( error );
    } );

    // Add a response interceptor
    axios.interceptors.response.eject( interceptorIDs.response );
    interceptorIDs.response = axios.interceptors.response.use( response =>
    {
        numberOfAjaxCAllPending--;

        if ( numberOfAjaxCAllPending === 0 )
        {
            dispatch( setRequestState( { id: 'httpRequest', loading: false } ) );
        }

        return response;
    }, ( error: AxiosError ) =>
    {
        numberOfAjaxCAllPending--;

        if ( numberOfAjaxCAllPending === 0 )
        {
            dispatch( setRequestState( {
                id: 'httpRequest', loading: false, error: {
                    code: error?.code,
                    name: error?.name,
                    message: error?.response?.data[ 'error' ] ? handleHTTPResponseError( error?.response?.data[ 'error' ] ) : error?.message
                }
            } ) );
        }

        return Promise.reject( error );
    } );

    axiosRetry( axios, {
        retries: 5,
        shouldResetTimeout: true,
        retryCondition: ( error: AxiosError ) =>
        {
            if ( error?.response?.status === 401 ||
                  // this case usually happens when the company is changed and the user tries to access a resource that is not available for the new company
                  stringContainsUUID( error?.config?.url ) && error?.response?.status === 404 )
            {
                // processError( 'Axios Error Retry: not retrying.' );
                return false;
            }

            processError( 'Axios Error Retry', error );
            // processError( 'Axios Error Retry: Checking if a retry is needed... Result: ', error?.code === 'ECONNABORTED' );

            return error?.code === 'ECONNABORTED';
        },
        retryDelay: ( retryCount, error ) =>
        {
            if ( error?.response?.status === 401 )
            {
                return 99999999999999;
            }
            return 250;
        }
    } );
}