import type { ORJSON } from '@/shared/models';


export const serverDataCache: { cookies: ORJSON<string> } = {
    cookies: {},
};

// https://www.tabnine.com/academy/javascript/how-to-set-cookies-javascript/
export const setCookie = ( {
    cName,
    cValue,
    expDays = 365,
    returnValue = false,
    to = ( typeof window === 'undefined' ? serverDataCache : document ),
}: { cName: string, cValue: string, expDays?: number, returnValue?: boolean, to?: { cookie: string } | typeof serverDataCache } ) =>
{
    const date = new Date();
    date.setTime( date.getTime() + ( expDays * 24 * 60 * 60 * 1000 ) );
    const expires = 'expires=' + date.toUTCString();
    const domain = process.env.NODE_ENV !== 'production' ? `${ process.env.NEXT_PUBLIC_DEV_HOST }; samesite=lax` : `${ process.env.NEXT_PUBLIC_OR_TYPE }.oneregistry.co.uk; secure; samesite`;
    const cookie = `${ cName }=${ cValue }; ${ expires }; path=/; domain=${ domain }`;

    if ( returnValue ) return cookie;

    if ( 'cookies' in to )
    {
        to.cookies[ cName ] = cValue;
        return cookie;
    }

    return ( to.cookie = cookie );
};

// https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
export const getCookie = ( { cName, from = ( typeof window === 'undefined' ? serverDataCache : document ) }: { cName: string, from?: { cookie: string } | typeof serverDataCache } ) =>
{
    if ( 'cookies' in from )
    {
        return from.cookies[ cName ];
    }

    const cookies = decodeURIComponent( from.cookie || '' ).split( '; ' );
    const cookieRaw = cookies.find( item => item.startsWith( `${ cName }=` ) );
    const cookie = cookieRaw?.split( '=' )[ 1 ] || null;

    return cookie;
};

export const removeCookies = ( ...cNames: string[] ) =>
{
    cNames.forEach( cName =>
    {
        setCookie( {
            cName,
            cValue: '',
            expDays: -1,
        } );

        if ( cName in serverDataCache.cookies )
        {
            delete serverDataCache.cookies[ cName ];
        }
    } );
};

const cookies = {
    setCookie, getCookie, removeCookies,
};

export default cookies;