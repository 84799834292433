import { useMemo } from 'react';

import { useCurrentCompany } from '@/store/company/hooks/useCurrentCompany.hook';

import { useGetFundamentalsQuery } from '@/store/company';

import { compare } from '@/shared/tools';


export const useFundamentals = ( skip = false ) =>
{
    const { orCompany } = useCurrentCompany();
    const { data, isFetching, isLoading, error } = useGetFundamentalsQuery( undefined, {
        skip: skip || !orCompany
    } );

    const fundamentals = [ ...( data || [] ) ];
    fundamentals.sort( ( a, b ) => compare( a.from_date, b.from_date, true ) );

    return useMemo( () => ( {
        fundamentals,
        isLoading,
        isFetching,
        error
    } ), [ data, isFetching, isLoading, error ] );
};