import { type FC, memo, type PropsWithChildren } from 'react';

import { selectMostRecentRequest } from '@/store/internal/selectors';
import { useAppSelector } from '@/store/hooks';

import { Button, type ORButtonType } from '@/components/structural';


export interface IAuthPageFormButtonProps
{
    onClick?: () => void,
    buttonType?: ORButtonType,
    className?: string
}

export const AuthPageFormButton: FC<PropsWithChildren<IAuthPageFormButtonProps>> = memo( props =>
{
    const { loading } = useAppSelector( selectMostRecentRequest );

    return <Button
          type={ props.buttonType || 'success' }
          htmlType={ props.onClick ? 'button' : 'submit' }
          className={ `w-full h-12 ${ props.className || '' }` }
          loading={ loading }
          onClick={ props.onClick }
    >
        { props.children }
    </Button>;
} );

AuthPageFormButton.displayName = 'AuthPageFormButton';