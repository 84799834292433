import { useMemo } from 'react';

import { useCompanies } from '@/store/company/hooks/useCompanies.hook';
import { useCurrentUser } from '@/store/user/hooks';


export const useCurrentCompany = () =>
{
    const { settings } = useCurrentUser( {} );
    const { companies, isLoading, isFetching, error } = useCompanies();

    const orCompany = settings && companies.find( c => c.uuid === settings?.currentCompanyUuid ) || null;

    const chProfile = orCompany?.ch_data.chProfile;
    const chOfficers = orCompany?.ch_data.chOfficers;
    const chPSCs = orCompany?.ch_data.chPersonsWithSignificantControl;
    const chFilings = orCompany?.ch_data.chFilingHistories;
    const chCharges = orCompany?.ch_data.chCharges;
    const chExemptions = orCompany?.ch_data.chExemptions;
    const chLastUpdate = orCompany?.ch_data.last_update;
    const chOrUuid = orCompany?.ch_data.uuid;
    const orCompanySettings = orCompany?.json_settings;

    return useMemo( () => ( {
        orCompany,
        orCompanySettings,
        chProfile,
        chOfficers,
        chPSCs,
        chFilings,
        chCharges,
        chExemptions,
        chLastUpdate,
        chOrUuid,
        isLoading,
        isFetching,
        error
    } ), [ orCompany, isLoading, isFetching, error ] );
};