import { type FC, memo, useMemo, useRef, useState } from 'react';

import useDebounceFn from 'ahooks/lib/useDebounceFn';

import {
    companyApiEndpoints,
    shareClassApiEndpoints,
    shareRoundApiEndpoints,
    stakeholderApiEndpoints,
    transactionApiEndpoints,
    useLazyGetManyCompaniesQuery,
    useLazyGetManyShareClassesQuery,
    useLazyGetManyShareRoundsQuery,
    useLazyGetManyStakeholdersQuery,
    useLazyGetManyTransactionsQuery
} from '@/store/company';
import { doPatchCurrentUser } from '@/store/user/actions';
import { useAppDispatch } from '@/store/hooks';
import { useShareholders } from '@/store/company/hooks';

import { routerNavigate, TitleWithIcon } from '@/components/structural';
import { Option, type RefSelectProps, Select, type SelectProps } from '@/components/structural/form';
import { searchFilterOption, searchFilterSort } from '@/components/tables';


interface ISearchBoxProps
{
    className?: string;
}

export const SearchBox: FC<ISearchBoxProps> = memo( ( { className } ) =>
{
    const dispatch = useAppDispatch();
    const [ searchValue, setSearchValue ] = useState( '' );

    const queryCompanies = { query: searchValue };
    const { data: companiesRaw } = companyApiEndpoints.getManyCompanies.useQueryState( queryCompanies );
    const [ fetchCompanies, { isFetching: companiesAreFetching } ] = useLazyGetManyCompaniesQuery();
    const companies = useMemo( () =>
          companiesRaw?.ids.map( uuid => companiesRaw.entities[ uuid ] ) ||
          [], [ companiesRaw ] );

    const queryStakeholders = { query: [ { first_name: searchValue }, { last_name: searchValue } ] };
    const { data: stakeholdersRaw } = stakeholderApiEndpoints.getManyStakeholders.useQueryState( queryStakeholders );
    const [ fetchStakeholders, { isFetching: stakeholdersAreFetching } ] = useLazyGetManyStakeholdersQuery();
    const stakeholders = useMemo( () =>
          stakeholdersRaw?.ids.map( uuid => stakeholdersRaw.entities[ uuid ] ) ||
          [], [ stakeholdersRaw ] );

    const queryShareRounds = { query: searchValue };
    const { data: shareRoundsRaw } = shareRoundApiEndpoints.getManyShareRounds.useQueryState( queryShareRounds );
    const [ fetchShareRounds, { isFetching: shareRoundsAreFetching } ] = useLazyGetManyShareRoundsQuery();
    const shareRounds = useMemo( () =>
          shareRoundsRaw?.ids.map( uuid => shareRoundsRaw.entities[ uuid ] ) ||
          [], [ shareRoundsRaw ] );

    const queryShareClasses = { query: searchValue };
    const { data: shareClassesRaw } = shareClassApiEndpoints.getManyShareClasses.useQueryState( queryShareClasses );
    const [ fetchShareClasses, { isFetching: shareClassesAreFetching } ] = useLazyGetManyShareClassesQuery();
    const shareClasses = useMemo( () =>
          shareClassesRaw?.ids.map( uuid => shareClassesRaw.entities[ uuid ] ) ||
          [], [ shareClassesRaw ] );

    const { shareholders, isFetching: shareholdersAreFetching } = useShareholders();

    /*const queryShareTransfers = { query: searchValue };
    const { data: shareTransfersRaw } = shareTransferApiEndpoints.getManyShareTransfers.useQueryState( queryShareTransfers );
    const [ fetchShareTransfers, { isFetching: shareTransfersAreFetching } ] = useLazyGetManyShareTransfersQuery();
    const shareTransfers = useMemo( () =>
        shareTransfersRaw?.ids.map( uuid => shareTransfersRaw.entities[ uuid ] ) ||
        [], [ shareTransfersRaw ] );*/

    /*const queryShareIssues = { query: searchValue };
    const { data: shareIssuesRaw } = shareIssueApiEndpoints.getManyShareIssues.useQueryState( queryShareIssues );
    const [ fetchShareIssues, { isFetching: shareIssuesAreFetching } ] = useLazyGetManyShareIssuesQuery();
    const shareIssues = useMemo( () =>
        shareIssuesRaw?.ids.map( uuid => shareIssuesRaw.entities[ uuid ] ) ||
        [], [ shareIssuesRaw ] );*/

    const queryTransactions = { query: searchValue };
    const { data: transactionsRaw } = transactionApiEndpoints.getManyTransactions.useQueryState( queryTransactions );
    const [ fetchTransactions, { isFetching: transactionsAreFetching } ] = useLazyGetManyTransactionsQuery();
    const transactions = useMemo( () =>
          transactionsRaw?.ids.map( uuid => transactionsRaw.entities[ uuid ] ) ||
          [], [ transactionsRaw ] );

    const ref = useRef<RefSelectProps>();
    const delayedSearch = useDebounceFn<() => void>(
          () =>
          {
              fetchCompanies( queryCompanies );
              fetchShareRounds( queryShareRounds );
              fetchStakeholders( queryStakeholders );
              fetchShareClasses( queryShareClasses );
              // fetchShareTransfers( queryShareTransfers );
              // fetchShareIssues( queryShareIssues );
              fetchTransactions( queryTransactions );
          },
          {
              wait: 250,
          },
    );

    const handleResult_Company = ( uuid: string ) =>
    {
        dispatch( doPatchCurrentUser( {
            settings: {
                currentCompanyUuid: uuid
            }
        } ) );
        routerNavigate( `/my-company` );
    };

    const handleResult_Stakeholder = ( /*uuid: string*/ ) =>
    {
        routerNavigate( `/share-capital/stakeholders` );
    };

    const handleResult_Shareholder = ( uuid: string ) =>
    {
        routerNavigate( `/share-capital/shareholders/${ uuid }` );
    };

    const handleResult_ShareRound = ( uuid: string ) =>
    {
        routerNavigate( `/share-capital/share-rounds/${ uuid }` );
    };

    const handleResult_ShareClass = ( uuid: string ) =>
    {
        routerNavigate( `/share-capital/share-classes?class=${ uuid }` );
    };

    /*const handleResult_ShareTransfer = ( /!*to_stakeholder_uuid: string*!/ ) =>
    {
        routerNavigate( `/share-capital/share-transfers` );
    };*/

    /*const handleResult_ShareIssue = ( roundUUID: string ) =>
    {
        routerNavigate( `/share-capital/share-rounds/${roundUUID}` );
    };*/

    const handleResult_Transaction = ( uuid: string ) =>
    {
        routerNavigate( `/share-capital/share-transactions?id=${ uuid }` );
    };

    const actionSearch = ( value: string, option: {
        type: 'company' | 'stakeholder' | 'shareholder' | 'shareRound' | 'shareClass' | /*'shareTransfer' |*/ /*'shareIssue' |*/ 'transaction'
    } ) =>
    {
        if ( option.type === 'company' )
        {
            handleResult_Company( value );
        } else if ( option.type === 'stakeholder' )
        {
            handleResult_Stakeholder( /*value*/ );
        } else if ( option.type === 'shareholder' )
        {
            handleResult_Shareholder( value );
        } else if ( option.type === 'shareRound' )
        {
            handleResult_ShareRound( value );
        } else if ( option.type === 'shareClass' )
        {
            handleResult_ShareClass( value );
        } /*else if ( option.type === 'shareTransfer' )
        {
            handleResult_ShareTransfer( /!*value*!/ );
        }*/ /*else if ( option.type === 'shareIssue' )
        {
            handleResult_ShareIssue( value );
        } */ else if ( option.type === 'transaction' )
        {
            handleResult_Transaction( value );
        }

        ref.current.blur();
        setSearchValue( null );
    };

    return ( <>
        <Select ref={ ref }
                size="middle"
                showSearch
                className={ className }
                popupClassName="!min-w-[32rem]"
                placement="bottomRight"
                onSearch={ ( value: string ) =>
                {
                    setSearchValue( value );
                    delayedSearch.cancel();
                    delayedSearch.run();
                } }
                placeholder="Search..."
                onChange={ actionSearch as unknown as SelectProps['onChange'] }
                onBlur={ () => setSearchValue( '' ) }
                filterOption={ searchFilterOption as unknown as SelectProps['filterOption'] }
                filterSort={ searchFilterSort }
                open={ !!searchValue }
                value={ null }
                loading={
                      companiesAreFetching ||
                      shareRoundsAreFetching ||
                      stakeholdersAreFetching ||
                      shareholdersAreFetching ||
                      shareClassesAreFetching ||
                      // shareTransfersAreFetching ||
                      // shareIssuesAreFetching ||
                      transactionsAreFetching
                }
                showArrow={ false }
        >
            { searchValue && companies.map( ( company ) => (
                  <Option value={ company.uuid } key={ company.uuid } filterByValue={ company.name } type="company">
                      Company: <TitleWithIcon title={ company.name } highlight={ searchValue }/>
                  </Option>
            ) ) }
            { searchValue && stakeholders.map( ( stakeholder ) => (
                  <Option value={ stakeholder.uuid } key={ stakeholder.uuid } filterByValue={ stakeholder.entity_name }
                          type="stakeholder">
                      Stakeholder: <TitleWithIcon title={ stakeholder.entity_name } highlight={ searchValue }/>
                  </Option>
            ) ) }
            { searchValue && shareholders.map( ( shareholder ) => (
                  <Option value={ shareholder.uuid } key={ shareholder.uuid + '_shareholder' }
                          filterByValue={ shareholder.entity_name } type="shareholder">
                      Shareholder: <TitleWithIcon title={ shareholder.entity_name } highlight={ searchValue }/>
                  </Option>
            ) ) }
            { searchValue && shareRounds.map( ( shareRound ) => (
                  <Option value={ shareRound.uuid } key={ shareRound.uuid } filterByValue={ shareRound.name } type="shareRound">
                      Round: <TitleWithIcon title={ shareRound.name } highlight={ searchValue }/>
                  </Option>
            ) ) }
            { searchValue && shareClasses.map( ( shareClass ) => (
                  <Option value={ shareClass.uuid } key={ shareClass.uuid } filterByValue={ shareClass.name } type="shareClass">
                      Share Class: <TitleWithIcon title={ shareClass.name } highlight={ searchValue }/>
                  </Option>
            ) ) }
            {/*{ searchValue && shareTransfers.map( ( transfer ) => {
                // only show transfers between shareholders
                if ( transfer.from_share_round ) return null;

                const toStakeholderName = stakeholders.find( sh => sh.uuid === transfer.to_stakeholder ).name;
                const fromStakeholderName = stakeholders.find( sh => sh.uuid === transfer.from_stakeholder ).name;

                return (
                    <>
                        <Option value={ transfer.uuid } key={ transfer.uuid } filterByValue={ toStakeholderName + ' ' + fromStakeholderName } type="shareTransfer">
                            Share Transfer From: <TitleWithIcon title={ fromStakeholderName } highlight={ searchValue }/><br/>
                            To: <TitleWithIcon title={ toStakeholderName } highlight={ searchValue }/>
                        </Option>
                    </>
                );
            } ) }*/ }
            {/*{ searchValue && shareIssues.map( ( shareIssue ) => (
                <Option value={ shareIssue.share_round } key={ shareIssue.uuid } filterByValue={ shareIssue.stakeholder_details.name } type="shareIssue">
                    Share Issue to: <TitleWithIcon title={ shareIssue.stakeholder_details.name } highlight={ searchValue }/>
                </Option>
            ) ) }*/ }
            { searchValue && transactions.map( ( transaction ) =>
            {
                const filterByValue = transaction.from_stakeholder_name ?
                      transaction.from_stakeholder_name + ' ' + transaction.to_stakeholder_name :
                      transaction.company_name + ' ' + transaction.to_stakeholder_name;
                return (
                      <Option value={ transaction.uuid } key={ transaction.uuid } filterByValue={ filterByValue }
                              type="shareIssue">
                          Transaction From: <TitleWithIcon
                            title={ transaction.from_stakeholder_name || transaction.company_name } highlight={ searchValue }/>
                          To: <TitleWithIcon title={ transaction.to_stakeholder_name } highlight={ searchValue }/>
                      </Option>
                );
            } ) }
        </Select>
    </> );
} );

SearchBox.displayName = 'SearchBox';