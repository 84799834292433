import moment from 'moment';
import { DATE_FORMAT_DATE_PICKER, DATE_FORMAT_SERVER } from '@/shared/dateFormats';
import get from 'lodash/get';
import type { FilterKeys } from '@/components/tables';
import type { ORJSON } from '@/shared/models';


export const filterData = <T, U>( data: T[], filtersState: U, keys: FilterKeys ): T[] => Object.keys( keys ).length ?
      data.filter( d =>
      {
          const matchesAttempted: ORJSON<boolean> = {};
          const filterKeys = Object.keys( filtersState );
          let activeKeys = 0;

          filterKeys.forEach( key =>
          {
              // console.log( 'filtering', d, key, JSON.stringify( filtersState[ key ] ) );
              if ( !filtersState[ key ]?.active )
              {
                  return;
              }

              activeKeys++;

              if ( typeof matchesAttempted[ key ] === 'undefined' )
              {
                  matchesAttempted[ key ] = false;
              }

              const value = get( d, key, '' );
              const category = keys.categoryKeys?.find( c => c.title === key );

              if ( category )
              {
                  if ( filtersState[ category.title ].values.length )
                  {
                      if ( Array.isArray( value ) )
                      {
                          matchesAttempted[ key ] = value.reduce( ( acc, v ) => acc || filtersState[ category.title ].values.includes( v ), false );
                      } else
                      {
                          matchesAttempted[ key ] = filtersState[ category.title ].values.includes( value );
                      }
                  } else
                  {
                      matchesAttempted[ key ] = true;
                  }
              } else if ( keys.dateKeys?.includes( key ) )
              {
                  matchesAttempted[ key ] = moment( value, DATE_FORMAT_SERVER )
                        .isBetween(
                              moment( filtersState[ key ].from, DATE_FORMAT_DATE_PICKER ),
                              moment( filtersState[ key ].to, DATE_FORMAT_DATE_PICKER ),
                              undefined,
                              '[]'
                        );
              }

              // add more key types here
          } );

          const matches = Object.values( matchesAttempted );

          // console.log( 'matches', matchesAttempted );

          if ( activeKeys )
          {
              // AND filtering operation
              return matches.length ? matches.every( result => result === true ) : false;
          }

          return true;
      } )
      : data;