import type { GetServerSidePropsContext } from 'next';

import { getCookie, removeCookies, setCookie } from '@/shared/tools';


export const storage = {
    keys: [] as string[],
    setItem( key: string, value: string )
    {
        this.keys.push( key );
        setCookie( { cName: key, cValue: value, expDays: 9999 } );
    },
    getItem( key: string )
    {
        return getCookie( { cName: key } );
    },
    removeItem( key: string )
    {
        this.keys = this.keys.filter( ( value: string ) => value === key );
        removeCookies( key );
    },
    clear()
    {
        this.keys.length = 0;
    }
};

export const storageNextContext = ( context: GetServerSidePropsContext ) => ( {
    keys: [],
    setItem( key: string, value: string )
    {
        this.keys.push( key );
        context.res.setHeader( 'set-cookie', setCookie( { cName: key, cValue: value, expDays: 9999, returnValue: true } ) );
    },
    getItem( key: string )
    {
        return context.req.cookies[ key ];
    },
    removeItem( key: string )
    {
        this.keys = this.keys.filter( value => value === key );
        context.res.setHeader( 'set-cookie', setCookie( { cName: key, cValue: '', expDays: -1, returnValue: true } ) );
    },
    clear()
    {
        this.keys.length = 0;
    }
} );