import { type FC, memo, useEffect, useMemo } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/query';
import useReactive from 'ahooks/lib/useReactive';
import useUpdateEffect from 'ahooks/lib/useUpdateEffect';

import { useCurrentCompany } from '@/store/company/hooks';
import { usePatchCompanyMutation } from '@/store/company';


import { Button, Loader, Modal, Typography, UploadZone } from '@/components/structural';
import {
    FieldContainerMultipleFields,
    Form,
    FormItem,
    HiddenFormItemFullWidth,
    Input,
    InputNumber,
    InputTextArea,
    Switch,
    useForm,
    useTouchForm,
    VisibleFormItem,
    VisibleFormItemFullWidth
} from '@/components/structural/form';

import { companyUTRNumberPattern, companyVATNumberPattern } from '@/features/company/shared/validation';

import type { IORCompany, IORCompanyMainAttributes } from '@/features/company/shared/models';


interface ICompanySettingsProps
{
    standalone?: boolean,
    allowSaveWithoutChanges?: boolean,
    onUpdate?: () => void
}

export const CompanySettingsForm: FC<ICompanySettingsProps> = memo( ( {
    standalone = false,
    allowSaveWithoutChanges = false,
    onUpdate = () => null,
} ) =>
{
    const { orCompany } = useCurrentCompany();
    const [ patchCompany, { isLoading, status: patchStatus } ] = usePatchCompanyMutation();

    const { formIsPristine, touchForm } = useTouchForm();
    const [ form ] = useForm();

    const company = useMemo<Partial<IORCompanyMainAttributes>>( () => ( {
        ...( orCompany || {} ),
        eis_allocation: Number( orCompany?.eis_allocation ) || null,
        eis_allocation_yearly: Number( orCompany?.eis_allocation_yearly ) || null,
        seis_allocation: Number( orCompany?.seis_allocation ) || null,
    } ), [ orCompany ] );

    const switches = useReactive( {
        eis_enable: false,
        seis_enable: false,
    } );

    useEffect( () =>
    {
        form.setFieldsValue( {
            ...company
        } );

        switches.eis_enable = Number( company.eis_allocation ) > 0;
        switches.seis_enable = Number( company.seis_allocation ) > 0;
    }, [ company ] );

    useUpdateEffect( () =>
    {
        if ( patchStatus === QueryStatus.fulfilled )
        {
            !standalone &&
            Modal.destroyAll();

            onUpdate();
        }
    }, [ patchStatus ] );


    const doSave = ( data: Partial<IORCompany> ) =>
    {
        // used in the add company multistep form
        if ( standalone && formIsPristine && allowSaveWithoutChanges )
        {
            onUpdate();
            return;
        }

        const finalData: Partial<IORCompany> = {
            ...data,
        };

        patchCompany( { uuid: orCompany.uuid, data: finalData } );
    };

    return ( <>
        <Form form={ form }
              layout="vertical"
              requiredMark={ false }
              onFinish={ doSave }
              validateTrigger="onSubmit"
              onValuesChange={ () => touchForm() }
        >
            <VisibleFormItem
                  label="Enterprise Investment Scheme (EIS)"
                  tooltip="EIS is a UK government scheme which offers a range of tax relief to investors. Where a business is listed
                          as ‘EIS’, this tax relief will be available to qualifying investors."
            >
                <Switch
                      checked={ switches.eis_enable }
                      onChange={ value => switches.eis_enable = value }
                />
            </VisibleFormItem>

            <HiddenFormItemFullWidth
                  label={ <span className="text-info dark:text-info-dark">EIS Allowance (£)</span> }
                  rules={ [ { message: 'Please use a number greater than 1!', type: 'number', min: 0 } ] }
                  className={ switches.eis_enable ? 'grid' : 'hidden' }
                  name="eis_allocation"
            >
                <InputNumber
                      disabled
                      wholeNumbers
                />
            </HiddenFormItemFullWidth>

            <HiddenFormItemFullWidth
                  label={ <span className="text-info dark:text-info-dark">EIS Allowance (£)</span> }
                  rules={ [ { message: 'Please use a number greater than 1!', type: 'number', min: 0 } ] }
                  className={ switches.eis_enable ? 'grid' : 'hidden' }
                  name="eis_allocation_yearly"
            >
                <InputNumber
                      disabled
                      wholeNumbers
                />
            </HiddenFormItemFullWidth>

            <VisibleFormItem
                  label="Seed Enterprise Investment Scheme (SEIS)"
                  tooltip="The Seed Enterprise Investment Scheme (SEIS) was introduced in April 2012 by HMRC to help small, early-stage
                          companies raise funds through individual investors by providing a series of tax reliefs on investments made
                          into qualifying companies."
            >
                <Switch
                      checked={ switches.seis_enable }
                      onChange={ value => switches.seis_enable = value }
                />
            </VisibleFormItem>

            <HiddenFormItemFullWidth
                  label={ <span className="text-info dark:text-info-dark">SEIS Allowance (£)</span> }
                  rules={ [ { message: 'Please use a number greater than 1!', type: 'number', min: 0 } ] }
                  className={ switches.seis_enable ? 'grid' : 'hidden' }
                  name="seis_allocation"
            >
                <InputNumber
                      disabled
                      wholeNumbers
                />
            </HiddenFormItemFullWidth>

            <VisibleFormItemFullWidth
                  label={ <span>Web Filing Code <span className="ml-2 text-default-alt dark:text-default-dark font-roboto">OPTIONAL</span></span> }
                  className=""
                  name="web_filing_key"
                  tooltip={ <Typography.Paragraph className="font-roboto">
                      This is an alphanumeric code provided by Companies House. You’ll need to save it
                      in OneRegistry in order to be able to make statutory filings and changes at
                      Companies House through the platform. You can add in settings later if needed.
                  </Typography.Paragraph> }
            >
                <Input
                      placeholder=""
                />
            </VisibleFormItemFullWidth>

            <FieldContainerMultipleFields $cols={ 2 }>
                <FormItem
                      label="VAT Number"
                      rules={ [ { message: 'Please use a valid VAT number!', pattern: companyVATNumberPattern } ] }
                      name="vat_number"
                      className="mb-4"
                >
                    <Input/>
                </FormItem>
                <FormItem
                      label="UTR Number"
                      rules={ [ { message: 'Please use a valid UTR number!', pattern: companyUTRNumberPattern } ] }
                      name="utr_number"
                      className="mb-4"
                >
                    <Input/>
                </FormItem>
            </FieldContainerMultipleFields>

            <VisibleFormItemFullWidth
                  label="Website"
                  className=""
                  name="company_website"
            >
                <Input
                      placeholder="https://"
                />
            </VisibleFormItemFullWidth>

            <VisibleFormItemFullWidth
                  label={ <span>Company Profile <span className="ml-2 text-default-alt dark:text-default-dark font-roboto">OPTIONAL</span></span> }
                  className=""
                  name="company_profile"
            >
                <InputTextArea
                      placeholder=""
                          className="w-full"
                          rows={ 4 }
                />
            </VisibleFormItemFullWidth>

            <VisibleFormItemFullWidth
                  label={ <span>Company Logo <span className="ml-2 text-default-alt dark:text-default-dark font-roboto">OPTIONAL</span></span> }
                  className=""
            >
                <UploadZone
                      onFilesDrop={ ( files ) =>
                      {
                          form.setFieldValue( 'company_logo', files[ 0 ] );
                          touchForm();
                      } }
                      existingFiles={ form.getFieldValue( 'company_logo' ) ? [ form.getFieldValue( 'company_logo' ) ] : [] }/>
            </VisibleFormItemFullWidth>

            <FieldContainerMultipleFields $cols={ 1 }>
                <FormItem wrapperCol={ { span: 24 } }>
                    { isLoading ?
                          <Loader/>
                          :
                          <div className="grid grid-cols-2 gap-4">
                              { standalone ?
                                    <div>&nbsp;</div>
                                    :
                                    <Button onClick={ Modal.destroyAll } type="default" htmlType="button" className="w-full h-12">
                                        CLOSE
                                    </Button>
                              }
                              <Button type="success" htmlType="submit" className="w-full h-12" disabled={ formIsPristine && !allowSaveWithoutChanges }>
                                  SAVE
                              </Button>
                          </div>
                    }
                </FormItem>
            </FieldContainerMultipleFields>
        </Form>
    </> );
} );

CompanySettingsForm.displayName = 'CompanySettingsForm';