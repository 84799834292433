import { type FC, memo, type PropsWithChildren, useState } from 'react';
import { useRouter } from 'next/router';
import tw from 'tailwind-styled-components';

import { useAuth } from '@/store/auth/hooks';
import { useCurrentUser } from '@/store/user/hooks';

import { FullScreenOverlay, Layout, Loader } from '@/components/structural';

import { Alerts, Messages, Notifications } from '@/features/notifications';

import { AppHeaderAuthenticated } from './AppHeader-Authenticated';
import { AppViewToggle, Breadcrumbs, ViewSelector } from './components';
import { useDarkModeStylesheet, usePreventNavigationIfOnDashboardAndNoCompanySelected, useVersionChecker, useViewSelector } from './hooks';


export interface IAppLayoutAuthenticatedProps
{
    hideBreadcrumbs?: boolean,
    showPageViewSelector?: boolean,
    hideHeader?: boolean,
    hideTopViewToggle?: boolean,
}

export const AppLayoutAuthenticated: FC<PropsWithChildren<IAppLayoutAuthenticatedProps>> = memo( ( props ) =>
{
    useVersionChecker();

    const router = useRouter();

    const { awsUser } = useAuth();
    const { settings, isFetching: userIsFetching } = useCurrentUser( {} );

    const [ showLoader, setShowLoader ] = useState( false );

    const { viewSelectorVisible } = useViewSelector( props.showPageViewSelector );

    useDarkModeStylesheet( settings, awsUser );

    usePreventNavigationIfOnDashboardAndNoCompanySelected( {
        setShowLoader,
        awsUser,
        settings
    } );

    const showOverlay = ( showLoader || userIsFetching );

    return ( <>
        <CustomLayout className={ settings?.dark_mode ? 'scrollbar-dark' : 'scrollbar-light' }>
            { !props.hideTopViewToggle && settings?.user_type === 'investor-company' &&
                  <AppViewToggle/> }
            { !props.hideHeader &&
                  <AppHeaderAuthenticated hideTopViewToggle={ props.hideTopViewToggle }/> }
            <CustomContent
                  className={ `${ settings?.dark_mode ? 'scrollbar-dark' : 'scrollbar-light' } ${ settings?.user_type === 'investor-company' ?
                        ( props.hideTopViewToggle ? ( props.hideHeader ? 'mt-0' : 'mt-14' ) : ( props.hideHeader ? 'mt-10' : 'mt-24' ) )
                        :
                        ( props.hideHeader ? 'mt-0' : 'mt-14' )
                  }` }
                  $usePadding={ !router.pathname.includes( '404' ) }>
                <div className="grid grid-flow-col justify-between">
                    { !props.hideBreadcrumbs &&
                          <Breadcrumbs className="mb-4"/> || ' '
                    }
                    { viewSelectorVisible &&
                          <ViewSelector/>
                    }
                </div>
                <Alerts/>
                <div className="pb-28">
                    { props.children }
                </div>
            </CustomContent>
        </CustomLayout>
        { showOverlay && <FullScreenOverlay className="!p-0 !block">
            <Loader/>
        </FullScreenOverlay> }
        <Messages/>
        <Notifications/>
    </> );
} );

AppLayoutAuthenticated.displayName = 'AppLayoutAuthenticated';

const { Content } = Layout;

const CustomLayout = tw( Layout )`
    overflow-y-auto
    h-screen
`;

type CustomContentProps = { $usePadding: boolean };
const CustomContent = tw( Content ) <CustomContentProps>`
    ${ ( p: CustomContentProps ) => p.$usePadding && `
      px-10
      py-6
      ` }
    h-[calc(100vh-6rem)]
    overflow-y-auto
`;