import { useMemo } from 'react';

import { useAuth } from '@/store/auth/hooks';
import { useGetManyUsersQuery } from '@/store/user';


export const useUsers = ( skip = false ) =>
{
    const { awsUser } = useAuth();
    const { data, isFetching, isLoading, error, status } = useGetManyUsersQuery( undefined, {
        skip: skip || !awsUser
    } );

    return useMemo( () => ( {
        users: data,
        usersAreLoading: isLoading,
        usersAreFetching: isFetching,
        error,
        status
    } ), [ data, isFetching, isLoading, error, status ] );
};