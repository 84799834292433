import { type FC, memo, PropsWithChildren } from 'react';
import omit from 'lodash/omit';
import tw from 'tailwind-styled-components';

import AntDButton, { type ButtonProps, type ButtonType } from 'antd/lib/button/button';


export type ORButtonType = ButtonType | 'success' | 'warning' | 'info' | 'danger';

export interface IORButtonProps extends Omit<ButtonProps, 'type'>
{
    type?: ORButtonType;
}

export const Button: FC<PropsWithChildren<IORButtonProps>> = memo( ( props ) =>
{
    const newProps = Object.assign(
          {},
          omit( props, [ 'blurHeight', 'blurWidth' ] ),
          {
              type: props.type as ButtonType,
              // @ts-expect-error
              blurheight: props.blurHeight,
              // @ts-expect-error
              blurwidth: props.blurwidth
          }
    );

    return ( <CustomButton { ...newProps } $type={ props.type || 'default' }>
        { props.children }
    </CustomButton> );
} );

export const ButtonGroup = AntDButton.Group;

Button.displayName = 'Button';

const CustomButton = tw( AntDButton )<{ $type: ORButtonType }>`
    font-roboto
    ${ p =>
      ( p.$type === 'success' ? `
        text-white
            dark:text-default-dark
        bg-success
            dark:bg-success-dark
        border-success
            dark:border-success-dark
        hover:text-white
            dark:hover:text-default-dark
        hover:border-success-hover
            dark:hover:border-success-hover-dark
        hover:bg-success-hover
            dark:hover:bg-success-hover-dark
        ` : null ) +
      ( p.$type === 'default' ? `
        bg-white
            dark:bg-default
        hover:bg-default-dark-gray
            dark:bg-default-hover-dark
        ` : null ) +
      ( p.$type === 'warning' ? `
        text-white
            dark:text-default-dark
        bg-warning
            dark:bg-warning-dark
        border-warning
            dark:border-warning-dark
        hover:text-white
            dark:hover:text-default-dark
        hover:border-warning-hover
            dark:hover:border-warning-hover-dark
        hover:bg-warning-hover
            dark:hover:bg-warning-hover-dark
        ` : null ) +
      ( p.$type === 'info' ? `
        !text-white
            dark:!text-default-dark
        !bg-info
            dark:!bg-info-dark
        !border-info
            dark:!border-info-dark
        hover:!text-white
            dark:hover:!text-default-hover-dark
        hover:!border-info-hover
            dark:hover:!border-info-hover-dark
        hover:!bg-info-hover
            dark:hover:!bg-info-hover-dark
        disabled:!bg-default-hover
            dark:disabled:!bg-default-hover
            dark:disabled:!border-default-hover
        disabled:!border-default-hover
        ` : null ) +
      ( p.$type === 'danger' ? `
        !text-white
            dark:!text-default-dark
        !bg-danger
            dark:!bg-danger-dark
        !border-danger
            dark:!border-danger-dark
        hover:!text-white
            dark:hover:!text-default-hover-dark
        hover:!border-danger-hover
            dark:hover:!border-danger-hover-dark
        hover:!bg-danger-hover
            dark:hover:!bg-danger-hover-dark
        disabled:!bg-default-hover
            dark:disabled:!bg-default-hover
            dark:disabled:!border-default-hover
        disabled:!border-default-hover
        ` : null )
}
`;