import type { PayloadActionCreator } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/query';

import { doLogoutClearState } from '@/store/auth/actions';

import { replaceUndefinedWithNull } from '@/shared/tools';

import type { ORJSON } from '@/shared/models';


const ACTIONS_TO_KEEP = 50;

export type IStoreActionType = IStoreApiAction | IStoreNonApiAction;

export interface IActionsState
{
    actions: IStoreActionType[],
    lastAction: IStoreActionType,
}

export interface IStoreAction
{
    // TRUE if action is an RTK-Query action, FALSE if regular Redux action
    isApi: boolean,
    payload: ORJSON,
    // either RTK-Query api name (userApi, companyApi, etc.) or regular Redux slice name (internal, auth, etc.)
    storeSliceKey: string,
    // action name (patchUser, setAwsUser, setVersion, etc.)
    endpoint: string,
}

export interface IStoreApiAction extends IStoreAction
{
    isApi: true,
    category: API_ACTION_CATEGORY,
    type: QueryStatus,
    originalArgs: ORJSON | string,
}

export interface IStoreNonApiAction extends IStoreAction
{
    isApi: false,
}

export type TStoreAction = IStoreApiAction | IStoreNonApiAction;

const initialState: IActionsState = {
    actions: [],
    lastAction: undefined,
};

export const actions = ( state: IActionsState = initialState, action: ReturnType<PayloadActionCreator<unknown>> & APIResultType ) =>
{
    if ( !action || action[ '@@redux-saga/SAGA_ACTION' ] )
    {
        return state;
    }

    if ( doLogoutClearState.match( action ) )
    {
        return initialState;
    }

    let parsedAction: TStoreAction;
    const typeBreakdown = action.type.split( '/' );
    const payload = replaceUndefinedWithNull( action.payload );

    if ( action.meta?.arg || action.type.toLowerCase().includes( 'api' ) )
    {
        const storeSliceKey: string = typeBreakdown[ API_ACTION.NAME ] || null;
        const category: API_ACTION_CATEGORY = ( typeBreakdown[ API_ACTION.CATEGORY ] as API_ACTION_CATEGORY ) || null;
        const type: API_ACTION_TYPE = ( typeBreakdown[ API_ACTION.TYPE ] as API_ACTION_TYPE ) || null;
        const endpoint = action.meta?.arg?.endpointName || null;
        const originalArgs = action.meta?.arg?.originalArgs || null;

        parsedAction = {
            isApi: true,
            storeSliceKey,
            category,
            type,
            endpoint,
            originalArgs,
            payload
        } as IStoreApiAction;
    } else
    {
        const storeSliceKey = typeBreakdown[ ACTION.NAME ] || null;
        const endpoint = typeBreakdown[ ACTION.ENDPOINT ] || null;

        parsedAction = {
            isApi: false,
            storeSliceKey,
            endpoint,
            payload,
        } as IStoreNonApiAction;
    }

    if ( process.env.NODE_ENV !== 'production' )
    {
        // console.log( parsedAction );
    }

    return {
        // latest action at the top, actions in reverse order
        actions: [
            parsedAction,
            ...state.actions.slice( 0, ACTIONS_TO_KEEP ),
        ],
        lastAction: parsedAction
    };
};

type APIResultType = {
    meta: {
        arg: {
            endpointName: string,
            originalArgs: IStoreApiAction['originalArgs'],
        }
    }
}

enum API_ACTION
{
    NAME,
    CATEGORY,
    TYPE
}

type API_ACTION_CATEGORY = 'queries' | 'executeQuery' | 'executeMutation' | 'subscriptions' | 'mutations' | 'config';
type API_ACTION_TYPE = typeof QueryStatus.pending | typeof QueryStatus.fulfilled | typeof QueryStatus.rejected | typeof QueryStatus.uninitialized |
      'unsubscribeQueryResult' | 'unsubscribeMutationResult' | 'middlewareRegistered';

enum ACTION
{
    NAME,
    ENDPOINT
}